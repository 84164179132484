import { Button, Card, Form, Modal, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";
import { Col, Row } from "react-bootstrap";
import { POST } from "../../utils/apiRequest";
import { API } from "../../config/Api";

function BulkCreateModal(props: any) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const getLatestJobs = (data: any) => {
    const latestJobs = data?.reduce((acc: any, job: any) => {
      if (
        !acc[job.staffId] ||
        new Date(acc[job.staffId].createdAt) < new Date(job.createdAt)
      ) {
        acc[job.staffId] = job;
      }
      return acc;
    }, {});
    return Object.values(latestJobs);
  };

  const staffMap = new Map(
    props.staff.map((staff: any) => [staff.id, staff.name])
  );
  const activityMap = new Map(
    props.activities.map((activity: any) => [activity.id, activity.name])
  );
  const activityReverseMap = new Map(
    props.activities.map((activity: any) => [activity.name, activity.id])
  );

  useEffect(() => {
    if (props?.data) {
      const latestJobs = getLatestJobs(props.data);
      const initialData = latestJobs.map((item: any) => {
        return {
          name: staffMap.get(String(item.staffId)),
          activities: item.activities.filter(Boolean).map((actId: string) => {
            return activityMap.get(String(actId));
          }),
          staffId: item?.staffId,
        };
      });
      form.setFieldsValue({ staff: initialData });
      setInitialLoading(false);
    }
  }, [props.data, form, staffMap, activityMap]);

  const handleFinish = async (values: any) => {
    setIsLoading(true);
    try {
      const mappedValues = values.staff.map((item: any) => ({
        ...item,
        staffId: item.staffId || item.name,
        activities: item.activities
          .filter(Boolean)
          .map((activityName: string) => activityReverseMap.get(activityName)),
      }));
      const obj = {
        jobs: mappedValues.map((job: any) => ({
          ...job,
          status: true,
          totalSlots: 0,
          totalAmount: 0,
          dueAmount: 0,
          settledAmount: 0,
          upiSettle: 0,
          usedSlots: 0,
          isClockIn: "start",
        })),
      };
      const api = API.JOB_BULK_CREATE;
      const response: any = await POST(api, obj);
      if (response?.status) {
        Notifications["success"]({
          message: "Successfully Created Jobs",
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        Notifications["error"]({
          message: response?.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Notifications["error"]({
        message: "Oops something went wrong...",
      });
    }
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props.visible}
        title={<div style={{ fontSize: 25 }}>Bulk Job Create</div>}
        onCancel={props.onCancel}
        footer={false}
        width={1200}
      >
        <hr />
        {isLoading || initialLoading ? (
          <Loading />
        ) : (
          <Form form={form} onFinish={handleFinish} layout="vertical">
            <Card>
              <Row style={{ marginBottom: "10px", fontWeight: "bold" }}>
                <Col md={4}>Staff Name</Col>
                <Col md={7}>Activities</Col>
              </Row>
              <Form.List name="staff">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }: any) => (
                          <Row key={key}>
                            <Col md={4} className="mt-1">
                              <Form.Item
                                {...restField}
                                name={[name, "name"]}
                                fieldKey={[fieldKey, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing staff name",
                                  },
                                ]}
                                noStyle
                              >
                                <Select
                                  allowClear
                                  size="middle"
                                  style={{ width: "100%" }}
                                  placeholder="Select Staff"
                                  onChange={(value: string) => {
                                    const staffName = staffMap.get(value);
                                    form.setFieldsValue({
                                      staff: form
                                        .getFieldValue("staff")
                                        .map((staff: any, idx: number) => {
                                          return idx === name
                                            ? {
                                                ...staff,
                                                name: staffName,
                                                staffId: Number(value),
                                              }
                                            : staff;
                                        }),
                                    });
                                  }}
                                >
                                  {props?.staff.map((option: any) => (
                                    <Select.Option
                                      key={option?.id}
                                      value={option?.id}
                                    >
                                      {option?.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={7} className="mt-1">
                              <Form.Item
                                {...restField}
                                name={[name, "activities"]}
                                fieldKey={[fieldKey, "activities"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Activities",
                                  },
                                ]}
                                noStyle
                              >
                                <Select
                                  allowClear
                                  mode="multiple"
                                  size="middle"
                                  style={{ width: "100%" }}
                                  placeholder="Select Activities"
                                >
                                  {props?.activities.map((option: any) => (
                                    <Select.Option
                                      key={option?.id}
                                      value={option?.name}
                                    >
                                      {option?.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={1} style={{ padding: 0 }} className="mt-1">
                              <Button
                                danger
                                onClick={() => {
                                  if (fields.length === 1) {
                                    Notifications["error"]({
                                      message:
                                        "At least one job needed, You can edit it",
                                    });
                                  } else {
                                    remove(name);
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        )
                      )}
                      <br />
                      <Form.Item className="mb-0">
                        <Button type="dashed" onClick={() => add()} block>
                          + Add
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </Card>
            <br />
            <Row>
              <Col md={6} />
              <Col md={3}>
                <Button size="large" danger block onClick={props.onCancel}>
                  Close
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default BulkCreateModal;
