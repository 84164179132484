import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import activitySlice from "./activitySlice";
import jobSlice from "./jobSlice";

const Slices = combineReducers({
  User: userSlice,
  activity: activitySlice,
  job: jobSlice,
});
export default Slices;
