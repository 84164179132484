import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../components/pageHeader";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import { useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import PageHeaders from "../../components/pageHeaders";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function TicketForm(props: any) {
  const [data, setData] = useState<any>([]);
  const [activity, setActivity] = useState([]);
  //const [STAFF, setActivity] = useState([]);

  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  useEffect(() => {
    fetchActivity();
    fetchstaff();
  }, []);

  const fetchActivity = async () => {
    const activity: any = await GET("activity/all", {});
    setActivity(activity);
  };

  const fetchstaff = async () => {
    const staff: any = await GET("staff/all", {});
    setActivity(staff);
  };
  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let ticket = {
        from: "web",
        bookingId: moment().format("YYYYMMDDHHmmssSSS"),
        staffId: 10,
        jobId: 1,
        totalPrice: 200,
        createdBy: "string",
        start: moment().toDate(),
        end: moment().add(30, "minutes").toDate(),
        activities: val?.activity,
        print: 1,
        paymentStatus: true,
        transId: "string",
        paymentMethod: "upi",
        totalAmount: 200,
        discount: 0,
        tax: 1.5,
        taxAmt: 3,
        paidAmount: 203,
        grandTotal: 203,
        count: 1,
        ticketInfo: ["string"],
      };
      let url = API.TICKET_ADD;
      const data: any = await POST(url, ticket);
      if (data) {
        message.success("Succesfully Created");
        setIsLoading(false);
      }
      console.log(ticket);
      setData(data);
      console.log(url);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Card>
        <PageHeaders title={"Create Tickets"} backButton={true}></PageHeaders>
        <div className="screenBox">
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            autoComplete="off"
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Row>
                      <Col md={6}></Col>
                      <Col md={6}></Col>
                      </Row>
                      <Form.Item
                        {...restField}
                        name={[name, "first"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "last"]}
                        rules={[
                          { required: true, message: "Missing last name" },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>

          {/* <Form onFinish={onFinish} form={form}>
            <Row>
              <Col md={6}>
                <label className="formLabel">Activities</label>
                <Form.Item
                  name={"activity"}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select size="large" style={{ width: "100%" }}>
                    {props?.activity?.map((activity: any) => (
                      <Select.Option key={activity?.id}>
                        {activity?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">Category</label>
                <Form.Item
                  name={"role"}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select size="large" style={{ width: "100%" }}>
                    <Select.Option value="adult">Adult</Select.Option>
                    <Select.Option value="kids">Kids</Select.Option>
                  </Select>
                </Form.Item>
                <Row>
                  <Col sm={6}>
                    <Button size="large" danger block onClick={props.onCancel}>
                      Close
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {props?.data?.id ? "Edit" : "Create"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form> */}
        </div>
      </Card>
    </>
  );
}
export default TicketForm;
