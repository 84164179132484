import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";

function BookingDataTable(props: any) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{id}</div>;
      },
    },
    {
      title: "booking Id",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "bookingDate",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    // {
    //   title: "Device",
    //   dataIndex: "fromDevice",
    //   key: "fromDevice",
    //   render: (record: any) => {
    //     return <div className="table-Txt">{record}</div>;
    //   },
    // },
    // {
    //   title: "Print",
    //   dataIndex: "print",
    //   key: "print",
    //   render: (item: any) => (
    //     <div className="table-Action">
    //       {item ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
    //     </div>
    //   ),
    // },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (item: any) => (
        <div className="table-Action">
          {item ? <Tag color="green">Paid</Tag> : <Tag color="red">Unpaid</Tag>}
        </div>
      ),
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("llll")}</div>
      ),
    },
    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item?.id)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiEdit
                size={20}
                onClick={() => props?.onEdit(item)}
                style={{ cursor: "pointer", color: "orange" }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
              <Popconfirm
                title="Delete the Staff's Details"
                description="Are you sure you want to delete the Staff Details?"
                onConfirm={() => props?.delete(item?.id)}
                okText="Yes"
              >
                <AiOutlineDelete
                  size={20}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Bookings`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default BookingDataTable;
