import { Card, Tabs, Tag } from "antd";
import PageHeaders from "../../components/pageHeaders";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { Table } from "react-bootstrap";
import "./styles.scss";
import moment from "moment";
import ViewTransModal from "./transViewModal";
import type { TabsProps } from "antd";
import History from "./components/history";
import Tickets from "./components/tickets";
import Transactions from "./components/transactions";

const CardDetails = () => {
  const location = useLocation();
  const id = location.state?.val?.id;
  const cardNo = location.state?.val?.cardNo;

  const [data, setData] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({});

  const [transModalVisible, setTransModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.CARDS_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };

  const fullDetailsView = (val: any) => {
    try {
      setSelectedItem(val);
      setTransModalVisible(!transModalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Tickets",
      children: <Tickets cardNo={cardNo} />,
    },
    {
      key: "2",
      label: "Transactions",
      children: <Transactions cardNo={cardNo} />,
    },
    {
      key: "3",
      label: "History",
      children: <History cardNo={cardNo} />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <div>
      <PageHeaders title={"Card Details"} backButton={true} />
      <div className="screenBox">
        <br />
        <Card>
          <div className="">
            <div className="header-txt1">Card Details</div>
            <br />
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th className="table-th">ID</th>
                  <th className="table-th">cardNo</th>
                  <th className="table-th">Name</th>
                  <th className="table-th">Phone No</th>
                  <th className="table-th">Expiry Date</th>
                  <th className="table-th">Balance</th>
                  <th className="table-th">Status</th>
                  <th className="table-th">Assigned</th>
                  <th className="table-th">createdAt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.id}</td>
                  <td>{data?.cardNo}</td>
                  <td>{data?.name}</td>
                  <td>{data?.phone}</td>
                  <td>{moment(data.expiryDate).format("lll")}</td>
                  <td>{data?.balance}</td>
                  <td>
                    {data?.status === true ? (
                      <Tag color="green">Yes</Tag>
                    ) : (
                      <Tag color="red">No</Tag>
                    )}
                  </td>
                  <td>
                    {data?.assigned === true ? (
                      <Tag color="green">Yes</Tag>
                    ) : (
                      <Tag color="red">No</Tag>
                    )}
                  </td>
                  <td>{moment(data?.createdAt).format("lll")}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card>
        <br />
        <Card>
          <Tabs
            animated
            size="large"
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </Card>
      </div>
      {transModalVisible ? (
        <ViewTransModal
          data={selectedItem}
          visible={transModalVisible}
          onCancel={() => setTransModalVisible(false)}
        />
      ) : null}
    </div>
  );
};

export default CardDetails;
