import React, { useEffect, useLayoutEffect, useState } from "react";
import { POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import moment from "moment";
import { Button, Pagination, Tag } from "antd";
import { Table } from "react-bootstrap";
import Loading from "../../../components/loading";
import { MdOutlineRefresh } from "react-icons/md";

const History = (props: any) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);

  useEffect(() => {
    getData();
  }, [page, take]);

  const getData = async () => {
    try {
      const api = API.CARD_HISTORY;
      let obj = {
        cardNo: props?.cardNo,
        type: "history",
        page: page,
        take: take,
      };
      const response: any = await POST(api, obj);
      if (response?.status) {
        setHistory(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
        setIsLoading2(false);
      } else {
        setHistory([]);
        setIsLoading(false);
        setIsLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="table-header">
            <div className="header-txt1">
              Showing {history?.length} of {meta?.total_count}
            </div>
            <Button
              onClick={() => {
                setIsLoading(true);
                getData();
              }}
              type="primary"
            >
              <MdOutlineRefresh color="#fff" size={20} />
            </Button>
          </div>
          <br />
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th className="table-th">Card No</th>
                <th className="table-th">Name</th>
                <th className="table-th">Phone No</th>
                <th className="table-th">Expiry Date</th>
                <th className="table-th">Balance</th>
                <th className="table-th">Status</th>
                <th className="table-th">Assigned</th>
                <th className="table-th">Created At</th>
              </tr>
            </thead>
            <tbody>
              {history?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item?.cardNo}</td>
                    <td>{item?.name}</td>
                    <td>{item?.phone}</td>
                    <td>{moment(item.expiryDate).format("lll")}</td>
                    <td>{item?.balance}</td>
                    <td>
                      {item?.status === true ? (
                        <Tag color="green">Yes</Tag>
                      ) : (
                        <Tag color="red">No</Tag>
                      )}
                    </td>
                    <td>
                      {item?.assigned === true ? (
                        <Tag color="green">Yes</Tag>
                      ) : (
                        <Tag color="red">No</Tag>
                      )}
                    </td>
                    <td>{moment(item.createdAt).format("lll")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      <br />
      <div className="pagination-item">
        <Pagination
          defaultCurrent={page}
          total={meta?.total_count}
          onChange={(page: any, take: any) => {
            setIsLoading(true);
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default History;
