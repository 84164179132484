import React, { useEffect, useLayoutEffect, useState } from "react";
import { POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import moment from "moment";
import { Button, Pagination, Tag } from "antd";
import { Table } from "react-bootstrap";
import Loading from "../../../components/loading";
import { MdOutlineRefresh } from "react-icons/md";

const Transactions = (props: any) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);

  useEffect(() => {
    getData();
  }, [page, take]);

  const getData = async () => {
    try {
      const api = API.CARD_HISTORY;
      let obj = {
        cardNo: props?.cardNo,
        type: "transaction",
        page: page,
        take: take,
      };
      const response: any = await POST(api, obj);
      if (response?.status) {
        setTransactions(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
        setIsLoading2(false);
      } else {
        setTransactions([]);
        setIsLoading(false);
        setIsLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="table-header">
            <div className="header-txt1">
              Showing {transactions?.length} of {meta?.total_count}
            </div>
            <Button
              onClick={() => {
                setIsLoading(true);
                getData();
              }}
              type="primary"
            >
              <MdOutlineRefresh color="#fff" size={20} />
            </Button>
          </div>
          <br />
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th className="table-th">Date</th>
                <th className="table-th">Transaction Id</th>
                <th className="table-th">Type</th>
                <th className="table-th">Payment Method</th>
                <th className="table-th">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td className="table-td">
                      {moment(item.createdAt).format("lll")}
                    </td>
                    <td className="table-td">{item?.transactionId}</td>
                    <td className="table-td">{item?.type}</td>
                    <td className="table-td">{item?.paymentMethod}</td>
                    <td className="table-td">{item?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      <br />
      <div className="pagination-item">
        <Pagination
          defaultCurrent={page}
          total={meta?.total_count}
          onChange={(page: any, take: any) => {
            setIsLoading(true);
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default Transactions;
