import { Button, DatePicker, Form, Input, Modal, notification } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const BookingFormModal = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const { activities } = useSelector((state: any) => state.activity);
  const [form] = Form.useForm();

  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf("day");
  };
  const calculateTotalCount = () => {
    try {
      let totalCount = 0;
      activities?.forEach((activity: any) => {
        activity.price.forEach((price: any) => {
          totalCount += price.count;
        });
      });
      return totalCount;
    } catch (error) {
      console.error("Error calculating total count:", error);
    }
  };
  //   let grandTotal: number = 0;

  //   const summaryTotal = (val: any) => {
  //     const sum = val.reduce((totalSum: any, item: any) => {
  //       const total = Number(item.price) * Number(item.count);
  //       return totalSum + total;
  //     }, 0);
  //     grandTotal += sum;
  //     return sum;
  //   };

  const bookNow = async (val: any) => {
    setIsLoading(true);
    try {
      const bookingInfo = {
        ...val,
        grandTotal: props?.grandTotal,
        paidAmount: props?.grandTotal,
        totalAmount: props?.grandTotal,
        ticketInfo: { activities },
        status: false,
        paymentMethod: "razorpay",
        email: val?.email,
        count: calculateTotalCount(),
        paymentStatus: true,
      };

      await props?.makePayment(bookingInfo);
      form.resetFields();
    } catch (error) {
      console.error("Error booking:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while booking",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={props?.open}
      footer={false}
      centered
      className="Modal_BackgroundColor"
      onCancel={props?.close}
    >
      <Form form={form} onFinish={bookNow}>
        <div className="summaryKey mb-3">Submit Your Details</div>
        <Row>
          <Col md={6} xs={12}>
            <div>Date:</div>
            <Form.Item
              name="bookingDate"
              rules={[{ required: true, message: "Please Select a Date" }]}
            >
              <DatePicker
                disabledDate={disabledDate}
                className="custom-input"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <div>Phone</div>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please Enter your Phone Number" },
              ]}
            >
              <Input
                type="number"
                placeholder="Phone Number"
                className="custom-input"
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <div>Name</div>
            <Form.Item
              name="userName"
              rules={[{ required: true, message: "Please Enter Your Name" }]}
            >
              <Input placeholder="Enter your Name" />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <div>Email</div>
            <Form.Item name="email">
              <Input placeholder="Enter your Email" />
            </Form.Item>
          </Col>
          <Col xs={12} className="d-flex justify-content-end gap-3">
            <Button onClick={props?.close}>Back</Button>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={activities.length === 0 ? true : false}
              >
                Pay Now
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BookingFormModal;
