import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";

function TransactionDataTable(props: any) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{id}</div>;
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Payment Type",
      dataIndex: "type",
      key: "type",
      render: (record: any) => {
        return <div className="table-Txt">{record?.toUpperCase()}</div>;
      },
    },

    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: " paymentMethod",
      render: (item: any) => (
        <div className="table-Action">
          {item === "cash" ? (
            <Tag color="purple">{item?.toUpperCase()}</Tag>
          ) : item === "card" ? (
            <Tag color="gold">{item?.toUpperCase()}</Tag>
          ) : (
            <Tag color="cyan">{item?.toUpperCase()}</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Card No.",
      dataIndex: "cardNo",
      key: "cardNo",
      render: (record: any) => {
        return <div className="table-Txt">{record?.toUpperCase()}</div>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: " amount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
      ),
    },

    {
      title: "Created By",
      dataIndex: "staff",
      key: "staff",
      render: (record: any) => {
        return <div className="table-Txt">{record?.name.toUpperCase()}</div>;
      },
    },

    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item)}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) =>
            `Total ${props?.meta?.total_count} Transactions`
          }
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default TransactionDataTable;
