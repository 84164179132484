export const API = {
  // BASE_URL: "http://localhost:8018/",

  // BASE_URL: "https://server.bairuhatech.com/ticketing/", //testing

  BASE_URL: "https://server.teenzania.in/", // production

  //IMAGE UPLOAD
  FILE_COMPRESS_UPLOAD: "img_compress/compress", //POST

  //DASHBOARD LOGIN
  ADMIN_LOGIN: "staff/login", //POST

  //DASHBOARD
  DASHBOARD_COUNT: "dashboard/counts", //GET
  DASHBOARD_GRAPH: "dashboard/getGraphData", //GET

  //STAFF
  STAFFS_LIST: "staff/list", //POST
  STAFFS_ADD: "staff/create", //POST
  STAFFS_EDIT: "staff/update", //POST
  STAFFS_DELETE: "staff/delete/", //DELETE
  STAFFS_DETAILS: "staff/find_one/", //GET
  STAFFS_PICKER: "staff/picker", //GET
  STAFFS_PICKER2: "staff/picker2", //GET

  //ACTIVITY
  ACTIVITY_LIST: "activity/list", //POST
  ACTIVITY_ADD: "activity/create", //POST
  ACTIVITY_EDIT: "activity/update", //POST
  ACTIVITY_DELETE: "activity/delete/", //DELETE
  ACTIVITY_DETAILS: "activity/find_one/", //GET
  ACTIVITY_PICKER: "activity/picker", //GET
  ACTIVITY_PICKER2: "activity/picker2", //GET

  //JOB
  JOBS_LIST: "jobs/list", //POST
  JOBS_ADD: "jobs/create", //POST
  JOBS_EDIT: "jobs/update", //POST
  JOBS_DELETE: "jobs/delete/", //DELETE
  JOBS_DETAILS: "jobs/find_one/", //GET
  JOBS_PICKER: "jobs/picker", //GET
  JOB_IN: "jobs/inOut", //POST
  JOB_BULK_CREATE: "jobs/bulk-create", //POST

  TRANSACTION_SUMMARY: "Transaction/summary",
  //OFFERS
  OFFERS_LIST: "Offers/list", //POST
  OFFERS_ADD: "Offers/create", //POST
  OFFERS_EDIT: "Offers/update", //POST
  OFFERS_DELETE: "Offers/delete/", //DELETE
  OFFERS_DETAILS: "Offers/find_one/", //GET
  OFFERS_PICKER: "Offers/picker", //GET

  //BOOKING
  BOOKING_LIST: "bookings/list", //POST
  BOOKING_ADD: "bookings/create", //POST
  BOOKING_EDIT: "bookings/update", //POST
  BOOKING_DELETE: "bookings/delete/", //DELETE
  BOOKING_DETAILS: "bookings/find_one/", //GET
  BOOKING_PICKER: "bookings/picker", //GET

  //TICKETS
  TICKET_LIST: "ticketMaster/list",
  TICKET_ADD: "ticketMaster/create",
  TICKET_EDIT: "ticketMaster/update",
  TICKET_DELETE: "ticket/delete",
  TICKET_DETAILS: "ticketMaster/info/",
  TICKET_DETAILS_INFO: "ticketMaster/details/",
  // TICKET_DETAILS_BYID: "ticketMaster/find_one/",

  //PAYMENT
  PAYMENT_LIST: "payment/list", //POST
  PAYMENT_ADD: "payment/create", //POST
  PAYMENT_EDIT: "payment/update", //POST
  PAYMENT_DELETE: "payment/delete/", //DELETE
  PAYMENT_DETAILS: "payment/find_one/", //GET

  //CARDS
  CARDS_LIST: "card/list", //POST
  CARDS_ADD: "card/create", //POST
  CARDS_EDIT: "card/update", //POST
  CARDS_DELETE: "card/delete/", //DELETE
  CARDS_DETAILS: "card/find_one/", //GET
  CARDS_FULL_DETAILS: "card/fullDetails/", //GET
  CARD_REFUND: "card/refund", //POST
  CARD_HISTORY: "card/history/", //POST
  CARD_REFERENCE_DATA: "card/getCardRefeHistory/", //GET
  REFERENCE_CARDS: "card/referance",

  //Tickets
  ALL_TICKETS: "ticketMaster/list",
  FIND_TICKET_BY_ID: "ticketMaster/",
  CREATE_TICKETS: "ticketMaster/createTicket",

  //Transactions
  TRANSACTIONS_LIST: "Transaction/list",

  //Report
  SALES_REPORT: "report/sales-report", //POST
  BOOKING_REPORT: "report/booking-report", //POST
  ACTIVITY_REPORT: "report/activity-report", //POST
  JOB_REPORT: "report/job-report", //POST
  STAFF_REPORT: "report/staff-report", //POST
  PACKAGE_REPORT: "report/package-report", //POST

  //Self Ticketing
  GET_ACTIVITIES: "selfTicket/activities",
  //Contact
  CONTACT_US: "contact/create", //POST

  //feedback
  FEEDBACK: "Feedback/list",
  FEED_BACK: "Feedback/create", //POST
  //card

  //mobileApp
  MOBILE_APP: "download/create",
  GET_DOWNLOAD: "download/list",
  UPDATE_PUBLISH: "download/publish/",

  //DISCOUNT
  DISCOUNT_LIST: "discount/list", //POST
  DISCOUNT_ADD: "discount/create", //POST
  DISCOUNT_EDIT: "discount/update", //POST
  DISCOUNT_DELETE: "discount/delete/", //DELETE
  DISCOUNT_DETAILS: "discount/find_one/", //GET
};
