import { Button, notification } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import logo from "../../../assets/images/teenzaniLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { addTicket, removeAllTicket } from "../../../redux/slice/activitySlice";
import herryUp from "../../../assets/images/hurryUp.png";
import "../styles.scss";
import BookingFormModal from "./bookingForm";
import { POST } from "../../../utils/apiRequest";
import { KEYS } from "../../../config/keys";
import { API } from "../../../config/Api";
import TicketModal from "../../../admin/Tickets/ticketModal";
const Fade = require("react-reveal/Fade");

interface Props {
  setGrandTotal?: (total: number) => void;
  onCancel?: () => void;
  makePayment: (bookingInfo: any) => void;
}

function SummaryScreen(props: any) {
  const dispatch = useDispatch();
  const [ticketModalOpen, setTicketModalOpen] = useState<boolean>(false);
  const [bookingFormModal, setBookingFormModal] = useState<boolean>(false);
  const [ticketNo, setTicketnumber] = useState<any>();
  const { activities } = useSelector((state: any) => state.activity);

  let grandTotal: number = 0;

  const summaryTotal = (val: any) => {
    const sum = val.reduce((totalSum: any, item: any) => {
      const total = Number(item.price) * Number(item.count);
      return totalSum + total;
    }, 0);
    grandTotal += sum;
    return sum;
  };

  const selectItem = (item: any, price: any, type: any) => {
    try {
      let obj = {
        id: item?.id,
        name: item?.name,
        price: price,
        type: type,
      };
      dispatch(addTicket(obj));
    } catch (err) {
      console.log("Error selecting item:", err);
    }
  };

  const clear = () => {
    dispatch(removeAllTicket());
  };

  const makePayment = async (e: any) => {
    // e.preventDefault();
    var options = {
      key: KEYS.RAZORPAY_KEY,
      key_secret: KEYS.RAZORPAY_PRIVATE,
      amount: Number(grandTotal) * 100,
      currency: "INR",
      name: "Teenzania",
      description: "Ticket Payment",
      handler: function (response: any) {
        CreateBooking(e, response.razorpay_payment_id);
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#08493e",
      },
    };
    var pay: any = new window.Razorpay(options);
    pay.open();
  };

  const CreateBooking = async (val: any, paymentId: any) => {
    try {
      const bookingInfo = {
        ...val,
        paymentId: paymentId,
      };
      const response: any = await POST(API.BOOKING_ADD, bookingInfo);
      console.log("kondoyi olath ente console", response);
      if (response?.status) {
        notification.success({
          message: "Successfully Booked",
        });
        setTicketnumber(response?.data?.ticketNo);
        dispatch(removeAllTicket());
        setBookingFormModal(false);
        setTicketModalOpen(true);
      } else {
        console.log("error");
        notification.error({
          message: "Failed to Book",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: "Something Went Wrong kunjani",
      });
    } finally {
      if (props?.onClose) {
        props?.onClose();
      }
    }
  };

  return (
    <div className="summaryBBox">
      <div className="d-flex justify-content-center">
        <img src={logo} alt="teenzaniaLogo" height={60} />
      </div>
      <div className="summaryHead">Booking Summary</div>
      <div style={{ borderBottom: "1px solid #cacccf" }}></div>
      <br />

      <div className="summaryBBox2">
        {activities.length === 0 ? (
          <div className="d-flex  align-items-center flex-column">
            <Fade top>
              <img src={herryUp} alt="herryup" width={"100px"} />
            </Fade>
            <div className="text-center">Please select an activity</div>
            <Col className=" my-2 d-block d-md-none">
              <Button type="primary" onClick={props?.onClose}>
                Select Activities
              </Button>
            </Col>
          </div>
        ) : (
          activities.map((item: any, index: any) => (
            <div key={item.id} className="my-3">
              <div className="summaryKey">
                {index + 1}. {item.name}
              </div>
              {item?.price?.map((price: any, priceIndex: any) => (
                <>
                  <div
                    key={`${item.id}-${priceIndex}`}
                    className="d-flex justify-content-between"
                  >
                    <div className="summaryValue ms-2">
                      <div>category: {price.age}</div>
                      <div>
                        count: {price.count} x {price.price}
                      </div>
                    </div>
                    <div className="countBtnHead">
                      <CiCircleMinus
                        size={25}
                        color="grey"
                        onClick={() => selectItem(item, price, "remove")}
                      />
                      <div className="Summarycount">{price.count}</div>
                      <CiCirclePlus
                        size={25}
                        color="grey"
                        onClick={() => selectItem(item, price, "add")}
                      />
                    </div>
                  </div>
                  {item.price.length > 0 && (
                    <div
                      style={{
                        borderBottom:
                          item.price.length == priceIndex + 1
                            ? ""
                            : "1px solid #e8e6e6",
                        marginBottom: 5,
                      }}
                    />
                  )}
                </>
              ))}
              <div
                className="summaryKey my-1 d-flex justify-content-end"
                style={{ fontSize: 15 }}
              >
                Total: {summaryTotal(item.price)} /-
              </div>

              {activities.length > 1 && (
                <div
                  style={{
                    borderBottom:
                      index === activities.length - 1
                        ? ""
                        : "1px solid #cacccf",
                  }}
                />
              )}
            </div>
          ))
        )}
      </div>

      <div className="d-flex my-3 justify-content-between">
        <Button onClick={clear} style={{ border: "none" }}>
          Clear
        </Button>
        <Button
          type="primary"
          onClick={() => setBookingFormModal(true)}
          disabled={activities.length === 0 ? true : false}
        >
          Pay ₹ {grandTotal}/-
        </Button>
      </div>

      <div>
        <div style={{ borderBottom: "1px solid #cacccf" }}></div>
        <div className="SummaryCard_Footer">
          <div>*Working Hours: 10:00AM to 12:00 AM</div>
          <div>*We are open on all Public Holidays</div>
          <div>*Terms and Conditions Apply</div>
        </div>
      </div>
      {bookingFormModal && (
        <BookingFormModal
          activities={activities}
          open={bookingFormModal}
          close={() => setBookingFormModal(false)}
          grandTotal={grandTotal}
          makePayment={(data: any) => makePayment(data)}
        />
      )}
      {ticketModalOpen && (
        <TicketModal
          view={ticketModalOpen}
          onClose={() => setTicketModalOpen(false)}
          ticketNo={ticketNo}
          booking={true}
        />
      )}
    </div>
  );
}

export default SummaryScreen;
