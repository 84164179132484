import React, { useState } from "react";
import { Button, Form, Input, Switch, notification, Modal, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST, COMPRESS_IMAGE } from "../../utils/apiRequest";
import { useForm } from "antd/es/form/Form";
import ImagePicker from "../components/ImagePicker";
import { IoRemoveCircleOutline } from "react-icons/io5";

function ActivityFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [image_url, setImageUrl] = useState<any>(props?.data?.image);
  const [file, setFile] = useState<any>();
  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let images: any = await COMPRESS_IMAGE(file);
      var id = props?.data?.id;
      let activty = {
        id: props?.data?.id,
        name: val?.name,
        duration: val?.duration,
        description: val?.description,
        status: val?.status,
        pricing: val?.pricing,
        publish: val?.publish,
        image: file?.name ? images?.url : props?.data?.image,
      };
      let url = id ? API.ACTIVITY_EDIT : API.ACTIVITY_ADD;
      const data: any = await POST(url, activty);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Activity Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Activity`}</div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={900}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          name: props?.data?.name,
          duration: props?.data?.duration,
          description: props?.data?.description,
          status: props?.data?.status,
          publish: props?.data?.publish,
          pricing: props?.data?.pricing,
        }}
      >
        <Row>
          <Col md={5}>
            <label className="formLabel">Activity Name</label>
            <Form.Item name={"name"}>
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Activity Duration</label>
            <Form.Item name={"duration"}>
              <Input size="large" type="number" />
            </Form.Item>
            <label className="formLabel">Decription</label>
            <Form.Item
              name={"description"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.TextArea size="large" rows={2} />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <label className="formLabel">Status</label>
                <Form.Item
                  name="status"
                  valuePropName={props?.data.status ? "checked" : "null"}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <label className="formLabel">Publish</label>
                <Form.Item
                  name="publish"
                  valuePropName={props?.data.publish ? "checked" : "null"}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <label className="formLabel">Photo</label>
            <Form.Item name="image">
              <ImagePicker
                onChange={(value: any) => {
                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={image_url}
              />
            </Form.Item>
            <h5>Pricing</h5>
            <hr />
            <Form.List name="pricing">
              {(fields, { add, remove }) => (
                <>
                  <Row>
                    <Col sm={5}>
                      <div className="form-label">Age Category</div>
                    </Col>
                    <Col sm={5}>
                      <div className="form-label">Price</div>
                    </Col>
                  </Row>
                  {fields.map((field) => (
                    <Row>
                      <Col sm={5}>
                        <Form.Item
                          rules={[{ required: true, message: "Enter Age" }]}
                          name={[field.name, "age"]}
                          style={{ marginTop: -8 }}
                        >
                          <Select size="large">
                            <Select.Option value={"children"}>
                              Children
                            </Select.Option>
                            <Select.Option value={"adult"}>Adult</Select.Option>
                            <Select.Option value={"seniors"}>
                              Seniors
                            </Select.Option>
                            <Select.Option value={"others"}>
                              Others
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={5}>
                        <Form.Item
                          rules={[{ required: true, message: "Enter Price" }]}
                          name={[field.name, "price"]}
                          style={{ marginTop: -8 }}
                        >
                          <Input
                            size="large"
                            placeholder="Price"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={2}>
                        <Form.Item style={{ marginTop: -8 }}>
                          <Button
                            size="large"
                            onClick={() => remove(field.name)}
                            block
                          >
                            <IoRemoveCircleOutline size={20} color="red" />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col sm={6}></Col>
                    <Col sm={6}>
                      <Button size="large" block onClick={() => add()}>
                        Add Price +
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6}></Col>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Button size="large" danger block onClick={props.onCancel}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {props?.data?.id ? "Edit" : "Create"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ActivityFormModal;
