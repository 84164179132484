import React, { useEffect, useState } from "react";
import PageHeaders from "../../components/pageHeaders";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { Button, Card, DatePicker, Form, Input, notification } from "antd";
import { CiSearch } from "react-icons/ci";
import Loading from "../../components/loading";
import { API } from "../../config/Api";
import { DELETE, POST } from "../../utils/apiRequest";
import BookingDataTable from "./dataTable";
import HorizontalLineLoader from "../components/loader";
import { IoReload } from "react-icons/io5";

function BookingScreen() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getBooking();
  }, [page, take]);

  const getBooking = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        bookingId: form.getFieldValue("bookingId"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.BOOKING_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };
  const onView = (val: any) => {
    navigate(`/Auth/bookingDetails/${val}`, { state: { val } });
  };
  const createOrEditBooking = (booking: any) => {
    navigate(`/Auth/createBooking`, { state: { booking } });
  };
  const deleteBooking = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.BOOKING_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Booking Deleted",
        });
        getBooking();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Booking not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Booking not deleted",
      });
    }
  };
  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Booking"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getBooking}>
          <div className="bookingScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            <Form.Item name={"bookingId"} noStyle>
              <Input
                placeholder="Booking id"
                style={{ width: 200 }}
                allowClear
              />
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => createOrEditBooking({})} type="primary">
                Create +
              </Button>
            </Form.Item>

            {/* <Button className="refreshBtn" onClick={() => getBooking()}>
              <IoReload color="#000" size={15} />
            </Button> */}
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <BookingDataTable
              data={data}
              onEdit={(item: any) => createOrEditBooking(item)}
              onView={(item: any) => onView(item)}
              delete={(id: any) => deleteBooking(id)}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading2(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default BookingScreen;
