import { Button, Card, Form, Input, message } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";

function BookingForm() {
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const onFinish = async (val: any) => {
    console.log(val, "val======================>>>>>>>>>>>>>>>>>>");

    setIsLoading(true)
    try {
      let Booking = {
        id: val?.id,
        jobId: val?.jobId,
        staff: val?.staff,
        clockIn: val?.clockIn,
        clockOut: val?.clockOut,
        activities: val?.activities,
        usedSlots: val?.usedSlots,
        createdAt: val?.createdAt,
        Action: val?.Action,

      };

      let url = API.BOOKING_ADD;
      const data: any = await POST(url, Booking);
      if (data) {
        message.success("Succesfully Created")
        setIsLoading(false)
      }
      console.log(Booking)
      setData(data);
      console.log(url)
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Card>
        <PageHeader title={"CREATE BOOK"} />
        <Container>
          <Form onFinish={onFinish} initialValues={{
            name: location?.state?.data?.name,
            role: location?.state?.data?.role,

          }}>
            <Row>
              <Col md={6}>
                <label className="formLabel">Jobid</label>
                <Form.Item name={"jobId"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">staff</label>
                <Form.Item name={"role"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">clockIn</label>
                <Form.Item name={"phone"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">Email</label>
                <Form.Item name={"Email"}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={6}>
                <label className="formLabel">clockOut</label>
                <Form.Item name={"Address"}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={6}>
                <label className="formLabel">activities</label>
                <Form.Item name={"JobId"}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={6}>
                <label className="formLabel">usedSlots</label>
                <Form.Item name={"Status"}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={6}>
                <label className="formLabel">createdAt</label>
                <Form.Item name={"Status"}>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={6}>
                <label className="formLabel">Action</label>
                <Form.Item name={"Status"}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Col md={{ span: 4, offset: 8 }}>
              <Form.Item>
                <Button block htmlType="submit" loading={isLoading}>
                  SUBMIT
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Container>
      </Card>
    </>
  );
}
export default BookingForm;
function GET(url: string, arg1: null): any {
  throw new Error("Function not implemented.");
}

function setData(data: any) {
  throw new Error("Function not implemented.");
}

