import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";

function PaymentTable(props: any) {
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (_: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{rowIndex}</div>;
      },
    },
    {
      title: "Qr Code",
      dataIndex: "url",
      key: "url",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <div className="table-Action">
          {item ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: any) => {
        return (
          <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
        );
      },
    },

    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item?.id)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiEdit
                size={20}
                onClick={() => props?.onEdit(item)}
                style={{ cursor: "pointer", color: "orange" }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
              <Popconfirm
                title="Delete the Staff's Details"
                description="Are you sure you want to delete the Staff Details?"
                onConfirm={() => props?.delete(item?.id)}
                okText="Yes"
              >
                <AiOutlineDelete
                  size={20}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div>
        <Table
          columns={columns}
          dataSource={props?.data}
          pagination={false}
          size="small"
        />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            total={props?.meta?.total_count}
            showSizeChanger
            showTotal={(total) => `Total ${props?.meta?.total_count} Staffs`}
            onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          />
        </div>
      </div>
    </div>
  );
}
export default PaymentTable;
