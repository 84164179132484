import React, { useState } from "react";
import { Button, Form, Input, Select, Switch, notification, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
// import "cropperjs/dist/cropper.css";
import { useForm } from "antd/es/form/Form";

function JobFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      var id = props?.data?.id;
      let job = {
        id: props?.data?.id,
        name: val?.name,
        activities: val?.activities,
        staffId: Number(val?.staffId),
        status: props?.data?.id ? val?.status : true,
        totalSlots: 0,
        totalAmount: 0,
        dueAmount: 0,
        settledAmount: 0,
        upiSettle: 0,
        usedSlots: 0,
        isClockIn: "start",
      };
      let url = id ? API.JOBS_EDIT : API.JOBS_ADD;
      const data: any = await POST(url, job);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Job Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={props?.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Job`}</div>
      }
      onCancel={props?.onCancel}
      footer={false}
      width={900}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          name: props?.data?.name,
          staffId: props?.data?.staffId?.toString(),
          activities: props?.data?.activities,
          status: props?.data?.status,
        }}
      >
        <Row>
          <Col md={6}>
            <label className="formLabel">Name</label>
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please select Staff",
                },
              ]}
            >
              <Input size="large" placeholder="Job Name" allowClear />
            </Form.Item>
            <label className="formLabel">Activities</label>
            <Form.Item
              name={"activities"}
              rules={[
                {
                  required: true,
                  message: "Please select Activities",
                },
              ]}
            >
              <Select
                placeholder="Select Activities"
                mode="multiple"
                size="large"
              >
                {props?.activities?.map((item: any) => (
                  <Select.Option key={item?.id} value={item?.id.toString()}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6}>
            <label className="formLabel">Staff</label>
            <Form.Item
              name={"staffId"}
              rules={[
                {
                  required: true,
                  message: "Please select Staff",
                },
              ]}
            >
              <Select placeholder="Select Staff" size="large">
                {props?.staff?.map((item: any) => (
                  <Select.Option key={item?.id} value={item?.id?.toString()}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {props?.data?.isClockIn === "End" ? null : (
              <>
                <label className="formLabel">Status</label>
                <Form.Item
                  name="status"
                  valuePropName={props?.data.status ? "checked" : "null"}
                >
                  <Switch />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}></Col>
          <Col sm={3}>
            <Button size="large" danger block onClick={props.onCancel}>
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              loading={isLoading}
            >
              {props?.data?.id ? "Edit" : "Create"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default JobFormModal;
