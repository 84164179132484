import { Button, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "../../components/footer";
import Header from "../../components/header";
import HelmetSeo from "../../components/helmetSeo";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import ItemCard from "./components/itemCard";
import "./styles.scss";
import SummaryScreen from "./components/summaryScreen";
import Loading from "../../components/loading";

function BookingScreen() {
  const { activities } = useSelector((state: any) => state.activity);
  const [activitiesDet, setActivitiesDet] = useState([]);
  const [summaryModal, setSummaryModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [Notifications, contextHolder] = notification.useNotification();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      let obj = {
        page: 1,
        take: 100,
      };
      setIsLoading(true);
      let api = API.ACTIVITY_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        let filteredActivities = response?.data.filter(
          (activity: any) => !activity.name.startsWith("Ticket")
        );
        setActivitiesDet(filteredActivities);
        setIsLoading(false);
      } else {
        console.log("no activities");
        setIsLoading(false);
        notification.error({
          message: response?.message,
        });
      }
    } catch (err) {
      console.log(err, "err ---- >", err);
      setIsLoading(false);
      notification.error({
        message: "Something Went Wrong",
      });
    }
  };

  return (
    <div className="mainBox">
      <HelmetSeo
        title="Book your Tickets"
        description={`Book your Ticket Today and Enjoy Unlimited Fun and Adventure!`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/booknow.webp"
        canonicalurl="https://www.teenzania.com/booking"
        imageAlt="Book Now"
      />
      <Header />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Container>
            <Row>
              <div className="headingTxt">
                <br />
                <br />
                Choose the adventures you wanted to experience
              </div>
              <div className="subHeadingTxt mt-3">
                Enjoy the adventures you like by clicking book ticket. Add how
                many participants will be there then click the Confirm Booking
                button to
                <br />
              </div>
              <div className="subHeadingTxt mb-5">
                finish. If you wish to remove a specific adventure from
                selection just click on the clear booking button
              </div>

              <Col md={8}>
                <Row className="mb-5 cardsContainer">
                  {activitiesDet?.map((activity: any, i: any) => {
                    return (
                      <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        className="d-flex justify-content-center"
                      >
                        <ItemCard key={i} activity={activity} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col md={4} className="d-none d-md-block">
                <SummaryScreen />
              </Col>
            </Row>
          </Container>
          <Col className="d-block d-md-none">
            <div className="mobilePayNowBox">
              <Button
                type="primary"
                onClick={() => setSummaryModal(true)}
                disabled={activities.length === 0 ? true : false}
              >
                View Details
              </Button>
            </div>
          </Col>
        </>
      )}
      <Modal
        open={summaryModal}
        maskClosable={true}
        className="Modal_BackgroundColor"
        footer={false}
        onCancel={() => setSummaryModal(false)}
      >
        <SummaryScreen onClose={() => setSummaryModal(false)} />
      </Modal>

      <br />
      <br />
      <Footer />
    </div>
  );
}

export default BookingScreen;
