import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import flyImage from "../../../../assets/images/ziplinemobile.png";
import climb from "../../../../assets/images/climbingMobile.png";
const ClimbZip = () => {
  return (
    <>
      <div className="climbZipBg">
        <Row className="g-0">
          <Col lg={6} md={0} />
          <Col lg={6} md={0}>
            <div className="mountainTextBox mt-5 pt-5">
              <div className="fancytxtEnjoy">Enjoy</div>
              <div className="upperHeadTxt">THE REAL FEEL OF</div>
              <div className="headerTxtMain">Mountain Climbing</div>

              <div className="descEnjoy mt-1">
                Wall climbing is like city-based mountain climbing, offering a
                way for climbers to improve their skills safely on man-made
                walls. Unlike outdoor climbing, which involves real rocks and
                changing weather, wall climbing uses artificial walls with
                handholds and footholds to mimic real climbing.
                <br />
                <br /> It provides different difficulty levels, making it great
                for both new and experienced climbers to practice important
                techniques and build strength. These skills are also useful for
                outdoor climbing.
              </div>
            </div>
          </Col>
          <Col xs={12} className="blankDiv" />
          <Col lg={6} md={0}>
            <div className="flyTextBox">
              <div className="fancytxtFly">Fly</div>
              <div className="upperHeadTxt">FLY ABOVE THE TREES</div>
              <div className="headerTxtMain">Zipline Service</div>
              <div className="desc">
                Wall climbing serves as a bridge to mountain climbing, offering
                a platform for learning, training, and refining climbing
                techniques. It's an invaluable tool for climbers to prepare for
                the unpredictability and physical challenges of mountain
                climbing, enabling them to tackle more complex and demanding
                climbs in the great outdoors.
              </div>
            </div>
          </Col>
          <Col lg={6} sm={0} />
        </Row>
      </div>
      {/* mobile */}
      <div className="climbZipBgSm">
        <Row className="g-0 ">
          <img
            src={climb}
            alt="climb"
            width="100%"
            style={{ objectFit: "contain" }}
          />
          <div style={{ marginTop: "-250px" }}>
            <div
              className="fancytxt1Sm"
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              Enjoy
            </div>
            <div className="upperHeadTxt">THE REAL FEEL OF</div>
            <div className="headerTxtMain">Mountain Climbing</div>
          </div>
          <Col md={12}>
            <div className="descSm">
              Wall climbing is like city-based mountain climbing, offering a way
              for climbers to improve their skills safely on man-made walls.
              Unlike outdoor climbing, which involves real rocks and changing
              weather, wall climbing uses artificial walls with handholds and
              footholds to mimic real climbing.
              <br />
              <br /> It provides different difficulty levels, making it great
              for both new and experienced climbers to practice important
              techniques and build strength. These skills are also useful for
              outdoor climbing.
            </div>
          </Col>
          <img
            src={flyImage}
            alt="fly"
            width="100%"
            style={{ objectFit: "contain" }}
          />
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center flex-column "
          >
            <div
              className="fancytxt1Sm"
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Fly
            </div>
            <div className="upperHeadTxt">FLY ABOVE THE TREES</div>
            <div className="headerTxtMain">Zipline Service</div>
            <div className="descSm">
              Wall climbing serves as a bridge to mountain climbing, offering a
              platform for learning, training, and refining climbing techniques.
              It's an invaluable tool for climbers to prepare for the
              unpredictability and physical challenges of mountain climbing,
              enabling them to tackle more complex and demanding climbs in the
              great outdoors.
            </div>
            <br />
            <br />
            <div className="upperHeadTxt text-center">
              THERE ARE MORE FUN WAITING FOR YOU
            </div>
            <IoArrowDownCircleOutline color="#08493e" size={50} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClimbZip;
