import React from "react";
import PageHeaders from "../../components/pageHeaders";
import { DatePicker, Form, notification } from "antd";
import { CiSearch } from "react-icons/ci";

const WebSettings = () => {
  const [Notifications, contextHolder] = notification.useNotification();

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Website Settings"} backButton={true}></PageHeaders>
    </div>
  );
};

export default WebSettings;
