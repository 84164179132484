import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HelmetSeo from "../../components/helmetSeo";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HelmetSeo
        title="Privacy Policy"
        description={`Teenzania is committed to ensuring that your
        privacy is protected. Should we ask you to provide certain
        information by which you can be identified when using this
        website, and then you can be assured that it will only be used
        in accordance with this privacy statement.`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/teenzaniLogo.png"
        canonicalurl="https://www.teenzania.com/privacy"
        imageAlt="Privacy Policy"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> Legal Terms <IoIosArrowForward />{" "}
                Privacy Policy
              </div>
              <h3 className="heading_h3">Privacy Policy</h3>
              <br />
              <Col md={8} sm={12}>
                <p>
                  This privacy policy sets out how <strong>TEENZANIA</strong>{" "}
                  uses and protects any information that you give TEENZANIA when
                  you visit their website and/or agree to purchase from them.
                </p>
                <p>
                  <strong>TEENZANIA</strong> is committed to ensuring that your
                  privacy is protected. Should we ask you to provide certain
                  information by which you can be identified when using this
                  website, and then you can be assured that it will only be used
                  in accordance with this privacy statement.
                </p>
                <p>
                  <strong>TEENZANIA</strong> may change this policy from time to
                  time by updating this page. You should check this page from
                  time to time to ensure that you adhere to these changes.
                </p>
                <p>We may collect the following information:</p>
                <p>Name</p>
                <p>Contact information including email address</p>
                <p>
                  Demographic information such as postcode, preferences and
                  interests, if required
                </p>
                <p>
                  Other information relevant to customer surveys and/or offers
                </p>
                <p>What we do with the information we gather</p>
                <p>
                  We require this information to understand your needs and
                  provide you with a better service, and in particular for the
                  following reasons:
                </p>
                <p> Internal record keeping.</p>
                <p>
                  We may use the information to improve our products and
                  services.
                </p>
                <p>
                  We may periodically send promotional emails about new
                  products, special offers or other information which we think
                  you may find interesting using the email address which you
                  have provided.
                </p>
                <p>
                  From time to time, we may also use your information to contact
                  you for market research purposes. We may contact you by email,
                  phone, fax or mail. We may use the information to customise
                  the website according to your interests.
                </p>
                <p>
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorised access or disclosure we have
                  put in suitable measures.
                </p>
                <p>How we use cookies</p>
                <p>
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyze web traffic or lets you know when
                  you visit a particular site. Cookies allow web applications to
                  respond to you as an individual. The web application can
                  tailor its operations to your needs, likes and dislikes by
                  gathering and remembering information about your preferences.
                </p>
                <p>
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyze data about webpage traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                </p>
                <p>
                  Overall, cookies help us provide you with a better website, by
                  enabling us to monitor which pages you find useful and which
                  you do not. A cookie in no way gives us access to your
                  computer or any information about you, other than the data you
                  choose to share with us.
                </p>
                <p>
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website.
                </p>
                <p>Controlling your personal information</p>
                <p>
                  You may choose to restrict the collection or use of your
                  personal information in the following ways:
                </p>
                <p>
                  whenever you are asked to fill in a form on the website, look
                  for the box that you can click to indicate that you do not
                  want the information to be used by anybody for direct
                  marketing purposes
                </p>
                <p>
                  if you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to or emailing us at &nbsp;
                  <a href="mailto: teenzaniaadventurepark@gmail.com">
                    teenzaniaadventurepark@gmail.com
                  </a>
                </p>
                <p>
                  We will not sell, distribute or lease your personal
                  information to third parties unless we have your permission or
                  are required by law to do so. We may use your personal
                  information to send you promotional information about third
                  parties which we think you may find interesting if you tell us
                  that you wish this to happen.
                </p>
                <p>
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to OPPOSITE CHAIN TREE,
                  8/494, Kozhikode Vythiri Gudallur Road, LAKKIDI, Vythiri
                  Wayanad KERALA 673576 . or contact us at{" "}
                  <a href="tel:+918573900900">8573900900</a> or
                  <a href="mailto: teenzaniaadventurepark@gmail.com">
                    {" "}
                    &nbsp;teenzaniaadventurepark@gmail.com
                  </a>{" "}
                  as soon as possible. We will promptly correct any information
                  found to be incorrect.
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
