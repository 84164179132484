import {
  Button,
  Pagination,
  Popconfirm,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";
import { API } from "../../config/Api";
import { POST, PUT } from "../../utils/apiRequest";
import { useState } from "react";
import { Container } from "react-bootstrap";

function MobileAppDataTable(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const handlePublishToggle = async (id: number, publish: boolean) => {
    try {
      setIsLoading(true);
      let activty = {
        publish: publish,
      };
      let url = API.UPDATE_PUBLISH + id;
      const data: any = await PUT(url, activty);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Successfully Updated`,
        });
        setIsLoading(false);
        // props?.loadData();
        props?.onSuccess();
      } else {
        notification.error({
          message: "Failed",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <div className="table-Action">
          {item ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      ),
    },

    {
      title: "Publish",
      dataIndex: "publish",
      key: "publish",
      render: (publish: any, record: any) => (
        <div className="table-action">
          <Switch
            disabled={!record?.status}
            checked={publish}
            onChange={(checked) => handlePublishToggle(record.id, checked)}
          />
        </div>
      ),
    },
    {
      title: "Download",
      dataIndex: "link",
      key: "link",
      render: (link: any) => (
        <div className="table-action">
          <Button type="primary" onClick={() => window.open(link, "_blank")}>
            Download
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Container>
        <Table
          columns={columns}
          dataSource={props?.data}
          pagination={false}
          size="small"
        />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            total={props?.meta?.total_count}
            showSizeChanger
            showTotal={(total) => `Total ${props?.meta?.total_count} `}
            onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          />
        </div>
      </Container>
    </div>
  );
}
export default MobileAppDataTable;
