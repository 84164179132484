import { useEffect, useState } from "react";
import { Button, Card, DatePicker, Form, Select, notification } from "antd";
import ActivitesDataTable from "./dataTable";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { API } from "../../config/Api";
import { DELETE, POST } from "../../utils/apiRequest";
import StaffFormModal from "./form";
import HorizontalLineLoader from "../components/loader";
import { IoReload } from "react-icons/io5";

function Activity() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(30);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getActvites();
  }, [page, take]);

  const getActvites = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.ACTIVITY_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const deleteActivity = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.ACTIVITY_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Activity Deleted",
        });
        getActvites();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Activity not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Activity not deleted",
      });
    }
  };

  const editActivity = (act: any) => {
    try {
      setSelectedItem(act);
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  const onView = (val: any) => {
    navigate(`/Auth/activityDetails/${val}`, { state: { val } });
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Activites"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getActvites}>
          <div className="PageHeaderBox">
            <Form.Item name={"status"} noStyle>
              <Select allowClear style={{ minWidth: 100 }} placeholder="Status">
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>Inactive</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => editActivity({})} type="primary">
                Create +
              </Button>
            </Form.Item>

            <Button className="refreshBtn" onClick={() => getActvites()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <ActivitesDataTable
                data={data}
                onEdit={(item: any) => editActivity(item)}
                onView={(item: any) => onView(item)}
                delete={(id: any) => deleteActivity(id)}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          )}
        </Card>
      </div>
      {modalVisible ? (
        <StaffFormModal
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={() => {
            setIsLoading2(true);
            editActivity({});
            getActvites();
          }}
        />
      ) : null}
    </div>
  );
}
export default Activity;
