import React, { useEffect, useState } from "react";
import PageHeaders from "../../components/pageHeaders";
import { TicketTemplateHtml } from "./components/ticketTemplate";
import { Button, message } from "antd";
import { IoMdCloudDownload } from "react-icons/io";
import moment from "moment";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";

const TicketTemplate = () => {
  const pdfTemp = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  const params = useParams();
  const [isLoading2, setIsLoading2] = useState(false);
  const [data, setData] = useState<any>();
  const [html, setHTML] = useState<any>();
  const [ticketInfo, setTicketInfo] = useState<any>({});

  useEffect(() => {
    loadTicketData();
  }, []);

  const loadTicketData = async () => {
    try {
      const response: any = await GET(
        API.TICKET_DETAILS_INFO + params?.id,
        null
      );
      if (response?.status) {
        setData(response?.data);
        const html = await TicketTemplateHtml(response?.data);
        setTicketInfo(response?.data);
        setHTML(html);
      }
      //   setIsLoading(false);
    } catch (err) {
      //   setIsLoading(false);
    }
  };

  async function downloadPDF() {
    const templates = html;
    pdfTemp.html(templates, {
      callback: function (pdf) {
        pdf.save(ticketInfo?.ticketNumber + ".pdf");
      },
      x: 100,
      width: 180,
      html2canvas: {
        scale: 0.3,
        height: document.documentElement.offsetHeight,
      },
    });
  }
  return (
    <div>
      <PageHeaders title={"Print Ticket"} backButton={true}>
        <Button
          icon={<IoMdCloudDownload />}
          type="primary"
          onClick={() => downloadPDF()}
          loading={isLoading2}
        >
          Download
        </Button>
      </PageHeaders>
      <div className="screenBox">
        <hr />
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
      <br />
    </div>
  );
};

export default TicketTemplate;
