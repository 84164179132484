import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function QuickMenu(props: any) {
  const navigate = useNavigate();

  return (
    <div style={{ width: 250 }}>
      <div className="heading-txt2">Quick Access</div>
      <hr />
      <Container fluid>
        <div onClick={() => navigate("ticket")} className="QuickMenu-item">
          Create Ticket
        </div>
        <div onClick={() => navigate("staff")} className="QuickMenu-item">
          Create Staff
        </div>
        <div onClick={() => navigate("job")} className="QuickMenu-item">
          Create Job
        </div>
        <div onClick={() => navigate("activity")} className="QuickMenu-item">
          Create Activity
        </div>
      </Container>
    </div>
  );
}

export default QuickMenu;
