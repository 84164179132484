import { Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

function PageHeader(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <div className="pageHeaderConatiner">
        <Row className="align-items-center">
          <Col md={6}>
            <div style={{ display: "flex" }}>
              <div className="headerIconBox">
                <BsArrowLeftCircle
                  size={30}
                  color="#9BA9B6"
                  cursor={"pointer"}
                  onClick={() => navigate(-1)}
                />
              </div>
              <span className="headerTitle">{props.title}</span>
            </div>
          </Col>
          <Col md={3}></Col>
          <Col md={3}>
            {props?.buttonTxt && (
              <Button
                type="primary"
                block
                size="large"
                onClick={() => navigate(props?.navigate)}
              >
                {props?.buttonTxt}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PageHeader;
