async function TicketTemplateHtml(props: any) {
  const qr = require("qrcode");
  //   const { activities, ticketNumber, status }: any = props;

  const qrCodeDataUrl = await qr?.toDataURL(props?.ticket?.ticketNumber);
  const img = require("../../../assets/images/teenzaniLogo.png");
  //   console.log("activities ----> ", activities);

  return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins">
        <title>Document</title>
    </head>
    <body>
          <style>
              .container {
                  width: 100%;
                  border-radius: 10px;
                  background-color: #fff;
              }
              .companyLogo {
                  width: 100%;
                  height: 100px; 
              }
              .Montserrat{
                  font-family: "Poppins";
              }
              .spaceBetween{
                  display: flex;
                  justify-content: space-between;
              }
              .textAlignCeter{
                  text-align: center;
              }
              .containerCover{
                  align-items: center;
                  background-color: #f2f2f2;
                  padding: 20px 0px;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
              }
              .mainContainer{
                  background-color: white;
                  border-radius: 20px;
                  padding: 20px;
                  width: 288px;
              }
              .box1{
                  text-align: center;
                  line-height: 30px;
                  margin-top:6px;
              }
              .title{
                  font-weight: 900;
                  font-size: 26px;
              }
              .text1{
                  font-weight: 600;
                  font-size: 18px;
              }
              .text2{
                  font-weight: 500;
                  font-size: 15px;
              }
              .text3{
                  font-weight: 400;
                  font-size: 12px;
              }
  
              .text4{
                  font-weight: 600;
                  font-size: 10px;
                  padding:2px 10px;
                  border: 1px solid #ff6852;
                  border-radius: 30px;
                  background-color: #ff6852;
                  color: #fff;
              }
  
              .text5{
                  font-weight: 600;
                  font-size: 10px;
                  padding:2px 10px;
                  border: 1px solid #419149;
                  border-radius: 30px;
                  background-color: #419149;
                  color: #fff;
              }

              .text6{
                font-weight: 600;
                font-size: 12px;
                padding:2px 15px;
                border: 1px solid red;
                border-radius: 30px;
                background-color: #fff;
                color: red;
            }

            .text7{
                font-weight: 600;
                font-size: 12px;
                padding:2px 15px;
                border: 1px solid green;
                border-radius: 30px;
                background-color: #fff;
                color: green;
            }
  
  
              .border1{
                  border: dashed 1px black;
                  margin: 10px 0px;
              }
              .box2{
                  display: grid;
                  justify-items: center;
              }
              .QR{
                  width: 150px;
              }
              .item-head-box{
                  display:flex;
                  flex-direction:row;
                  align-items:center;
                  justify-content:space-between;
                  margin-top: 20px;
              }
          </style>
          <div class="container">
              <div class="containerCover">
                  <div class="mainContainer">
                      <div class="box1">
                      <img src="${img}" class="companyLogo" alt="Logo">
                      </div>
                      <div class="border1"></div>
                      <div class="box2">
                          <img class="QR" src="${qrCodeDataUrl}" alt="QR Code">
                          <div class="text1 Montserrat">${props?.ticket?.ticketNumber}</div>
                          <div class="${props?.ticket?.status ? "text6" : "text7"} Montserrat">${props?.ticket?.status ? "Used" : "Active"}</div>
                      </div>
                      <div class="border1"></div>
                      ${props?.items
                        ?.map((item: any, idx: number) => {
                          return `
                                  <div class="item-head-box">
                                  <div class="text1 Montserrat">
                                  ${idx + 1}. ${item?.activityName ?? "---"} * ${item?.count}
                                  </div>
                                  
                                  <div class="${item?.status ? "text4" : "text5"} Montserrat">${item?.status ? "Used" : "Active"}</div>
                                  </div>
  
  
                                  <table class="text2 Montserrat" style="width: 100%">
                                  ${item?.categories
                                    ?.map((pricings: any, id: number) => {
                                      console.log("pricings ---->", pricings);
                                      return `<tr key={${id}}>
                                              <td>${pricings?.age ?? "---"}</td>
                                              <td>${pricings?.count ?? "-"}</td>
                                              <td style="text-align: end">${pricings?.price ?? 0}</td>
                                              </tr>`;
                                    })
                                    .join("")}
                                  </table>`;
                        })
                        .join("")}
                      <div class="border1"></div>
                      <div class="spaceBetween">
                          <div class="text2 Montserrat">
                              <div>Total Amount</div>
                              <div>Tax</div>
                              <div>Discount</div>
                              <div>Grand Total</div>
                          </div>
                          <div class="text2 Montserrat">
                          <div>${props?.ticket?.totalAmount ?? 0}</div>
                          <div>${props?.ticket?.taxAmt ?? 0}</div>
                          <div>${props?.ticket?.discount ?? 0}</div>
                          <div>${props?.ticket?.grandTotal ?? 0}</div>
                          </div>
                      </div>
                      <br>
                      <br>
                      <div class="text2 Montserrat spaceBetween">
                          <div>Paid Amount</div>
                          <div>${props?.ticket?.grandTotal ?? 0}</div>
                      </div>
                      <div class="border1"></div>
                      <div class="textAlignCeter text2 Montserrat">Thank you for Coming <br> Have an adventurous day !</div>
                      <br>
                      <div class="textAlignCeter text2 Montserrat">Powered By</div>
                      <div class="textAlignCeter text1 Montserrat">Bairuha Tech</div>
                  </div>
              </div>
          </div>
    </body>
    </html>`;
}
export { TicketTemplateHtml };
