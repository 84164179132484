import { Card } from "antd";
import { Col, Row, Table } from "react-bootstrap";
import { BsCashCoin } from "react-icons/bs";
import UPI from "../../../assets/images/upi.png";
import CASH from "../../../assets/images/cash.png";

const SalesDataTable = (props: any) => {
  console.log(props?.methodSum);
  return (
    <div>
      <Row>
        <Col sm={"4"}>
          <Card>
            <div className="report-Box">
              <div>
                <div className="report-text1">TOTAL AMOUNT</div>
                <div className="report-text2">
                  {Number(props?.total).toFixed(2)}
                </div>
              </div>
              <div>
              <img src={CASH} style={{width:50}}/>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={"4"}>
          <Card>
            <div className="report-Box">
              <div>
                <div className="report-text1">UPI</div>
                <div className="report-text2">
                  {Number(props?.methodSum?.upi).toFixed(2)}
                </div>
              </div>
              <div>
                <img src={UPI} style={{width:100}}/>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={"4"}>
          <Card>
            <div className="report-Box">
              <div>
                <div className="report-text1">CASH</div>
                <div className="report-text2">
                  {Number(props?.methodSum?.cash).toFixed(2)}
                </div>
              </div>
              <div>
                <BsCashCoin size={40} color="grey"/>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <Table bordered>
        <thead>
          <tr>
            <td className="report-headtd">Day</td>
            <td className="report-headtd">Payment Method</td>
            <td className="report-headtd" style={{ textAlign: "right" }}>
              Total
            </td>
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((item: any) => {
            return (
              <tr key={item?.date}>
                <td>
                  <div className="report-titletd">{item?.date}</div>
                </td>
                <td style={{ width: 300 }}>
                  <Table
                    bordered
                    style={{ marginBottom: -10 }}
                    className="report-subtable"
                  >
                    <tbody>
                      {item?.transactions?.map((trans: any) => {
                        return (
                          <tr>
                            <td style={{ padding: 1, paddingLeft: 5 }}>
                              {trans?.paymentMethod}
                            </td>
                            <td
                              style={{
                                padding: 1,
                                paddingLeft: 5,
                                paddingRight: 5,
                                textAlign: "right",
                              }}
                            >
                              {Number(trans?.totalAmount).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </td>
                <td style={{ width: 300 }}>
                  <div className="report-sumtd">
                    {Number(item?.totalAmount).toFixed(2)}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SalesDataTable;
