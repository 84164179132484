import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { IoIosMore } from "react-icons/io";
import NoData from "../../components/noData";
function Bookings(props: any) {
  return (
    <div className="white-card">
      {props?.data?.length ? (
        <>
          {props?.data.map((item: any, index: number) => (
            <div key={index} className="Dashboard-booking">
              <div className="Dashboard-cardBox1">
                <HiOutlineDocumentCheck size={30} />
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  Booking ID : <strong>{item?.bookingId}</strong>
                </div>
                <div>{item?.email}</div>
                <h5>{Number(item?.grandTotal).toFixed(2)}</h5>
              </div>
              <div>
                <IoIosMore />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
}

export default Bookings;
