import { Pagination, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { CiRead } from "react-icons/ci";
function TicketsDataTable(props: any) {
  const columns: any = [
    {
      title: "Ticket Number",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Booking Id",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (record: any) => {
        return <div className="table-Txt">{record ?? "-"}</div>;
      },
    },
    {
      title: "Created From",
      dataIndex: "from",
      key: "from",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "ticketType",
      key: "ticketType",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "paidAmount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      key: "paidAmount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (record: any) => (
        <div className="table-Txt">{record?.toUpperCase()}</div>
      ),
    },
    // {
    //   title: "Start Time",
    //   dataIndex: "start",
    //   key: "start",
    //   render: (record: any) => (
    //     <div className="table-Txt">
    //       {moment(record).format("DD-MM-YYYY [at] hh:mm a")}
    //     </div>
    //   ),
    // },
    // {
    //   title: "End Time",
    //   dataIndex: "end",
    //   key: "end",
    //   render: (record: any) => (
    //     <div className="table-Txt">
    //       {moment(record).format("DD-MM-YYYY [at] hh:mm a")}
    //     </div>
    //   ),
    // },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("llll")}</div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => (
        <div className="table-Txt">
          {record ? (
            <Tag color="red">Used</Tag>
          ) : (
            <Tag color="green">Not Used</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      with: 5,

      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item?.ticketNumber)}
              />
            </Tooltip>
          </div>
          <div></div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta && props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) =>
            `Total ${props?.meta?.total_count || 0} Tickets`
          }
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}
export default TicketsDataTable;
