import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../config/logo-white.svg";
import {
  IoMailOpenOutline,
  IoLocationOutline,
  IoCallOutline,
} from "react-icons/io5";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { CiMail } from "react-icons/ci";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col sm={2} xs={12}>
            <div className="Footer-logo">
              <img src={logo} alt="logo" onClick={() => navigate("/")} />
            </div>
          </Col>
          <Col sm={3} xs={12}>
            <div className="Footer-text1">
              Teenzania invites everyone to explore and enjoy the beauty of the
              world through adventure tourism and game parks. It offers fun and
              excitement for all ages, making it a unique attraction in Wayanad.
            </div>
          </Col>
          <Col sm={2} xs={6}>
            <div className="Footer-text2">Activities</div>
            <div className="Footer-text3" onClick={() => navigate("/booking")}>
              Book Now
            </div>
            <div
              className="Footer-text3"
              onClick={() => navigate("/selfticket")}
            >
              Get Tickets
            </div>
            <div className="Footer-text3">Rides</div>
            <div className="Footer-text3" onClick={() => navigate("/aboutus")}>
              Park
            </div>
            <div className="Footer-text3" onClick={() => navigate("/resort")}>
              Resort
            </div>
            <div
              className="Footer-text3"
              onClick={() => navigate("/park_timing")}
            >
              Park Timing
            </div>
          </Col>
          <Col sm={2} xs={6}>
            <div className="Footer-text2">About</div>
            <div className="Footer-text3" onClick={() => navigate("/share")}>
              Share
            </div>
            <div className="Footer-text3" onClick={() => navigate("/download")}>
              Download
            </div>
            <div className="Footer-text3" onClick={() => navigate("/privacy")}>
              Privacy Policy
            </div>
            <div className="Footer-text3" onClick={() => navigate("/terms")}>
              Terms and Conditions
            </div>
            <div className="Footer-text3" onClick={() => navigate("/refund")}>
              Cancellation and Refund
            </div>
            <div className="Footer-text3" onClick={() => navigate("/delivery")}>
              Shipping and Delivery
            </div>
          </Col>
          <Col sm={2} xs={12}>
            <div className="Footer-Box2">
              <div className="Footer-text2">Contact Us</div>
              <div className="Footer-text3">
                <a
                  href="tel:+917001800600"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IoCallOutline size={25} color="#f9da7f" />
                  &nbsp; +917001800600
                </a>
              </div>
              <div className="Footer-text3 ">
                <IoMailOpenOutline size={22} color="#f9da7f" /> &nbsp;
                contact@teenzania.com
              </div>
              <div className="Footer-text3">
                <IoLocationOutline size={22} color="#f9da7f" /> &nbsp; Lakkidi,
                Wayanad, Kerala
              </div>
              <div
                className="Footer-text3 "
                onClick={() => navigate("/contact-us")}
              >
                <CiMail size={22} color="#f9da7f" /> &nbsp; Contact
              </div>
              <div className="Footer-Box1">
                <a
                  href="https://www.facebook.com/profile.php?id=61557318977943"
                  target="_blank"
                >
                  <FaFacebookF color="#ffffff" />
                </a>

                <a href="https://twitter.com/Teenzania_" target="_blank">
                  <FaXTwitter color="#ffffff" />
                </a>

                <a
                  href="https://www.instagram.com/teenzania_adventure_park/ "
                  target="_blank"
                >
                  <FaInstagram color="#ffffff" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <div className="Footer-copyRightTxt">
          Copyright © 2024. Teenzania. All rights reserved.
        </div>
      </Container>
    </div>
  );
}
export default Footer;
