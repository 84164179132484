import "./styles.scss";
import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";

import LayoutHeader from "./layoutHeader";
import SideBar from "./sideBar";

import Dashboard from "../admin/dashboard";

import StaffScreen from "../admin/staffScreen";
import StaffDetails from "../admin/staffScreen/details";

import Activity from "../admin/Activities";
import ActivityDetails from "../admin/Activities/details";

import JobsScreen from "../admin/jobsScreen";
import JobDetails from "../admin/jobsScreen/details";

import TicketData from "../admin/Tickets";
import TicketForm from "../admin/Tickets/ticketform";

import CardScreen from "../admin/cardScreen";
import CardDetails from "../admin/cardScreen/details";

import BookingScreen from "../admin/bookingScreen";
import TicketTemplate from "../website/ticketTemplate";

import BookingForm from "../admin/bookingScreen/bookingform";
import ReportScreen from "../admin/reports";
import Settings from "../admin/settings";
import WebSettings from "../admin/webSettings";
import BookingDetails from "../admin/bookingScreen/details";
import CreateBooking from "../admin/bookingScreen/createBooking";
import PaymentDetails from "../admin/settings/payment/details";
import Payment from "../admin/settings/payment";
import Transactions from "../admin/transactions";
import Offers from "../admin/Offers";
import BookingSummary from "../website/bookingScreen/components/summaryScreen";
import JobDetailsetails from "../admin/reports/staff/details";
import Feedback from "../admin/feedback";
// import Feedback from "react-bootstrap/esm/Feedback";
import Commission from "../admin/commission";
import CommissionDetails from "../admin/commission/viewData";
import Discount from "../admin/discountCoupen";
import DiscountDetails from "../admin/discountCoupen/details";

function Routing() {
  const { Sider, Content } = Layout;
  return (
    <Layout>
      <Sider
        style={{ backgroundColor: "#08493e", height: "100vh" }}
        width={250}
      >
        <SideBar />
      </Sider>
      <Layout>
        <LayoutHeader />
        <Content
          style={{ background: "#f5fffd", height: "70vh", overflow: "scroll" }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />

            <Route path="/staff" element={<StaffScreen />} />
            <Route path="/staffDetails/:id" element={<StaffDetails />} />

            <Route path="/activity" element={<Activity />} />
            <Route path="/activityDetails/:id" element={<ActivityDetails />} />

            <Route path="/job" element={<JobsScreen />} />
            <Route path="/jobDetails/:id" element={<JobDetails />} />

            <Route path="/ticket" element={<TicketData />} />
            <Route path="/ticket/create" element={<TicketForm />} />

            <Route path="/bookings" element={<BookingScreen />} />
            <Route path="/bookings/create" element={<BookingForm />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/paymentDetails/:id" element={<PaymentDetails />} />

            <Route path="/job" element={<JobsScreen />} />
            <Route path="/jobDetails/:id" element={<JobDetails />} />

            <Route path="/cards" element={<CardScreen />} />
            <Route path="/cardsDetails/:id" element={<CardDetails />} />

            <Route path="/ticket" element={<TicketData />} />
            <Route path="/ticket/create" element={<TicketForm />} />
            <Route path="/ticket/print/:id" element={<TicketTemplate />} />

            <Route path="/bookings" element={<BookingScreen />} />
            <Route path="/bookings/create" element={<BookingForm />} />

            <Route path="/reports" element={<ReportScreen />} />
            <Route path="/feedback" element={<Feedback />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/offers" element={<Offers />} />

            <Route path="/web-settings" element={<WebSettings />} />
            <Route path="/commission" element={<Commission />} />
            <Route
              path="/CommissionDetails/:id"
              element={<CommissionDetails />}
            />
            <Route path="/bookingDetails/:id" element={<BookingDetails />} />
            <Route path="/createBooking" element={<CreateBooking />} />
            <Route path="/Summary" element={<BookingSummary />} />

            <Route path="/transactions" element={<Transactions />} />
            <Route path="/discount" element={<Discount />} />
            <Route path="/discountDetails/:id" element={<DiscountDetails />} />
            <Route
              path="/report-job-details/:id"
              element={<JobDetailsetails />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Routing;
