import { Button, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import moment from "moment";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";

const ViewTransModal = (props: any) => {
  const [user, setUser] = useState([]);
  useEffect(() => {
    findUser();
  }, []);
  const findUser = async () => {
    try {
      let api = API.STAFFS_DETAILS + props?.data?.userId;
      const response: any = await GET(api);
      if (response?.status) {
        setUser(response?.data?.name);
      }
    } catch (err) {}
  };
  return (
    <div>
      <Modal
        open={props?.visible}
        title={
          <div style={{ fontSize: 25 }}>
            Transaction #{props?.data?.transactionId}
          </div>
        }
        onCancel={props?.onCancel}
        footer={false}
        width={500}
      >
        <hr />
        <div className="transactionItem1">
          <div className="transactionTxt1">Transaction Type :</div>
          <div className="transactionTxt2">
            {props?.data?.type?.toUpperCase()}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Payment Method :</div>
          <div className="transactionTxt2">
            {props?.data?.paymentMethod === "cash" ? (
              <Tag color="green">
                {props?.data?.paymentMethod?.toUpperCase()}
              </Tag>
            ) : props?.data?.paymentMethod === "card" ? (
              <Tag color="red">{props?.data?.paymentMethod?.toUpperCase()}</Tag>
            ) : (
              <Tag color="blue">
                {props?.data?.paymentMethod?.toUpperCase()}
              </Tag>
            )}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Card No. :</div>
          <div className="transactionTxt2">{props?.data?.cardNo ?? "N/A"}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Card Id :</div>
          <div className="transactionTxt2">{props?.data?.cardId ?? "N/A"}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">User Name :</div>
          <div className="transactionTxt2">{user ?? "N/A"}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Amount :</div>
          <div className="transactionTxt2">{props?.data?.amount}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Ticket No. :</div>
          <div className="transactionTxt2">
            {props?.data?.ticketNo ?? "N/A"}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Booking Id :</div>
          <div className="transactionTxt2">
            {props?.data?.BookingId ?? "N/A"}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Created At :</div>
          <div className="transactionTxt2">
            {moment(props?.data?.createdAt).format("lll")}
          </div>
        </div>
        <br />
        <Row>
          <Col sm={12}>
            <Button size="large" danger block onClick={props.onCancel}>
              Close
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ViewTransModal;
