import { Button, Form, Input, Modal, Select, Switch, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { COMPRESS_IMAGE, POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import { Col, Row } from "react-bootstrap";
import ImagePicker from "../../components/ImagePicker";

function PaymentForm(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [image_url, setImageUrl] = useState<any>(props?.data?.qrCode);
  const [file, setFile] = useState<any>();
  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let images: any = await COMPRESS_IMAGE(file);
      var id = props?.data?.id;
      let activty = {
        id: props?.data?.id,
        status: val?.status,
        url: val?.url,
        provider: val?.provider,
        createdBy: val?.createdBy,
      };
      let url = id ? API.PAYMENT_EDIT : API.PAYMENT_ADD;
      const data: any = await POST(url, activty);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Payment Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };
  return (
    <Modal
      visible={props.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Payment`}</div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={900}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          url: props?.data?.url,
          status: props?.data?.status,
          createdBy: props?.data?.createdBy,
        }}
      >
        <Row>
          <Col md={6}>
            <label className="formLabel">Created By</label>
            <Form.Item
              name={"createdBy"}
              rules={[{ required: true, message: "Enter the Field" }]}
            >
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Provider</label>
            <Form.Item
              name={"provider"}
              rules={[{ required: true, message: "Select One Option" }]}
            >
              <Select size="large">
                <Select.Option value={"Google pay"}>Google Pay</Select.Option>
                <Select.Option value={"phone Pe"}>Phone Pe</Select.Option>
                <Select.Option value={"paytm"}>Paytm</Select.Option>
                <Select.Option value={"others"}>Others</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={6}>
            <label className="formLabel">Url</label>
            <Form.Item
              name="url"
              rules={[{ required: true, message: "Enter the Field" }]}
            >
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Status</label>
            <Form.Item
              name="status"
              valuePropName={props?.data.status ? "checked" : "null"}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={6}>
            <Button size="large" danger block onClick={props.onCancel}>
              Close
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              loading={isLoading}
            >
              {props?.data?.id ? "Edit" : "Create"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PaymentForm;
