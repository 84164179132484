import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { GET, POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import Loading from "../../../components/loading";
import PackageDataTable from "./dataTable";
import NoData from "../../components/noData";
import { Col, Row } from "react-bootstrap";
import { ExportToExcel } from "../../../utils/exportToExcel";
import { SiMicrosoftexcel } from "react-icons/si";
import moment from "moment";
import { IoReload } from "react-icons/io5";

const PackageReport = (props: any) => {
  const [form] = useForm();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  const [sdate, setSdate] = useState<any>(
    dayjs(new Date()).subtract(10, "day").format("YYYY-MM-DD")
  );
  const [ldate, setLdate] = useState<any>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const dateFormat = "YYYY/MM/DD";
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [packageData, setPackageData] = useState([]);
  const [startDate, setStartDate] = useState(firstDayOfMonth) as any;
  const [endDate, setEndDate] = useState(lastDayOfMonth) as any;
  const [isLoading2, setIsLoading2] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPkg, setSelectedPkg] = useState<any>({});
  const [summary, setSummary] = useState<any>({});

  useEffect(() => {
    getReport(page, take);
  }, []);

  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      let api = API.OFFERS_PICKER;
      const result: any = await GET(api, null);
      if (result?.status) {
        setPackages(result?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  //   const getReport = async (page: any, take: any) => {
  //     try {
  //       let api = API.PACKAGE_REPORT;
  //       let obj = {
  //         query: form.getFieldValue("query"),
  //         startDate: sdate,
  //         endDate: ldate,
  //         package: 1,
  //         page: page,
  //         take: take,
  //       };
  //       const report: any = await POST(api, obj);
  //       if (report?.status) {
  //         setPackageData(report.data);
  //         setMeta(report.meta);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //     }
  //   };

  const getReport = async (page: any, take: any) => {
    try {
      setIsLoading2(true);
      let api = API.PACKAGE_REPORT;
      const formData = form.getFieldsValue();
      const start = formData.date
        ? moment(formData.date[0].$d).startOf("month")
        : moment().startOf("month");
      const end = formData.date
        ? moment(formData.date[1].$d).endOf("month")
        : moment().endOf("month");
      setStartDate(start);
      setEndDate(end);
      const obj = {
        package: Number(formData.package),
        startDate: start,
        endDate: end,
      };
      const report: any = await POST(api, obj);
      if (report?.status) {
        calculateTotal(report?.data);
        setPackageData(report?.data);
        setIsLoading2(false);
      } else {
        setIsLoading2(false);
        setPackageData([]);
        notification.error({
          message: "Data not available",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotal = (data: any[]) => {
    const totals = data?.reduce(
      (acc: any, val: any) => {
        acc.totalAmount += Number(val.totalAmount);
        acc.totalTickets += Number(val.totalTickets);
        return acc;
      },
      { totalAmount: 0, totalTickets: 0 }
    );
    setSummary(totals);
  };

  return (
    <div className="screenBox">
      <Card
        title={<div className="title1">Select Package</div>}
        extra={
          <div className="PageHeaderBox">
            <Button className="refreshBtn" onClick={() => getReport(1, 10)}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        }
      >
        <div className="">
          <br />
          <Form form={form} onFinish={() => getReport(1, 10)}>
            <Row>
              <Col md="4">
                <Form.Item
                  name={"package"}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Select a staff",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    allowClear
                    showSearch={true}
                    placeholder={"Select Package"}
                    onChange={(data: any, val: any) => setSelectedPkg(val)}
                    defaultValue={"Select Package"}
                    loading={isLoading}
                  >
                    {packages?.map((option: any) => (
                      <Option key={option.id} value={option.id}>
                        {option.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md="3">
                <Form.Item name={"date"} noStyle>
                  <DatePicker.RangePicker
                    size="large"
                    picker="month"
                    style={{ width: "100%" }}
                    onChange={(_, val: any) => {}}
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                  />
                </Form.Item>
              </Col>

              <Col md="1"></Col>

              <Col md="2">
                <Button
                  onClick={() =>
                    ExportToExcel(packageData, `${selectedPkg?.children}`)
                  }
                  size="large"
                  style={{ width: "100%" }}
                >
                  <SiMicrosoftexcel color="#08493e" size={20} /> &nbsp;Export
                </Button>
              </Col>

              <Col md="2">
                <Button
                  loading={isLoading2}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Get Report
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <br />

        {/* //PackageDataTable */}

        {packageData?.length ? (
          <>
            <hr />
            <br />
            <PackageDataTable
              activity={selectedPkg}
              summary={summary}
              data={packageData}
              loading={isLoading}
            />
          </>
        ) : (
          <NoData />
        )}
      </Card>
    </div>
  );
};

export default PackageReport;
