import { Modal, Table, Tag } from "antd";
import React from "react";
import moment from "moment";

const CardViewModal = (props: any) => {
  const columns = [
    {
      title: "Card Number",
      dataIndex: "cardNo",
      key: "cardNo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <div className="table-Action">
          <Tag color={status ? "green" : "red"}>
            {status ? "Active" : "Blocked"}
          </Tag>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Modal
        visible={props.visible}
        title={<div style={{ fontSize: 25 }}>Card Details</div>}
        onCancel={props.onCancel}
        footer={false}
        width={700}
      >
        <hr />
        <Table
          columns={columns}
          dataSource={props.data}
          rowKey="cardNo"
          bordered
        />
      </Modal>
    </div>
  );
};

export default CardViewModal;
