import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HelmetSeo from "../../components/helmetSeo";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import {
  addTicket,
  removeAllTicket,
  removeSelectedTicket,
} from "../../redux/slice/activitySlice";
import { KEYS } from "../../config/keys";
import "./styles.scss";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { FiInbox } from "react-icons/fi";
import TicketModal from "../../admin/Tickets/ticketModal";
import Loading from "../../components/loading";
import { FaMinus, FaPlus } from "react-icons/fa";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { IoMdCloseCircle } from "react-icons/io";

const Fade = require("react-reveal/Fade");

declare global {
  interface Window {
    Razorpay: any;
  }
}

const SelfTicket = () => {
  const dispatch = useDispatch();
  const Activity = useSelector((state: any) => state?.activity);
  const [Notifications, contextHolder] = notification.useNotification();
  const [activities, setActivities] = useState([]);
  const [selected, setSelected] = useState<any>({});
  const [select, setSelect] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [detailShows, setDetailsShows] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.GET_ACTIVITIES, null);
      if (response?.status) {
        const publishedActivities = response?.data?.filter(
          (activity: any) => activity?.publish === true
        );
        setActivities(publishedActivities);
      } else {
        setActivities([]);
        notification.error({
          message: response?.message,
        });
      }
    } catch (error) {
      setActivities([]);
      notification.error({
        message: "Unable to load events",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkItem = () => {
    try {
      const arr = Activity?.activities;
      const findActivity = arr?.findIndex(
        (activity: any) => activity?.id === selected?.id
      );
      if (findActivity >= 0) {
        return arr[findActivity]?.price;
      } else {
        return [];
      }
    } catch (err) {
      console.error("Error checking item:", err);
    }
  };

  const showCount = (item: any) => {
    try {
      const data = checkItem();
      if (data?.length) {
        const findItem = data?.filter((i: any) => i?.age === item?.age);
        if (findItem?.length) {
          return findItem[0]?.count;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (err) {
      console.error("Error showing count:", err);
      return 0;
    }
  };

  const clickItem = (type: any, data: any) => {
    try {
      setSelected(data);
      setSelect(true);
    } catch (err) {
      console.error("Error clicking item:", err);
    }
  };

  const selectItem = (item: any, price: any, type: any) => {
    try {
      const obj = {
        id: item?.id,
        name: item?.name,
        price: price,
        type: type,
      };
      dispatch(addTicket(obj));

      setSelectedItems((prevSelectedItems) => {
        const itemIndex = prevSelectedItems?.indexOf(item.id);
        if (type === "add" && itemIndex === -1) {
          return [...prevSelectedItems, item?.id];
        } else if (type === "remove" && itemIndex !== -1) {
          return prevSelectedItems?.filter((id) => id !== item?.id);
        }
        return prevSelectedItems;
      });
    } catch (err) {
      console.error("Error selecting item:", err);
    }
  };

  const calculate = () => {
    try {
      let total = 0;
      Activity?.activities?.forEach((activity: any) => {
        activity.price.forEach((price: any) => {
          total += parseInt(price?.price) * price?.count;
        });
      });
      return total.toFixed(2);
    } catch (error) {
      console.error("Error calculating total:", error);
    }
  };

  const calculateTotalCount = () => {
    try {
      let totalCount = 0;
      Activity?.activities?.forEach((activity: any) => {
        activity?.price?.forEach((price: any) => {
          totalCount += price?.count;
        });
      });
      return totalCount;
    } catch (error) {
      console.error("Error calculating total count:", error);
    }
  };

  const makePayment = (e: any) => {
    e.preventDefault();
    const options = {
      key: KEYS.RAZORPAY_KEY,
      key_secret: KEYS.RAZORPAY_PRIVATE,
      amount: Number(calculate()) * 100,
      currency: "INR",
      name: "Teenzania",
      description: "Ticket",
      handler: function (response: any) {
        SelfTicketBooking();
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#08493e",
      },
    };
    const pay: any = new window.Razorpay(options);
    pay.open();
  };

  const clear = () => {
    dispatch(removeAllTicket());
    setSelectedItems([]);
  };

  const removeOne = (activityId: any) => {
    dispatch(removeSelectedTicket(activityId));
    setSelectedItems((prevItems) =>
      prevItems?.filter((id) => id !== activityId)
    );
  };

  const SelfTicketBooking = async () => {
    setIsLoading2(true);
    try {
      const obj = {
        from: "web",
        ticketType: "booking",
        activities: Activity?.activities,
        print: 0,
        paymentStatus: true,
        paymentMethod: "razorpay",
        totalAmount: Number(calculate()),
        grandTotal: Number(calculate()),
        paidAmount: Number(calculate()),
        status: false,
        count: calculateTotalCount(),
      };
      const response: any = await POST(API.TICKET_ADD, obj);
      if (response?.status) {
        notification.success({
          message: "Successfully created Ticket",
        });
        clear();
        setDetailsShows(false);
        setTicketNumber(response?.data?.ticketNumber);
        setIsLoading2(false);
      }
    } catch (err) {
      console.error("Error booking ticket:", err);
      setIsLoading2(false);
    } finally {
      setTicketModal(true);
    }
  };
  let grandTotal: number = 0;

  const summaryTotal = (val: any) => {
    const sum = val.reduce((totalSum: any, item: any) => {
      const total = Number(item?.price) * Number(item?.count);
      return totalSum + total;
    }, 0);
    grandTotal += sum;
    return sum;
  };

  return (
    <div>
      <HelmetSeo
        title="Get Tickets Now"
        description={`Get Your Tickets Now. Click Here`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/hurryUp.png"
        canonicalurl="https://www.teenzania.com/selfticket"
        imageAlt="Self Ticket"
      />
      <div>
        <Header />
        {isLoading ? (
          <Loading />
        ) : (
          <div className="LayoutBox">
            <br />
            <Container>
              <Row>
                <Col lg="8" md="7" sm="7">
                  <Row>
                    {activities?.map((val: any) => {
                      const isSelected = Activity?.activities?.some(
                        (act: any) =>
                          act?.id === val?.id &&
                          act?.price?.some((price: any) => price?.count > 0)
                      );
                      return (
                        <Col lg="6" md="12" sm="12" key={val.id}>
                          <div className="activityItem">
                            <div>
                              <img
                                className="activityItem-img"
                                src={val?.image}
                              />
                            </div>
                            <div className="activityItem-box1">
                              <div className="activityItem-txt1">
                                {val?.name}
                              </div>
                              {val?.pricing?.map((data: any) => (
                                <div
                                  className="activityItem-txt2"
                                  key={data.age}
                                >
                                  {data?.age}: {data?.price}
                                </div>
                              ))}
                            </div>
                            <div className="activityItem-box2">
                              <Button onClick={() => clickItem("add", val)}>
                                Ticket
                              </Button>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                {isSelected && (
                                  <IoCheckmarkDoneCircleSharp
                                    size={25}
                                    color="green"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg="4" md="5" sm="5" className="summaryCol">
                  <div className="stickyBox">
                    <div className="ticket-summaryBox">
                      <div className="">
                        {Activity?.activities?.map((item: any, i: any) => {
                          return (
                            <div className="selectedItem" key={item.id}>
                              <div className="selectedItemTxt1">
                                {i + 1}. {item?.name}
                                <IoMdCloseCircle
                                  color="red"
                                  onClick={() => removeOne(item.id)}
                                />
                              </div>
                              {item?.price?.map((val: any) => {
                                return (
                                  <div
                                    className="selectedItem2 mt-2"
                                    key={val.age}
                                  >
                                    <div className="selectedItemTxt2">
                                      {val?.age} * {val?.count} ={" "}
                                      {Number(
                                        Number(val?.price) * Number(val?.count)
                                      ).toFixed(2)}
                                    </div>
                                    <div className="countBtnHead">
                                      <CiCircleMinus
                                        size={25}
                                        color="grey"
                                        onClick={() =>
                                          selectItem(item, val, "remove")
                                        }
                                      />
                                      <div className="Summarycount">
                                        {val.count}
                                      </div>
                                      <CiCirclePlus
                                        size={25}
                                        color="grey"
                                        onClick={() =>
                                          selectItem(item, val, "add")
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              <div
                                className="my-2 fw-bold"
                                style={{ fontSize: 15 }}
                              >
                                Total: {summaryTotal(item?.price)} /-
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="">
                      <div className="summaryBox2">
                        <div>Total: {calculate()}</div>
                        <Row>
                          <Col>
                            <div className="clearAct mt-2" onClick={clear}>
                              Clear
                            </div>
                          </Col>
                          <Col>
                            <Button
                              size="large"
                              type="primary"
                              onClick={makePayment}
                            >
                              Pay Now
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                {Activity?.activities?.length > 0 && (
                  <Col className="summaryMobCol">
                    <div className="summaryMobHead">
                      <div>
                        <div>Total: {calculate()}</div>
                        <div
                          className="ticketDetTxtMob"
                          onClick={() => setDetailsShows(!detailShows)}
                        >
                          <span>Ticket Details</span>
                          <span>
                            {detailShows ? (
                              <MdKeyboardArrowDown size={15} />
                            ) : (
                              <MdKeyboardArrowUp size={15} />
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        <div className="clearAct mt-2" onClick={clear}>
                          Clear
                        </div>
                        <Button
                          size="large"
                          type="primary"
                          onClick={makePayment}
                          className="mt-2"
                          loading={isLoading2}
                        >
                          Pay Now
                        </Button>
                      </div>
                    </div>
                    {detailShows ? (
                      <div className="ticket-summaryBox">
                        {Activity?.activities?.length ? (
                          <div className="">
                            {Activity?.activities?.map((item: any, i: any) => {
                              return (
                                <div className="selectedItem" key={item.id}>
                                  <div className="selectedItemTxt1">
                                    {i + 1}. {item?.name}
                                    <IoMdCloseCircle
                                      color="red"
                                      onClick={() => removeOne(item?.id)}
                                    />
                                  </div>
                                  {item?.price?.map((val: any) => {
                                    return (
                                      <div
                                        className="selectedItem2 mt-2"
                                        key={val.age}
                                      >
                                        <div className="selectedItemTxt2">
                                          {val?.age} * {val?.count} ={" "}
                                          {Number(
                                            Number(val?.price) *
                                              Number(val?.count)
                                          ).toFixed(2)}
                                        </div>
                                        <div className="countBtnHead">
                                          <CiCircleMinus
                                            size={25}
                                            color="grey"
                                            onClick={() =>
                                              selectItem(item, val, "remove")
                                            }
                                          />
                                          <div className="Summarycount">
                                            {val.count}
                                          </div>
                                          <CiCirclePlus
                                            size={25}
                                            color="grey"
                                            onClick={() =>
                                              selectItem(item, val, "add")
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div
                                    className="my-2 fw-bold"
                                    style={{ fontSize: 15 }}
                                  >
                                    Total: {summaryTotal(item?.price)} /-
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <FiInbox size={25} />
                            </div>
                            <div>No Activities Selected</div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        )}
        <Footer />
      </div>
      {select ? (
        <Modal
          open={select}
          onCancel={() => setSelect(false)}
          footer={false}
          width={350}
          centered
          title={selected?.name}
        >
          {contextHolder}
          <div className="mt-3">
            {selected?.pricing?.map((item: any) => {
              return (
                <div className="item">
                  <div className="itemtxt">
                    {item?.age} - {item?.price}
                  </div>
                  <div className="item2">
                    <div className="btn1">
                      <FaMinus
                        size={20}
                        color="red"
                        onClick={() => selectItem(selected, item, "remove")}
                      />
                    </div>
                    <div className="btn2">{showCount(item)}</div>
                    <div className="btn3">
                      <FaPlus
                        size={20}
                        color="#ffff"
                        onClick={() => selectItem(selected, item, "add")}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      ) : null}
      {ticketModal ? (
        <TicketModal
          view={ticketModal}
          ticketNo={ticketNumber}
          onClose={() => setTicketModal(false)}
        />
      ) : null}
    </div>
  );
};

export default SelfTicket;
