import React, { useEffect, useState } from "react";
import { API } from "../../../config/Api";
import { GET } from "../../../utils/apiRequest";
import { Table } from "react-bootstrap";
import { Card, Tag } from "antd";
import Loading from "../../../components/loading";
import PageHeaders from "../../../components/pageHeaders";
import { useLocation } from "react-router-dom";
import moment from "moment";

function PaymentDetails() {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.PAYMENT_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        const arr: any = [];
        arr.push(response.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <PageHeaders title={"Payment Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Qr Code</th>
                  <th className="table-th">Provider</th>
                  <th className="table-th">Status</th>
                  <th className="table-th">Created By</th>
                  <th className="table-th">Created At</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.url}</td>
                    <td>{item?.provider}</td>
                    <td>
                      {item?.status ? (
                        <Tag color="green">Yes</Tag>
                      ) : (
                        <Tag color="red">No</Tag>
                      )}
                    </td>
                    <td>{item?.createdBy}</td>
                    <td>
                      {item && item.createdAt
                        ? moment(item.createdAt).format("DD-MM-YYYY")
                        : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </div>
    </div>
  );
}

export default PaymentDetails;
