import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import PaymentTable from "./dataTable";
import { API } from "../../../config/Api";
import { DELETE, POST } from "../../../utils/apiRequest";
import PaymentForm from "./form";
import HorizontalLineLoader from "../../components/loader";

function PaymentSettings() {
  const navigate = useNavigate();
  const [form] = useForm();
  const { Option } = Select;
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  const [sdate, setSdate] = useState<any>(
    dayjs(new Date()).subtract(10, "day").format("YYYY-MM-DD")
  );
  const [ldate, setLdate] = useState<any>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const dateFormat = "YYYY/MM/DD";

  const [activities, setActivities] = useState([]);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    getPayment();
  }, [page, take]);

  const getPayment = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.PAYMENT_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const deletePayment = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.PAYMENT_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Payment Deleted",
        });
        getPayment();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Payment not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Payment not deleted",
      });
    }
  };
  const editPayment = (payment: any) => {
    try {
      setSelectedItem(payment);
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };
  const onView = (val: any) => {
    navigate(`/Auth/paymentDetails/${val}`, { state: { val } });
  };
  return (
    <>
      <Card>
        <div className="">
          <Form form={form} onValuesChange={getPayment}>
            <div className="jobScreen-PageHeaderBox">
              <Form.Item name={"query"} noStyle>
                <Input
                  style={{ width: 200 }}
                  placeholder="Search"
                  allowClear
                  suffix={<CiSearch size={16} color="#000" />}
                />
              </Form.Item>
              <Form.Item name={"status"} noStyle>
                <Select
                  allowClear
                  style={{ minWidth: 100 }}
                  placeholder="Status"
                >
                  <Select.Option value={null}>All</Select.Option>
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>Inactive</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={"date"} noStyle>
                <DatePicker
                  placeholder="Create Date"
                  style={{ minWidth: 100 }}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button onClick={() => editPayment({})} type="primary">
                  Create +
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <br />
        {isLoading2 ? <HorizontalLineLoader /> : null}
        <div className="screenBox">
          <PaymentTable
            data={data}
            onEdit={(item: any) => editPayment(item)}
            onView={(item: any) => onView(item)}
            delete={(id: any) => deletePayment(id)}
            meta={meta}
            onPageChange={(page: number, pageSize: number) => {
              setIsLoading2(true);
              setPage(page);
              setTake(pageSize);
            }}
          />
        </div>
      </Card>
      {modalVisible ? (
        <PaymentForm
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={() => {
            setIsLoading2(true);
            editPayment({});
            getPayment();
          }}
        />
      ) : null}
    </>
  );
}

export default PaymentSettings;
