import { ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Switch,
  Upload,
  notification,
} from "antd";
// import { version } from "os";

import React, { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { FILE_UPLOAD, GET, POST } from "../../utils/apiRequest";
import { UploadChangeParam } from "antd/es/upload";
import Loading from "../../components/loading";
import MobileAppDataTable from "./dataTable";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
import PageHeaders from "../../components/pageHeaders";

const MobileApp = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appVersion, setAppVersion] = useState("");
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading2, setIsLoading2] = useState(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(30);

  useEffect(() => {
    loadData();
  }, [page, take]);
  console.log("data....", data);

  const loadData = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.GET_DOWNLOAD;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const handleFormSubmit = async () => {
    try {
      const value = await form.validateFields();
      setIsLoading(true);

      let downloads = {
        version: value?.version,
        comment: value?.comment,
        note: value?.note,
        link: file,
        status: value?.status,
        publish: value?.publish,
      };

      let data3 = {
        version: "string",
        comment: "string",
        note: "string",
        link: "string",
        publish: true,
        status: true,
      };

      let url = API.MOBILE_APP;
      const data: any = await POST(url, downloads);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Created Successfully`,
        });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("err", error);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      // const name = moment(new Date()).unix();
      const name = `teenzania-${appVersion}`;
      const myNewFile = new File([file], name + ".apk", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Container fluid style={{ padding: "20px" }}>
        <PageHeaders title={"Developer"} backButton={true} loading={isLoading2}>
          <Form form={form} onValuesChange={loadData}>
            <div className="PageHeaderBox">
              <Form.Item noStyle>
                <Button
                  onClick={loadData}
                  icon={<ReloadOutlined />}
                  style={{ marginRight: 10 }}
                ></Button>
                <Button onClick={() => setModalVisible(true)} type="primary">
                  Create +
                </Button>
              </Form.Item>
            </div>
          </Form>
        </PageHeaders>
        {/* <header className="mb-4">
      </header>
      <div className="custom-button-position">
        <Button size="large" type="primary" onClick={() => setModalVisible(true)}>
          Create
        </Button>
      </div> */}
        <div className="screenBox">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <MobileAppDataTable
                data={data}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
                onSuccess={loadData}
              />
            </div>
          )}
        </div>
      </Container>
      <Modal
        title="Create"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={isLoading}
          >
            Submit
          </Button>,
        ]}
        width={600}
      >
        <Form form={form} layout="vertical">
          <Row gutter={8}>
            <Col sm={6}>
              <label className="formLabel">Version</label>
              <Form.Item
                name="version"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Please enter version" }]}
              >
                <Input
                  size="large"
                  placeholder=""
                  onChange={(e) => setAppVersion(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label className="formLabel">File</label>
              <Form.Item
                name="link"
                rules={[{ required: true, message: "required" }]}
              >
                <Upload onChange={(data) => UploadFile(data)}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <label className="formLabel">Comment</label>
              <Form.Item
                name="comment"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Please enter comment" }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <label className="formLabel">Publish</label>
              <Form.Item
                name="publish"
                valuePropName="checked"
                initialValue={false}
                style={{ marginBottom: 10 }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={6}>
              <label className="formLabel">Status</label>
              <Form.Item
                name="status"
                valuePropName="checked"
                initialValue={false}
                style={{ marginBottom: 10 }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label className="formLabel">Note</label>
              <Form.Item
                name="note"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Please enter note" }]}
              >
                <Input.TextArea rows={4} placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default MobileApp;
