import React, { useEffect, useState } from "react";
import "./styles.scss";
import { notification } from "antd";
import PageHeaders from "../../components/pageHeaders";
import { GET } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

import Sales from "./sales";
import ActivityReport from "./activity";
import JobReport from "./jobs";
import StaffReport from "./staff";
import BookingReport from "./booking";
import TicketReport from "./tickets";
import PackageReport from "./package";

const ReportScreen = () => {
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading2, setIsLoading2] = useState(true);
  const [activities, setActivities] = useState([]);
  const [staff, setStaff] = useState([]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    getAllJobs();
    getAllStaff();
    getAllActivities();
  }, []);

  const getAllStaff = async () => {
    try {
      const staffDet: any = await GET(API.STAFFS_PICKER);
      if (staffDet.status) {
        setStaff(staffDet.data);
      } else {
        Notifications["error"]({
          message: "No Staffs",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };

  const getAllActivities = async () => {
    try {
      const activityDet: any = await GET(API.ACTIVITY_PICKER);
      if (activityDet.status) {
        setActivities(activityDet.data);
      } else {
        Notifications["error"]({
          message: "No Activities",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };

  const getAllJobs = async () => {
    try {
      const activityDet: any = await GET(API.JOBS_PICKER);
      if (activityDet.status) {
        setJobs(activityDet.data);
      } else {
        Notifications["error"]({
          message: "No Activities",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Sales Report",
      children: <Sales />,
    },
    {
      key: "2",
      label: "Staff",
      children: (
        <StaffReport staff={staff} activities={activities} jobs={jobs} />
      ),
    },
    {
      key: "4",
      label: "Activities",
      children: (
        <ActivityReport staff={staff} activities={activities} jobs={jobs} />
      ),
    },
    {
      key: "3",
      label: "Packages",
      children: (
        <PackageReport staff={staff} activities={activities} jobs={jobs} />
      ),
    },
    // {
    //   key: "5",
    //   label: "Tickets",
    //   children: (
    //     <TicketReport staff={staff} activities={activities} jobs={jobs} />
    //   ),
    // },
  ];

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Reports"} backButton={true} load />
      <Tabs
        size="small"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        tabBarStyle={{ backgroundColor: "#fff", paddingLeft: 10 }}
      />
    </div>
  );
};

export default ReportScreen;
