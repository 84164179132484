import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import ActivityDataTable from "./dataTable";
import StaffDataTable from "./dataTable";
import { API } from "../../../config/Api";
import { GET, POST } from "../../../utils/apiRequest";
import Loading from "../../../components/loading";
import "../styles.scss";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { IoReload } from "react-icons/io5";

const StaffReport = (props: any) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { Option } = Select;
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  const [sdate, setSdate] = useState<any>(
    dayjs(new Date()).subtract(10, "day").format("YYYY-MM-DD")
  );
  const [ldate, setLdate] = useState<any>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const dateFormat = "YYYY/MM/DD";

  const [activities, setActivities] = useState([]);
  const [staff, setStaff] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [summary, setSummary] = useState<any>({});

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [data, setData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [methodSum, setMethodSum] = useState(0);
  const [startDate, setStartDate] = useState(firstDayOfMonth) as any;
  const [endDate, setEndDate] = useState(lastDayOfMonth) as any;

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async () => {
    try {
      let api = API.STAFFS_PICKER;
      const staffs: any = await GET(api, null);
      if (staffs?.status) {
        setStaff(staffs?.data);
        setIsLoading(false);
      } else {
        setStaff([]);
        setIsLoading(false);
        notification.error({
          message: "Staff data not available",
        });
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Network error",
      });
    }
  };

  const getStaffJobs = async (val: any) => {
    setJobs([]);
    try {
      setIsLoading2(true);
      const formData = form.getFieldsValue();
      const start = formData.date
        ? moment(formData.date[0].$d).startOf("month")
        : moment().startOf("month");
      const end = formData.date
        ? moment(formData.date[1].$d).endOf("month")
        : moment().endOf("month");
      setStartDate(start);
      setEndDate(end);

      const obj = {
        staffId: formData.staff,
        startDate: start,
        endDate: end,
      };

      const api = "report/staff-jobs";
      const data: any = await POST(api, obj);

      if (data?.status) {
        setJobs(data.data);
        calculateTotal(data.data);
      } else {
        notification.error({
          message: "No Jobs found",
        });
      }
    } catch (error) {
      notification.error({
        message: "Network error",
      });
    } finally {
      setIsLoading2(false);
    }
  };

  const calculateTotal = (jobs: any[]) => {
    const totals = jobs.reduce(
      (acc, staff) => {
        acc.totalAmount += staff.totalAmount;
        acc.settleAmount += staff.settledAmount;
        acc.dueAmount += staff.dueAmount;
        return acc;
      },
      { totalAmount: 0, settleAmount: 0, dueAmount: 0 }
    );

    setSummary(totals);
  };

  return (
    <div className="screenBox">
      {contextHolder}
      <Card
        title={<div className="title1">Select Staff</div>}
        extra={
          <div className="PageHeaderBox">
            <Button className="refreshBtn" onClick={() => getStaffJobs("")}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        }
      >
        <div className="">
          <div className="title1"></div>
          <br />
          <Form form={form} onFinish={getStaffJobs}>
            <Row>
              <Col md="3">
                <Form.Item
                  name={"staff"}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Select a staff",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    allowClear
                    showSearch={true}
                    placeholder={"Select Staff"}
                    defaultValue={"Select Staff"}
                    loading={isLoading}
                  >
                    {staff?.map((option: any) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md="3">
                <Form.Item name={"date"} noStyle>
                  <DatePicker.RangePicker
                    size="large"
                    picker="month"
                    style={{ width: "100%" }}
                    onChange={(_, val: any) => {}}
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                  />
                </Form.Item>
              </Col>

              <Col md="4"></Col>
              <Col md="2">
                <Button
                  loading={isLoading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Get Report
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <br />

        {jobs?.length ? (
          <>
            <hr />
            <br />

            <StaffDataTable
              summary={summary}
              data={jobs}
              loading={isLoading2}
            />
          </>
        ) : null}
      </Card>
    </div>
  );
};

export default StaffReport;
