import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

function CardDataTable(props: any) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{id}</div>;
      },
    },
    {
      title: "Card No.",
      dataIndex: "cardNo",
      key: "cardNo",
      render: (cardNo: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{cardNo}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (item: any) => (
        <div className="table-Action">
          {item ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      render: (item: any) => (
        <div className="table-Action">
          {item ? (
            <Tag color="green">Assigned</Tag>
          ) : (
            <Tag color="red">Refunded</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Expiring At",
      dataIndex: "expiryDate",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (record: any) => (
        <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiEdit
                size={20}
                onClick={() => props?.onEdit(item)}
                style={{ cursor: "pointer", color: "orange" }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
              <Popconfirm
                title="Delete the Staff's Details"
                description="Are you sure you want to delete the Staff Details?"
                onConfirm={() => props?.delete(item?.id)}
                okText="Yes"
              >
                <AiOutlineDelete
                  size={20}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Staffs`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default CardDataTable;
