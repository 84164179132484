import React, { useEffect } from "react";
import { Col, Container, Table } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { IoIosArrowForward } from "react-icons/io";
import { FcOk } from "react-icons/fc";
import HelmetSeo from "../../components/helmetSeo";
import { IoInformationCircleOutline } from "react-icons/io5";

const ParkTiming = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <HelmetSeo
        title="Park Timing"
        description="We are open on all Holidays..!"
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/Talaia.jpg"
        canonicalurl="https://www.teenzania.com/park_timing"
        imageAlt="Timing"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Col sm={12} xs={12}>
            <div className="heading_bredcume">
              Home <IoIosArrowForward /> About <IoIosArrowForward /> Park Timing
            </div>
            <h3 className="heading_h3">Park Timing</h3>
            <br />
            <Col md={12} sm={12}>
            <strong style={{ color: "green",fontSize:20 }}>
              <IoInformationCircleOutline size={30} />
              &nbsp; We are open on all Public Holidays..!
            </strong>
            <br/> <br/>
              <Table bordered>
                <thead>
                  <tr>
                    <th>{""}</th>
                    <th>Day</th>
                    <th>Working Hours</th>
                    <th>Open</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{day}</td>
                      <td>10:00 AM to 12:00 AM</td>
                      <td className="ps-3 ">
                        <FcOk size={25} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Col>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ParkTiming;
