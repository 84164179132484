import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";
import { Button, Form, Input, Modal, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";

import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import tick from "../../assets/images/tickmarkGif.gif";
import bro from "../../assets/images/bro.png";
import { useNavigate } from "react-router-dom";
function FeedbackScreen() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeb = () => {
    navigate("/home");
  };
  const nextpage = () => {
    navigate("/share");
  };

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let feedback = {
        name: val?.name,
        phone: val?.phone,
        email: val?.email,
        comment: val?.comment,
      };
      let url = API.FEED_BACK;
      const data: any = await POST(url, feedback);

      if (data?.status) {
        setIsModalOpen(true);
        setIsLoading(false);
        form.resetFields();
      } else {
        message.error("Failed to Submit the feedback ");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        width={400}
        centered
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
      >
        <div
          className=""
          style={{
            objectFit: "contain",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={tick} width="50%" alt="done" />
          <h5 className="">Thank you for your feedback</h5>
        </div>
      </Modal>

      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <div className="heading_bredcume">
              <span onClick={nextpage}>Back </span>{" "}
              <IoIosArrowForward onClick={nextpage} />
              <span onClick={changeb}>Home</span>
              <IoIosArrowForward onClick={changeb} />
              <span>Contact Us</span>
              <IoIosArrowForward />
            </div>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <h3 className="heading_h3">Feedback</h3>

              <Row>
                <Col sm={5} xs={12}>
                  <Form form={form} onFinish={onFinish}>
                    <div className="ContactUs_text3">Name</div>
                    <FormItem
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "please enter your name ",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Name" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Phone</div>
                    <FormItem
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Enter  your  phone number",
                        },
                      ]}
                    >
                      <Input type="number" placeholder="Phone" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Email</div>
                    <FormItem name="email">
                      <Input type="email" placeholder="Email" size="large" />
                    </FormItem>

                    <div className="ContactUs_text3">Feedback</div>
                    <FormItem
                      name="comment"
                      rules={[
                        {
                          required: true,
                          message: "Enter  your  feedback",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder="Write your Feedback here..."
                        size="large"
                      />
                    </FormItem>
                    <Col sm={{ offset: 12, span: 12 }} xs={12}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className="contactSubmit_Button"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Form>
                </Col>
                <Col sm={7} xs={12}>
                  <img src={bro} width={"100%"} style={{ padding: 10 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
}
export default FeedbackScreen;
