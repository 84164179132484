import moment from "moment";
import * as XLSX from "xlsx";
const { read, utils } = XLSX;

const ExportToExcel = async (data: any, fileName: any) => {
  try {
    let status = false;
    let message = "";
    if (!Array.isArray(data) || data.length === 0) {
      message = "Data is empty or not in the correct format.";
      status = false;
      let res = {
        status,
        message,
      };
      return res;
    } else {
      const aoa = data.map((item) => Object.values(item));
      const headers = Object.keys(data[0]);
      aoa.unshift(headers);

      const ws = XLSX.utils.aoa_to_sheet(aoa);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");

      let str = fileName + "_" + moment(new Date()).format("DD_MM_YYYY");
      let mnt = moment().unix().toString();

      let file_name = `${str}_${mnt}.xlsx`;

      let pr = XLSX.writeFile(wb, file_name);
    }
  } catch (err) {
    console.log(err);
  }
};
export { ExportToExcel };
