import { useEffect, useState } from "react";
import { Button, Card, DatePicker, Form } from "antd";
import SalesDataTable from "./dataTable";

import { API } from "../../../config/Api";
import { POST } from "../../../utils/apiRequest";
import dayjs from "dayjs";
import moment from "moment";
import { IoReload } from "react-icons/io5";

const Sales = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [methodSum, setMethodSum] = useState(0);
  const [startDate, setStartDate] = useState(firstDayOfMonth) as any;
  const [endDate, setEndDate] = useState(lastDayOfMonth) as any;

  useEffect(() => {
    getReport();
  }, []);

  const getReport = async () => {
    try {
      let obj = {
        startDate: startDate,
        endDate: endDate,
      };
      var val = form.getFieldsValue();
      if (val.date) {
        let start = moment(val?.date[0]?.$d).startOf("month");
        let end = moment(val?.date[1]?.$d).endOf("month");
        setStartDate(start);
        setEndDate(end);
        obj = {
          startDate: start,
          endDate: end,
        };
      }
      const report: any = await POST(API.SALES_REPORT, obj);
      if (report?.status) {
        await groupData(report?.data);
      }
    } catch (error) {
      console.log("error = = = = >", error);
      setIsLoading(false);
    }
  };

  const groupData = async (data: any) => {
    try {
      if (data?.length) {
        var grandTotal = 0;
        var paymentMethodSums: any = {};
        const groupedArray: any = Object.values(
          data.reduce((acc: any, transaction: any) => {
            const { date, paymentMethod, totalAmount } = transaction;
            grandTotal += parseInt(totalAmount);
            if (!acc[date]) {
              acc[date] = {
                date,
                transactions: [],
                totalAmount: 0,
              };
            }
            if (!paymentMethodSums[paymentMethod]) {
              paymentMethodSums[paymentMethod] = 0;
            }
            paymentMethodSums[paymentMethod] += parseInt(totalAmount);
            acc[date].transactions.push(transaction);
            acc[date].totalAmount += parseInt(totalAmount);
            return acc;
          }, {})
        );
        setMethodSum(paymentMethodSums);
        setData(groupedArray);
        setGrandTotal(grandTotal);
        setIsLoading(false);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="screenBox" style={{ paddingTop: 0 }}>
      <Card
        loading={isLoading}
        title={
          <div>
            Sales Report -{" "}
            <span style={{ fontSize: 14, color: "red" }}>
              {moment(startDate).format("DD-MM-YYYY")}
            </span>{" "}
            to{" "}
            <span style={{ fontSize: 14, color: "red" }}>
              {moment(endDate).format("DD-MM-YYYY")}
            </span>
          </div>
        }
        extra={
          <Form form={form} onValuesChange={() => getReport()}>
            <div className="PageHeaderBox">
              <Form.Item name={"date"} noStyle>
                <DatePicker.RangePicker
                  picker="month"
                  style={{ width: "250px" }}
                  onChange={(_, val: any) => {}}
                  defaultValue={[dayjs(startDate), dayjs(endDate)]}
                />
              </Form.Item>

              <Button className="refreshBtn" onClick={() => getReport()}>
                <IoReload color="#000" size={15} />
              </Button>
            </div>
          </Form>
        }
      >
        <SalesDataTable data={data} methodSum={methodSum} total={grandTotal} />
      </Card>
    </div>
  );
};

export default Sales;
