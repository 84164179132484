import { Card, Tag } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { Table } from "react-bootstrap";
import NoData from "../components/noData";

const DiscountDetails = () => {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.DISCOUNT_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        const arr: any = [];
        arr.push(response.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <PageHeaders title={"Discount Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Coupen Code</th>
                  <th className="table-th">Amount</th>
                  <th className="table-th">Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.coupenCode}</td>
                      <td>{item?.amount}</td>
                      <td>
                        {item?.status === true ? (
                          <Tag color="green">Yes</Tag>
                        ) : (
                          <Tag color="red">No</Tag>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Card>
        <br />
      </div>
    </div>
  );
};

export default DiscountDetails;
