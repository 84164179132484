const Cards = (props: any) => {
  return (
    <div className="Dashboard-cards">
      <div className="Dashboard-cardBox1">{props?.icon}</div>
      <div className="Dashboard-cardBox2">
        <div className="Dashboard-cardtxt1">{props?.label}</div>
        <div className="Dashboard-cardtxt2">{Number(props?.value)}</div>
      </div>
    </div>
  );
};

export default Cards;
