import React, { useEffect, useState } from "react";
import { Button, Form, Input, Switch, notification, Modal, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST, COMPRESS_IMAGE } from "../../utils/apiRequest";
import { useForm } from "antd/es/form/Form";
import ImagePicker from "../components/ImagePicker";
import { IoRemoveCircleOutline } from "react-icons/io5";

function OffersFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [image_url, setImageUrl] = useState<any>(props?.data?.image);
  const [file, setFile] = useState<any>();
  const [activities, setActivities] = useState<any>([]);
  const [isLoading2, setIsLoading2] = useState(true);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(100);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getActvites();
  }, []);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let images: any = await COMPRESS_IMAGE(file);
      var id = props?.data?.id;
      let offers = {
        id: Number(props?.data?.id),
        image: file?.name ? images?.url : props?.data?.image,
        title: val?.title,
        description: val?.description,
        activities: val?.activities,
        price: Number(val?.price),
        status: true,
      };
      let url = id ? API.OFFERS_EDIT : API.OFFERS_ADD;

      const data: any = await POST(url, offers);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Offers Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };
  const getActvites = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        page: page,
        take: take,
      };
      let api = API.ACTIVITY_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setActivities(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setActivities([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const calculate = (data: any) => {
    // // 0 + 1 + 2 + 3 + 4
    // const initialValue = 0;
    console.log("+++++++ ", data);
    // const sumWithInitial = data.reduce(
    //   (acc: any, cur: any) => Number(acc) + Number(cur?.price),
    //   initialValue
    // );

    // console.log(sumWithInitial);
    // // Expected output: 10

    // return sumWithInitial;
  };

  return (
    <Modal
      visible={props.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Offers`}</div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={900}
    >
      {contextHolder}
      <hr />
      <Form
        onChange={calculate}
        onFinish={onFinish}
        form={form}
        initialValues={{
          image: props?.data?.image,
          title: props?.data?.title,
          description: props?.data?.description,
          activities: props?.data?.activities,
          price: props?.data?.price,
        }}
      >
        <Row>
          <Col md={5}>
            <label className="formLabel">Title</label>
            <Form.Item
              name={"title"}
              rules={[{ required: true, message: "please input the field" }]}
            >
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Decription</label>
            <Form.Item name={"description"}>
              <Input.TextArea size="large" rows={2} />
            </Form.Item>

            <label className="formLabel">Price</label>
            <Form.Item
              name={"price"}
              rules={[{ required: true, message: "Enter price" }]}
            >
              <Input size="large" type="number" />
            </Form.Item>

            <label className="formLabel">Photo</label>
            <Form.Item name="image">
              <ImagePicker
                onChange={(value: any) => {
                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={image_url}
              />
            </Form.Item>
          </Col>

          <Col md={7}>
            <Form.List name="activities">
              {(fields, { add, remove }) => (
                <>
                  {fields?.length ? (
                    <Row>
                      <Col sm={5}>
                        <div className="form-label">Activity</div>
                      </Col>
                      <Col sm={5}>
                        <div className="form-label">Count</div>
                      </Col>
                    </Row>
                  ) : null}
                  {fields.map((field) => (
                    <>
                      <Row>
                        <Col sm={5}>
                          <Form.Item
                            rules={[{ required: true, message: "Enter Age" }]}
                            name={[field.name, "activity"]}
                            style={{ marginTop: -8 }}
                          >
                            <Select size="large">
                              {activities.map((option: any) => (
                                <Select.Option
                                  key={option?.id}
                                  value={option?.id}
                                >
                                  {option?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col sm={5}>
                          <Form.Item
                            rules={[{ required: true, message: "Enter Count" }]}
                            name={[field.name, "count"]}
                            style={{ marginTop: -8 }}
                          >
                            <Input
                              size="large"
                              placeholder="Count"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={2}>
                          <Form.Item style={{ marginTop: -8 }}>
                            <Button
                              size="large"
                              onClick={() => remove(field.name)}
                              block
                            >
                              <IoRemoveCircleOutline size={20} color="red" />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Row>
                    <Col sm={6}></Col>
                    <Col sm={6}>
                      <Button size="large" block onClick={() => add()}>
                        Add Activity +
                      </Button>
                    </Col>
                  </Row>
                  <hr />

                  {/* <Row>
                    <Col sm={6}></Col>
                    <Col sm={6}>
                      <h5 className="">
                        Total Amount : <b>{calculate(fields)}</b>
                      </h5>
                    </Col>
                  </Row> */}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={6} />
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Button size="large" danger block onClick={props.onCancel}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {props?.data?.id ? "Edit" : "Create"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default OffersFormModal;
