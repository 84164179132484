import React, { useState } from "react";
import Loading from "../../../components/loading";
import { Col, Row, Table } from "react-bootstrap";
import { Card, Tag } from "antd";
import moment from "moment";
import "../styles.scss";
import { useNavigate } from "react-router-dom";
import JobDetailsetails from "./details";

const StaffDataTable = (props: any) => {
  const navigate = useNavigate();

  const [details, setDetails] = useState(false);
  const [selected, setSelected] = useState<any>({});

  const openItem = (data: any) => {
    setSelected(data);
    setDetails(true);
  };

  return (
    <div>
      {props?.loading ? (
        <Loading />
      ) : details ? (
        <JobDetailsetails back={() => setDetails(false)} id={selected?.id} />
      ) : (
        <div className="">
          <Row>
            <Col md="4">
              <Card>
                <div className="report-text1">TOTAL AMOUNT</div>
                <div className="report-text2">
                  {props?.summary?.totalAmount}
                </div>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <div className="report-text1">SETTLE AMOUNT</div>
                <div className="report-text2">
                  {props?.summary?.settleAmount}
                </div>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <div className="report-text1">DUE AMOUNT</div>
                <div className="report-text2">{props?.summary?.dueAmount}</div>
              </Card>
            </Col>
          </Row>
          <br />
          <div className="">
            <Table bordered>
              <thead>
                <tr>
                  <td className="report-headtd">Day</td>
                  <td className="report-headtd">Clock In</td>
                  <td className="report-headtd">Clock Out</td>
                  <td className="report-headtd">Status</td>
                  <td className="report-headtd">Total Amount</td>
                  <td className="report-headtd">Settled Amount</td>
                  <td className="report-headtd"></td>
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((val: any) => {
                  return (
                    <tr>
                      <td>{moment(val?.createdAt).format("ll")}</td>
                      <td>
                        {val?.clockIn ? moment(val?.clockIn).format("LT") : "-"}
                      </td>

                      <td>
                        {val?.clockOut
                          ? moment(val?.clockOut).format("LT")
                          : "-"}
                      </td>
                      <td>
                        {val?.status ? (
                          <Tag color="green">Active</Tag>
                        ) : (
                          <Tag color="red">End</Tag>
                        )}
                      </td>
                      <td>{Number(val?.totalAmount).toFixed(2)}</td>
                      <td>{Number(val?.settledAmount).toFixed(2)}</td>
                      <td>
                        <div
                          className="table-view"
                          onClick={() => openItem(val)}
                        >
                          View
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffDataTable;
