import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineWhatsapp } from "react-icons/md";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { IoEarthSharp, IoLocationOutline } from "react-icons/io5";
import { MdArrowOutward } from "react-icons/md";
import BookNow from "../../assets/images/booknow.webp";
import Ticket from "../../assets/images/ticket.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelmetSeo from "../../components/helmetSeo";
import { VscFeedback } from "react-icons/vsc";
import { TiCreditCard } from "react-icons/ti";
const Fade = require("react-reveal/Fade");

function ShareScreena(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    navigate("/feedback");
  };
  const movescreen = () => {
    navigate("/card");
  };
  return (
    <div>
      <HelmetSeo
        title="Share Screen"
        description={`Share the Social Media of Teenzania`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/teenzaniLogo.png"
        canonicalurl="https://www.teenzania.com/share"
        imageAlt="Sharescreen"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> Account <IoIosArrowForward /> Share
              </div>
              <h3 className="heading_h3">Share Now</h3>
              <br />
              <Row>
                <Col
                  sm={3}
                  xs={12}
                  style={{ marginBottom: 25 }}
                  onClick={movescreen}
                >
                  <Fade top>
                    <div className="ShareBox">
                      <div>
                        <TiCreditCard size={30} color="green" />
                        <div className="ShareBox-txt1">Card</div>
                      </div>
                      <div>
                        <MdArrowOutward color="grey" />
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col
                  sm={3}
                  xs={12}
                  style={{ marginBottom: 25 }}
                  onClick={handleClick}
                >
                  <Fade top delay={400}>
                    <div className="ShareBox">
                      <div>
                        <VscFeedback size={30} color="#B8860B" />
                        <div className="ShareBox-txt1">Feedback</div>
                      </div>
                      <div>
                        <MdArrowOutward color="grey" />
                      </div>
                    </div>
                  </Fade>
                </Col>

                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <a
                    href="https://wa.me/+917001800600"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Fade top delay={100}>
                      <div className="ShareBox">
                        <div>
                          <MdOutlineWhatsapp size={30} color="#25d366" />
                          <div className="ShareBox-txt1">Whatsapp</div>
                        </div>
                        <MdArrowOutward color="grey" />
                      </div>
                    </Fade>
                  </a>
                </Col>
                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <a
                    href="https://www.instagram.com/teenzania_adventure_park/ "
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Fade top delay={200}>
                      <div className="ShareBox">
                        <div>
                          <div>
                            <BsInstagram size={30} color="#E1306C" />
                            <div className="ShareBox-txt1">Instagram</div>
                          </div>
                        </div>
                        <div>
                          <MdArrowOutward color="grey" />
                        </div>
                      </div>
                    </Fade>
                  </a>
                </Col>
                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <a
                    href="https://www.facebook.com/profile.php?id=61557318977943"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Fade top delay={300}>
                      <div className="ShareBox">
                        <div>
                          <RiFacebookCircleLine size={30} color="#4267B2" />
                          <div className="ShareBox-txt1">facebook</div>
                        </div>
                        <div>
                          <MdArrowOutward color="grey" />
                        </div>
                      </div>
                    </Fade>
                  </a>
                </Col>
                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <a
                    href="https://twitter.com/Teenzania_"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Fade top delay={400}>
                      <div className="ShareBox">
                        <div>
                          <FaXTwitter size={30} color="#000" />
                          <div className="ShareBox-txt1">Twitter</div>
                        </div>
                        <div>
                          <MdArrowOutward color="grey" />
                        </div>
                      </div>
                    </Fade>
                  </a>
                </Col>
                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <a
                    href="https://teenzania.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Fade top delay={400}>
                      <div className="ShareBox">
                        <div>
                          <IoEarthSharp size={30} color="#1d53d1" />
                          <div className="ShareBox-txt1">Website</div>
                        </div>
                        <div>
                          <MdArrowOutward color="grey" />
                        </div>
                      </div>
                    </Fade>
                  </a>
                </Col>
                <Col sm={3} xs={6} style={{ marginBottom: 25 }}>
                  <Fade top>
                    <div className="ShareBox">
                      <div>
                        <IoLocationOutline size={30} color="#188a8a" />
                        <div className="ShareBox-txt1">Location</div>
                      </div>
                      <MdArrowOutward color="grey" />
                    </div>
                  </Fade>
                </Col>
              </Row>
              <br />
              <Row>
                <Col
                  sm={6}
                  xs={12}
                  style={{ marginBottom: 25 }}
                  onClick={() => navigate("/booking")}
                >
                  <Fade top delay={500}>
                    <div className="ShareBox">
                      <div>
                        <img
                          src={BookNow}
                          className="ShareBox-img"
                          alt="booknow"
                        />
                        <br />
                        <div style={{ fontSize: 25 }}>Book Now</div>
                      </div>
                      <div>
                        <MdArrowOutward color="grey" />
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col sm={6} xs={12} style={{ marginBottom: 25 }}>
                  <Fade top delay={600}>
                    <div className="ShareBox" onClick={() => navigate("/self")}>
                      <div>
                        <img
                          src={Ticket}
                          className="ShareBox-img"
                          alt="ticket"
                        />
                        <br />
                        <div style={{ fontSize: 25 }}>Get Tickets</div>
                      </div>
                      <div>
                        <MdArrowOutward color="grey" />
                      </div>
                    </div>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
}
export default ShareScreena;
