import { Card, Tag } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { Table } from "react-bootstrap";
import NoData from "../components/noData";

const StaffDetails = () => {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.STAFFS_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        const arr: any = [];
        arr.push(response.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <PageHeaders title={"Staff Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Name</th>
                  <th className="table-th">User Name</th>
                  <th className="table-th">Phone</th>
                  <th className="table-th">Role</th>
                  <th className="table-th">Address</th>
                  <th className="table-th">Status</th>
                  <th className="table-th">Self Assign</th>
                  <th className="table-th">ClockIn</th>
                  <th className="table-th">Image</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.name}</td>
                      <td>{item?.userName}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.role}</td>
                      <td>{item?.address}</td>
                      <td>
                        {item?.status === true ? (
                          <Tag color="green">Yes</Tag>
                        ) : (
                          <Tag color="red">No</Tag>
                        )}
                      </td>
                      <td>
                        {item?.self_assign === true ? (
                          <Tag color="green">Yes</Tag>
                        ) : (
                          <Tag color="red">No</Tag>
                        )}
                      </td>
                      <td>{data?.clockIn}</td>
                      <td>
                        <img
                          src={item?.image}
                          alt={item?.name}
                          style={{ width: "100%", height: "120px" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Card>
        <br />
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {data[0]?.ticketDet?.length ? (
                <>
                  <div className="tableHeading">Ticket Details</div>
                  <Table bordered hover>
                    <thead>
                      <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                        <th className="table-th">ID</th>
                        <th className="table-th">Job Id</th>
                        <th className="table-th">Ticket No.</th>
                        <th className="table-th">Created From</th>
                        <th className="table-th">Total Amount</th>
                        <th className="table-th">Grand Total</th>
                        <th className="table-th">Paid Amount</th>
                        <th className="table-th">Paid Method</th>
                        <th className="table-th">Ticket Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[0]?.ticketDet?.map((tktDet: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>{tktDet?.id}</td>
                            <td>{tktDet?.jobId}</td>
                            <td>{tktDet?.ticketNumber}</td>
                            <td>{tktDet?.from}</td>
                            <td>{tktDet?.totalAmount}</td>
                            <td>{tktDet?.grandTotal}</td>
                            <td>{tktDet?.paidAmount}</td>
                            <td>{tktDet?.paymentMethod}</td>
                            <td>
                              {tktDet.activities.map(
                                (act: any, idx: number) => {
                                  return (
                                    <div key={idx}>
                                      <div>
                                        <b>{act.name}</b>
                                      </div>
                                      {act.price.map((prc: any) => (
                                        <div>
                                          {prc.age}-{prc?.price}({prc?.count})
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default StaffDetails;
