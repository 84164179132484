import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { useSelector } from "react-redux";
import ProtectedRoute from "./navigation/protectRouter";
import Routing from "./navigation";
import ThemeObj from "./theme";
import HomeScreen from "./website/homeScreen";
import LoginScreen from "./website/loginScreen";

import AboutUs from "./website/aboutScreen";
import Privacy from "./website/privacyPolicy/index";
import BookingScreen from "./website/bookingScreen";
import TicketInvoice from "./website/TicketInvoice";

import SummaryScreen from "./website/bookingScreen/components/summaryScreen";
import ContactScreen from "./website/contactScreen";
import Refund from "./website/refund/index";
import Delivery from "./website/shipping/index";
import Terms from "./website/terms&Conditions/index";
import SelfTicket from "./website/selfTicket";
import Resort from "./website/resort";
import Offers from "./website/offerScreen";
import BookingSummary from "./website/bookingScreen/components/summaryScreen";
import Downloads from "./website/download";
import ParkTiming from "./website/parkTiming";
import Developer from "./website/dev";
import MobileApp from "./website/dev/mobileApp";
import FeedbackScreen from "./website/feedback";
import CardInfo from "./website/card";
import ShareScreena from "./website/shareScreen";
function App() {
  const User = useSelector((state: any) => state.User);
  return (
    <>
      <ConfigProvider theme={ThemeObj}>
        <Routes>
          <Route index element={<HomeScreen />} />
          <Route index path="/home" element={<HomeScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/share" element={<ShareScreena />} />
          <Route path="/dev" element={<Developer />} />
          <Route path="/mobile-app" element={<MobileApp />} />
          {/* <Route path="/share" element={<ShareScreen />} /> */}
          <Route path="/booking" element={<BookingScreen />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact-us" element={<ContactScreen />} />
          <Route path="/resort" element={<Resort />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/park_timing" element={<ParkTiming />} />
          <Route path="/booking/summary" element={<SummaryScreen />} />
          <Route path="/ticket/:ticketId" element={<TicketInvoice />} />;
          <Route path="/selfticket" element={<SelfTicket />} />
          <Route path="/Summary" element={<BookingSummary />} />
          <Route path="/self" element={<SelfTicket />} />
          <Route path="/download" element={<Downloads />} />
          <Route path="/feedback" element={<FeedbackScreen />} />
          <Route path="/card" element={<CardInfo />} />
          <Route
            path="/Auth/*"
            element={
              <ProtectedRoute isSignedIn={User.auth}>
                <Routing />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ConfigProvider>
    </>
  );
}
export default App;
