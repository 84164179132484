import React from "react";
import { LuUserCircle } from "react-icons/lu";
import { FiUser } from "react-icons/fi";
import { logout } from "../redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";

const PopoverItem = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state?.User?.user);

  const logOut = () => {
    dispatch(logout());
  };
  return (
    <div className="Profile-popover">
      <div className="Popover-item1">
        <LuUserCircle size={28} />
        <div className="Popover-txt1">
          {user?.name ? user.name.toUpperCase() : ""}
        </div>
      </div>
      {/* <div className="Popover-item2">
        <FiUser size={16} />
        <div className="Popover-txt2">Profile</div>
      </div> */}
      <div className="Popover-item3" onClick={logOut}>
        Logout
      </div>
    </div>
  );
};

export default PopoverItem;
