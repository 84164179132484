import { Button, Card, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import NoData from "../components/noData";
import SettlementFormModal from "./settlmentForm";
import TicketModal from "../Tickets/ticketModal";
import { IoReload } from "react-icons/io5";
import TransViewModal from "./transViewModal";

const JobDetails = () => {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isSettlmentModel, setIsSettlmentModel] = useState(false);
  const [jobData, setJobData] = useState<any>([]);
  const [ticketModal, setTicketModal] = useState<any>(false);
  const [ticketNumber, setTicketNumber] = useState<any>(false);
  const [transaction, setTransactions] = useState<any>([]);
  const [transView, setTransView] = useState<any>(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const api = API.JOBS_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        setJobData(response?.data);
        setIsLoading(false);
        setIsLoading2(false);
        walletSummary(response?.data?.job?.staffId);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
        setIsLoading2(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
      setIsLoading2(false);
    }
  };
  const walletSummary = async (u_id: any) => {
    try {
      let obj = {
        staffId: u_id,
        jobId: Number(id),
      };
      let api = API.TRANSACTION_SUMMARY;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
      }
    } catch (err) {
      console.log("err --- >>>", err);
    }
  };
  const totalUpi =
    data?.total?.find((item: any) => item.paymentMethod === "upi")
      ?.totalAmount || 0;

  const getTransactions = async (payMethod: any) => {
    try {
      const api = API.TRANSACTIONS_LIST;
      let obj = {
        type: null,
        paymentMethod: payMethod,
        page: 1,
        take: 100,
        user: jobData?.job?.staffId,
        jobId: jobData?.job?.id,
      };
      const response: any = await POST(api, obj);
      if (response?.status) {
        setTransactions(response.data);
        setIsLoading(false);
        setIsLoading2(false);
      } else {
        console.log("some thing went wrong == >");
        setIsLoading(false);
        setIsLoading2(false);
      }
    } catch (error) {
      console.log("error == >", error);
      setIsLoading(false);
      setIsLoading2(false);
    }
  };
  return (
    <div>
      <PageHeaders title={"Job Details"} backButton={true}>
        <div style={{ display: "flex" }}>
          {jobData?.job?.status ? (
            <Button type="primary" onClick={() => setIsSettlmentModel(true)}>
              Clock Out
            </Button>
          ) : null}
          <div style={{ marginLeft: 6 }}>
            <Button className="refreshBtn mr-3" onClick={() => getDetails()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </div>
      </PageHeaders>
      <div className="screenBox">
        <Row>
          <Col>
            <Card>
              <div className="jobTxt1">Total Collection</div>
              <div className="jobTxt2">
                {data?.grandTotal ? Number(data?.grandTotal).toFixed(2) : 0}
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <div className="jobTxt1">Total Tickets</div>
              <div className="jobTxt2">
                {jobData?.tickets?.length ? jobData?.tickets?.length : 0}
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <div className="jobTxt1">Total Refund</div>
              <div className="jobTxt2">
                {data?.refundcash ? Number(data?.refundcash).toFixed(2) : 0.0}
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="jobTxt1">Total Cash</div>
                <div
                  className="ViewBtn"
                  onClick={() => {
                    setTransView(true);
                    setIsLoading2(true);
                    getTransactions("cash");
                  }}
                >
                  View
                </div>
              </div>
              <div className="jobTxt2">
                {data?.totalcash
                  ? Number(
                      Number(data?.totalcash) -
                        Number(data?.refundcash ? data?.refundcash : 0)
                    ).toFixed(2)
                  : 0}
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="jobTxt1">Total UPI</div>
                <div
                  className="ViewBtn"
                  onClick={() => {
                    setTransView(true);
                    setIsLoading2(true);
                    getTransactions("upi");
                  }}
                >
                  View
                </div>
              </div>
              <div className="jobTxt2">{Number(totalUpi).toFixed(2)}</div>
            </Card>
          </Col>
        </Row>

        <br />
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Name</th>
                  <th className="table-th">Clock In</th>
                  <th className="table-th">Clock Out</th>
                  <th className="table-th">Total Amount</th>
                  <th className="table-th">Settled Amount</th>
                  <th className="table-th">Due Amount</th>
                  <th className="table-th">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr key={1}>
                  <td>{jobData?.job?.id}</td>
                  <td>{jobData?.job?.name}</td>
                  <td>{jobData?.job?.clockIn}</td>
                  <td>{jobData?.job?.clockOut}</td>
                  <td>{jobData?.job?.totalAmount}</td>
                  <td>{jobData?.job?.settledAmount}</td>
                  <td>{jobData?.job?.dueAmount}</td>
                  <td>
                    {jobData?.job?.status === true ? (
                      <Tag color="green">Yes</Tag>
                    ) : (
                      <Tag color="red">No</Tag>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card>
        <br />
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {jobData?.tickets?.length ? (
                <>
                  <div className="tableHeading mb-2 fw-bold">
                    Ticket Details
                  </div>
                  <Table bordered hover>
                    <thead>
                      <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                        <th className="table-th">ID</th>
                        <th className="table-th">Ticket No.</th>
                        <th className="table-th">Qr Code</th>
                        <th className="table-th">Payment Method</th>
                        <th className="table-th">Grand Total</th>
                        <th className="table-th"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobData?.tickets?.map((activity: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{activity.id}</td>
                            <td>{activity.ticketNumber}</td>
                            <td>{activity.qrCode}</td>
                            <td>{activity.paymentMethod}</td>
                            <td>{activity.grandTotal}</td>
                            <td>
                              <div
                                className="ViewBtn"
                                onClick={() => {
                                  setTicketModal(true);
                                  setTicketNumber(activity.ticketNumber);
                                }}
                              >
                                View
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>{" "}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Card>
        <br />
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {jobData?.transactions?.length ? (
                <>
                  <div className="tableHeading mb-2 fw-bold">Transactions</div>
                  <Table bordered hover>
                    <thead>
                      <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                        <th className="table-th">Transaction Id</th>
                        <th className="table-th">Type</th>
                        <th className="table-th">Payment Method</th>
                        <th className="table-th">Amount</th>
                        <th className="table-th">Ticket No.</th>
                        <th className="table-th">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobData?.transactions?.map(
                        (activity: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{activity.transactionId}</td>
                              <td>{activity.type}</td>
                              <td>{activity.paymentMethod}</td>
                              <td>{activity.amount}</td>
                              <td>{activity.ticketNo ?? "-"}</td>
                              <td>
                                {moment(activity.createdAt).format("lll")}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>{" "}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Card>
      </div>
      {isSettlmentModel ? (
        <SettlementFormModal
          jobData={jobData}
          visible={isSettlmentModel}
          onCancel={() => setIsSettlmentModel(false)}
          data={data}
        />
      ) : null}
      {ticketModal ? (
        <TicketModal
          view={ticketModal}
          ticketNo={ticketNumber}
          onClose={() => setTicketModal(false)}
        />
      ) : null}
      {transView ? (
        <TransViewModal
          loading={isLoading2}
          visible={transView}
          onCancel={() => setTransView(false)}
          data={transaction}
        />
      ) : null}
    </div>
  );
};

export default JobDetails;
