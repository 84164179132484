import React from "react";
import PageHeaders from "../../components/pageHeaders";
import { Tabs, TabsProps, notification } from "antd";
import PaymentSettings from "./payment";

const Settings = () => {
  const [Notifications, contextHolder] = notification.useNotification();
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Payment",
      children: <PaymentSettings />,
    },
  ];
  const onChange = (key: string) => {
    console.log(key);
  };
  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Settings"} backButton={true}></PageHeaders>
      <div className="screenBox">
        <Tabs
          size="large"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Settings;
