import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "job",
  initialState: {
    job: {},
    isClockIn: null,
    jobId: 0,
  },
  reducers: {
    updateJob: (state, action) => {
      state.job = action?.payload;
      state.isClockIn = action?.payload?.isClockIn;
      state.jobId = action?.payload?.id;
    },
  },
});

export const { updateJob } = jobSlice.actions;
export default jobSlice.reducer;
