import { Card } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { FaRegCalendarCheck } from "react-icons/fa";
import Bookings from "./component/bookings";
import GraphChart from "./component/graph";
import "./styles.scss";
import Cards from "./component/cards";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import { useEffect, useState } from "react";

function Dashboard() {
  const [booking, setbooking] = useState([]);
  const [Staffs, setStaffs] = useState([]);
  const [Tickets, setTickets] = useState([]);
  const [Activies, setActivies] = useState([]);
  const [graphData, setGraphData] = useState<any>({});
  const [bookings, setBookings] = useState<any>({});

  useEffect(() => {
    getAllCount();
    getGraphData();
    getBooking();
  }, []);

  const getAllCount = async () => {
    try {
      const Response: any = await GET(API.DASHBOARD_COUNT);
      if (Response.status) {
        setbooking(Response.data.bookingCount);
        setStaffs(Response.data.staffCount);
        setTickets(Response.data.ticketCount);
        setActivies(Response.data.activityCount);
      } else {
        console.log("some thing went wrong");
      }
    } catch (err) {
      console.log("err === >", err);
    }
  };

  const getGraphData = async () => {
    try {
      const Response: any = await GET(API.DASHBOARD_GRAPH);
      if(Response?.status){
        setGraphData(Response?.data);
      }else{
        console.log("some thing went wrong");
      }
    } catch (err) {
      console.log("some thing went wrong");
    }
  };

  const getBooking = async () => {
    try {
      let obj = {
        page: 1,
        take: 5,
      };
      let api = API.BOOKING_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setBookings(response?.data);
      } else {
        setBookings([]);
      }
    } catch (err) {
      console.log("errrrr", err);
    }
  };

  return (
    <Container>
      <br />
      <Row>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            label={"Bookings"}
            value={booking}
          />
        </Col>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            label={"Tickets"}
            value={Tickets}
          />
        </Col>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            label={"Staffs"}
            value={Staffs}
          />
        </Col>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            label={"Activities"}
            value={Activies}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={7}>
        <div className="dashboard-Txt1">Ticket Sales</div>
          <div className="Dashboard-graph">
            <br/>
            <GraphChart
              data={{
                data: graphData?.values,
                labels:graphData?.labels
              }}
            />
          </div>
        </Col>
        <Col md={5}>
          <div className="dashboard-Txt1">Bookings</div>
        <Bookings data={bookings}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
