import { useEffect, useState } from "react";
import { Button, Card, DatePicker, Form, Select, notification } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { API } from "../../config/Api";
import { DELETE, POST } from "../../utils/apiRequest";
import HorizontalLineLoader from "../components/loader";
import OffersFormModal from "./form";
import OffersDataTable from "./dataTable";
import { IoReload } from "react-icons/io5";

function Offers() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getOffers();
  }, [page, take]);

  const getOffers = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.OFFERS_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const deleteOffers = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.OFFERS_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Offers Deleted",
        });
        getOffers();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Offers not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Offers not deleted",
      });
    }
  };

  const editOffers = (act: any) => {
    try {
      setSelectedItem(act);
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  //   const onView = (val: any) => {
  //     navigate(`/Auth/offersDetails/${val}`, { state: { val } });
  //   };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Offers"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getOffers}>
          <div className="PageHeaderBox">
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => editOffers({})} type="primary">
                Create +
              </Button>
            </Form.Item>

            <Button className="refreshBtn" onClick={() => getOffers()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <OffersDataTable
                data={data}
                onEdit={(item: any) => editOffers(item)}
                // onView={(item: any) => onView(item)}
                delete={(id: any) => deleteOffers(id)}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          )}
        </Card>
      </div>
      {modalVisible ? (
        <OffersFormModal
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={() => {
            setIsLoading2(true);
            editOffers({});
            getOffers();
          }}
        />
      ) : null}
    </div>
  );
}
export default Offers;
