import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { IoIosArrowForward } from "react-icons/io";
import HelmetSeo from "../../components/helmetSeo";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HelmetSeo
        title="Cancelllation and Refund"
        description={`Teenzania believes in helping its customers
        as far as possible, and has therefore a liberal cancellation
        policy. Under this policy`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/teenzaniLogo.png"
        canonicalurl="https://www.teenzania.com/refund"
        imageAlt="Offers"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> Legal Terms <IoIosArrowForward />{" "}
                Cancellation and Refund
              </div>
              <h3 className="heading_h3">Cancellation and Refund</h3>
              <br />
              <Col md={8} sm={12}>
                <p>
                  <strong>TEENZANIA</strong> believes in helping its customers
                  as far as possible, and has therefore a liberal cancellation
                  policy. Under this policy:
                </p>
                <p>
                  Cancellations will be considered only if the request is made
                  within 2-3 days of placing the order. However, the
                  cancellation request may not be entertained if the orders have
                  been communicated to the vendors/merchants and they have
                  initiated the process of shipping them.
                </p>
                <p>
                  TEENZANIA does not accept cancellation requests for perishable
                  items like flowers, eatables etc. However, refund/replacement
                  can be made if the customer establishes that the quality of
                  product delivered is not good.
                </p>
                <p>
                  In case of receipt of damaged or defective items please report
                  the same to our Customer Service team. The request will,
                  however, be entertained once the merchant has checked and
                  determined the same at his own end. This should be reported
                  within 2-3 days of receipt of the products.
                </p>
                <p>
                  In case you feel that the product received is not as shown on
                  the site or as per your expectations, you must bring it to the
                  notice of our customer service within 2-3 days of receiving
                  the product. The Customer Service Team after looking into your
                  complaint will take an appropriate decision.
                </p>
                <p>
                  In case of complaints regarding products that come with a
                  warranty from manufacturers, please refer the issue to them.
                </p>
                <p>
                  In case of any Refunds approved by the{" "}
                  <strong>TEENZANIA</strong>, it’ll take 3-4 days for the refund
                  to be processed to the end customer.
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
