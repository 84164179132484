import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export const options = {
  responsive: true,
  borderRadius : 100 ,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {display: false},
  },
  barPercentage: 0.2
};

export default function GraphChart(props:any) {
  const labels = props?.data?.labels;
  const data = {
    labels,
    datasets: [ 
      {
        label: "Ticket Sales",
        data:  props?.data?.data,
        borderColor: "#08493e",
        backgroundColor: "#08493e",
      },
    ],
  };  
  return (
    <div className="Dashboard-ViewChart">
      <div style={{height:500,overflow:"hidden"}}>
        <Bar options={options} data={data}/>
      </div>
    </div>
  );
}
