import { Button, Input } from "antd";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Developer = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const devLogin = () => {
    try {
      if (username === "developer" && password === "developer123") {
        navigate("/mobile-app");
      } else {
        alert("login failed");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container>
        <h4>Teenzania Developer Portal</h4>
        <div className="">Login to continue</div>
        <br />
        <Row>
          <Col md="4">
            <Input
              type="text"
              onChange={(e: any) => setUsername(e.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="4">
            <Input
              type="password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col md="4">
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => devLogin()}
            >
              Login
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Developer;
