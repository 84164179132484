import { Button, Card, DatePicker, Form, notification, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ActivityDataTable from "./dataTable";
import { API } from "../../../config/Api";
import { GET, POST } from "../../../utils/apiRequest";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { SiMicrosoftexcel } from "react-icons/si";
import { ExportToExcel } from "../../../utils/exportToExcel";
import NoData from "../../components/noData";
import { IoReload } from "react-icons/io5";

const ActivityReport = (props: any) => {
  const [form] = useForm();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(true);
  const [Notifications, contextHolder] = notification.useNotification();
  const [activities, setActivities] = useState([]);
  const [summary, setSummary] = useState<any>({});
  const [selectedAct, setSelectedAct] = useState<any>({});
  const [actReport, setActReport] = useState<any>([]);
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const [isLoading2, setIsLoading2] = useState(false);
  const [startDate, setStartDate] = useState(firstDayOfMonth) as any;
  const [endDate, setEndDate] = useState(lastDayOfMonth) as any;

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let api = API.ACTIVITY_PICKER;
      const result: any = await GET(api, null);
      if (result?.status) {
        setActivities(result?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getReport = async (page: any, take: any) => {
    try {
      setIsLoading2(true);
      let api = API.ACTIVITY_REPORT;
      const formData = form.getFieldsValue();
      const start = formData.date
        ? moment(formData.date[0].$d).startOf("month")
        : moment().startOf("month");
      const end = formData.date
        ? moment(formData.date[1].$d).endOf("month")
        : moment().endOf("month");
      setStartDate(start);
      setEndDate(end);
      const obj = {
        activity: formData.activity,
        startDate: start,
        endDate: end,
      };
      const report: any = await POST(api, obj);
      if (report?.status) {
        const activitiesByDate: any = {};
        report?.data?.forEach((activity: any) => {
          const createdAt = new Date(activity.createdAt);
          const dateKey = createdAt.toISOString().split("T")[0];
          if (!activitiesByDate[dateKey]) {
            activitiesByDate[dateKey] = {
              date: dateKey,
              totalTickets: 0,
              totalAmount: 0,
            };
          }
          activity.activities.forEach((act: any) => {
            act.price.forEach((priceItem: any) => {
              const itemCount = priceItem.count || 1;
              activitiesByDate[dateKey].totalTickets += itemCount;
            });
          });
          activitiesByDate[dateKey].totalAmount += activity.totalAmount;
        });
        const mappedData = Object.values(activitiesByDate);
        calculateTotal(mappedData);
        setActReport(mappedData);
        setIsLoading2(false);
      } else {
        setIsLoading2(false);
        setActReport([]);
        notification.error({
          message: "Data not available",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotal = (data: any[]) => {
    const totals = data?.reduce(
      (acc: any, val: any) => {
        acc.totalAmount += Number(val.totalAmount);
        acc.totalTickets += Number(val.totalTickets);
        return acc;
      },
      { totalAmount: 0, totalTickets: 0 }
    );
    setSummary(totals);
  };

  return (
    <div className="screenBox">
      {contextHolder}
      <Card
        title={<div className="title1">Select Activity</div>}
        extra={
          <div className="PageHeaderBox">
            <Button className="refreshBtn" onClick={() => getReport(1, 10)}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        }
      >
        <div className="">
          <br />
          <Form form={form} onFinish={() => getReport(1, 10)}>
            <Row>
              <Col md="4">
                <Form.Item
                  name={"activity"}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Select a staff",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    allowClear
                    showSearch={true}
                    placeholder={"Select Activity"}
                    onChange={(data: any, val: any) => setSelectedAct(val)}
                    defaultValue={"Select Activity"}
                    loading={isLoading}
                  >
                    {activities?.map((option: any) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md="3">
                <Form.Item name={"date"} noStyle>
                  <DatePicker.RangePicker
                    size="large"
                    picker="month"
                    style={{ width: "100%" }}
                    onChange={(_, val: any) => {}}
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                  />
                </Form.Item>
              </Col>

              <Col md="1"></Col>

              <Col md="2">
                <Button
                  onClick={() =>
                    ExportToExcel(actReport, `${selectedAct?.children}`)
                  }
                  size="large"
                  style={{ width: "100%" }}
                >
                  <SiMicrosoftexcel color="#08493e" size={20} /> &nbsp;Export
                </Button>
              </Col>

              <Col md="2">
                <Button
                  loading={isLoading2}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Get Report
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <br />

        {actReport?.length ? (
          <>
            <hr />
            <br />
            <ActivityDataTable
              activity={selectedAct}
              summary={summary}
              data={actReport}
              loading={isLoading2}
            />
          </>
        ) : (
          <NoData />
        )}
      </Card>
    </div>
  );
};

export default ActivityReport;
