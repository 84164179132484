import { Helmet } from "react-helmet";

const HelmetSeo = (props: any) => {
  return (
    <div>
      <Helmet>
        <title>{props?.title} | Teenzania</title>
        <meta name="description" content={props?.description} />
        <link rel="canonical" href={props?.canonicalurl} />
        <meta property="og:title" content={`Teenzania | ${props?.title}`} />
        <meta property="og:description" content={props?.description} />
        <meta property="og:image" content={props?.image} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content={props?.canonicalurl} />
        <meta property="og:type" content="website" />
        <meta property="og:image:alt" content={props?.imageAlt} />
      </Helmet>
    </div>
  );
};

export default HelmetSeo;
