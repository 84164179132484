import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import logo from "../../config/logo-color.svg";
import getTicket from "../../assets/images/ticketbtn.svg";
import Menu from "./menu.json";
import { Container } from "react-bootstrap";
import { CgMenuRound } from "react-icons/cg";
import CustomDrawer from "./drawer";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");
const Header = (props: any) => {
  const navigation = useNavigate();
  const [lastScrollY, setLastScrollY] = useState<any>(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (lastScrollY > window.scrollY) {
        setLastScrollY(false);
      } else if (lastScrollY + 50 < window.scrollY) {
        setLastScrollY(true);
      }
    },
    [lastScrollY]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleNavigation);
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [lastScrollY]);

  return (
    <nav
      className={
        props?.type === 1
          ? lastScrollY
            ? "Header-scrolled"
            : "Header"
          : "Header-scrolled2"
      }
    >
      <Container className="Header-Container">
        <div className="Header-Box">
          <div>
            <Fade top>
              <img
                src={logo}
                onClick={() => navigation("/")}
                alt="teenzania"
                className={
                  props?.type === 1
                    ? lastScrollY
                      ? "Header-logo scrolled"
                      : "Header-logo"
                    : "Header-logo scrolled"
                }
              />
            </Fade>
          </div>
          <div className="Header-Box2" style={{ flex: 1 }}>
            {Menu?.map((item: any, index: number) => (
              <Fade top key={index} delay={index * 30}>
                <div
                  className="Header-items"
                  onClick={() => navigation(item?.path)}
                >
                  {item?.name}
                </div>
              </Fade>
            ))}
          </div>
          {props?.type === 1 ? (
            lastScrollY ? (
              <div>
                <Fade top delay={300}>
                  <img
                    src={getTicket}
                    alt="ticket"
                    className="Header-ticket"
                    onClick={() => navigation("/booking")}
                  />
                  <div className="Header-mobileMenu">
                    <CgMenuRound size={40} onClick={() => setIsOpen(true)} />
                  </div>
                </Fade>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div>
              <Fade top delay={300}>
                <img
                  src={getTicket}
                  alt="ticket"
                  className="Header-ticket"
                  onClick={() => navigation("/booking")}
                />
                <div className="Header-mobileMenu">
                  <CgMenuRound size={40} onClick={() => setIsOpen(true)} />
                </div>
              </Fade>
            </div>
          )}
        </div>
      </Container>
      {isOpen && (
        <CustomDrawer
          onClose={() => setIsOpen(false)}
          open={isOpen}
          options={Menu}
        />
      )}
    </nav>
  );
};
export default Header;
