import { Col, Row } from "react-bootstrap";
import water from "../../../../assets/images/SwimmingpoolMobile.png";
import boat from "../../../../assets/images/boatMobile.png";
import train from "../../../../assets/images/trainMobile.png";
import enjoy from "../../../../assets/images/bro.png";
import craft from "../../../../assets/images/handcraftMobile.png";
import cafe from "../../../../assets/images/cafeMobile.png";
import card1 from "../../../../assets/images/Frame31.png";
import card2 from "../../../../assets/images/Frame34.png";
import card3 from "../../../../assets/images/frame35.png";
import card4 from "../../../../assets/images/Frame37.png";
import card5 from "../../../../assets/images/Frame39.png";
import card6 from "../../../../assets/images/Frame38.png";
import card7 from "../../../../assets/images/Frame66.png";
import card8 from "../../../../assets/images/Frame65.png";
import card9 from "../../../../assets/images/Frame86.png";
import card10 from "../../../../assets/images/Frame87.png";
import card11 from "../../../../assets/images/Frame88.png";
import card12 from "../../../../assets/images/Frame67.png";
import card13 from "../../../../assets/images/soveneer.png";
import card14 from "../../../../assets/images/ceramic.png";
import card15 from "../../../../assets/images/teajug.png";
import card16 from "../../../../assets/images/honey.png";
import card17 from "../../../../assets/images/spices.png";
import card18 from "../../../../assets/images/herbals.png";
import card19 from "../../../../assets/images/bedsheet.png";
import card20 from "../../../../assets/images/garments.png";
import card21 from "../../../../assets/images/leather.png";
import card22 from "../../../../assets/images/fancy.png";
import card23 from "../../../../assets/images/candies.png";
import card24 from "../../../../assets/images/parkFloating.png";
import card25 from "../../../../assets/images/chocolate.png";
import card26 from "../../../../assets/images/livePlants.png";
import "./styles.scss";
const ActivityCard = () => {
  const cards1 = [
    {
      image: card1,
      head: "Inflatable Slide",
    },
    {
      image: card2,
      head: "Archery",
    },
    {
      image: card3,
      head: "Bull Ride",
    },
    {
      image: card4,
      head: "Rop Course",
    },
  ];
  const cards2 = [
    {
      image: card5,
      head: "Trampoline Bouncing",
    },
    {
      image: card6,
      head: "Burma Bridge",
    },
    {
      image: card7,
      head: "Kids Traffic Park",
    },
    {
      image: card8,
      head: "Giant Swing",
    },
  ];
  const cards3 = [
    {
      image: card9,
      head: "Fish Spa",
    },
    {
      image: card10,
      head: "Paint Ball Game",
    },
    {
      image: card11,
      head: "Zorby Fighting Game",
    },
    {
      image: card12,
      head: "Quad Bike Riding",
    },
  ];
  const cards4 = [
    {
      image: card13,
      head: "Sovenir Shop",
    },
    {
      image: card14,
      head: "Ceramic Shop",
    },
    {
      image: card15,
      head: "Tea Boutique",
    },
    {
      image: card16,
      head: "Honey Store",
    },
  ];
  const cards5 = [
    {
      image: card17,
      head: "Spices & Masala Store",
    },
    {
      image: card18,
      head: "Herbals & Ayurveda’s Store",
    },
    {
      image: card19,
      head: "Blanket And Bedsheet ",
    },
    {
      image: card20,
      head: "Garments & Pools Accessories",
    },
  ];
  const cards6 = [
    {
      image: card21,
      head: "Leather Store",
    },
    {
      image: card22,
      head: "Fancy Store",
    },
    {
      image: card23,
      head: "Candies Store",
    },
    {
      image: card25,
      head: "Chocolates Store",
    },
    {
      image: card24,
      head: "Floating Market",
    },

    {
      image: card26,
      head: "Live Plants",
    },
  ];
  return (
    <>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards1?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <div className="card-image">
                <img src={item?.image} alt="Image" className="w-100" />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col md={12} sm={12}>
          <div className="textBoxMobile">
            <div className="fancytxt1"> Enjoy</div>
            <div className="upperHeadTxt">BE A TENZO</div>
            <div className="headerTxtMain">BE HAPPY</div>
          </div>
        </Col>
        <Col md={12} xs={12}>
          <Row className="g-0 mx-0">
            <Col md={8} sm={12}>
              <img
                src={enjoy}
                width={"100%"}
                style={{ objectFit: "contain" }}
              />
            </Col>
            <Col md={4} sm={12}>
              <div className="textBox">
                <div className="fancytxt1" style={{ width: "80%" }}>
                  Enjoy
                </div>
                <div>
                  <div className="upperHeadTxt">BE A TENZO</div>
                  <div className="headerTxtMain">BE HAPPY</div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards2?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col md={12} xs={12}>
          <div className="textBoxMobile">
            <div className="fancytxt2">Water</div>
            <div className="upperHeadTxt">FAMILY ORIENTED </div>
            <div className="headerTxtMain">Swimming Pool</div>
            <img
              src={water}
              style={{ objectFit: "contain" }}
              width={"100%"}
              alt="waterImage"
            />
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div className="waterImageParent">
            <Col md={6}>
              <div className="textBox">
                <div className="fancytxt2">Water</div>
                <div>
                  <div className="upperHeadTxt">FAMILY ORIENTED </div>
                  <div className="headerTxtMain">Swimming Pool</div>
                </div>
              </div>
            </Col>
            <Col md={6} />
          </div>
        </Col>
      </Row>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards3?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col md={12} xs={12}>
          <div className="textBoxMobile">
            <div className="fancytxt2">Boat</div>

            <div className="upperHeadTxt">KIDS</div>
            <div className="headerTxtMain">Paddle Boat</div>
            <img
              src={boat}
              style={{ objectFit: "contain" }}
              width={"100%"}
              alt="boatImage"
            />
          </div>
        </Col>
        <Col md={12} sm={12}>
          <div className="boatImageParent">
            <Row className="mx-0 g-0">
              {" "}
              <Col md={6} />
              <Col md={6}>
                <div className="textBox">
                  <div className="fancytxt2">Boat</div>
                  <div>
                    <div className="upperHeadTxt">KIDS</div>
                    <div className="headerTxtMain">Paddle Boat</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards4?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col md={12} xs={12}>
          <div className="textBoxMobile">
            <div className="fancytxt3">Train</div>
            <div className="upperHeadTxt">RIDE WITH KIDS ON</div>
            <div className="headerTxtMain">Toy Train</div>
            <img src={train} style={{ objectFit: "contain" }} width={"100%"} />
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div className="trainImageParent">
            <Col md={5}>
              <div className="textBox">
                <div className="fancytxt3">Train</div>
                <div>
                  <div className="upperHeadTxt">RIDE WITH KIDS ON</div>
                  <div className="headerTxtMain">Toy Train</div>
                </div>
              </div>
            </Col>
            <Col md={7} />
          </div>
        </Col>
      </Row>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards5?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col md={12} xs={12}>
          <div className="textBoxMobile">
            <div className="fancytxt1">Cafe</div>
            <div className="upperHeadTxt">
              A COZY HEAVEN WHERE
              <br /> EVERY SIP TELLS
            </div>
            <div className="headerTxtMain">A Story</div>
            <img
              src={cafe}
              style={{ objectFit: "contain" }}
              width={"100%"}
              alt="cafeImage"
            />
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div className="cafeImageParent">
            <Row className="g-0 mx-0">
              <Col md={7} />

              <Col md={5} className="pt-5  d-flex flex-column align-items-end">
                <div className="textBox">
                  <div className="fancytxt1">Cafe</div>
                  <div>
                    <div className="upperHeadTxt">
                      A COZY HEAVEN WHERE
                      <br /> EVERY SIP TELLS
                    </div>
                    <div className="headerTxtMain">A Story</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="cardCntainer">
        <Row className="g-4">
          {cards6?.slice(0, 4)?.map((item: any, index: any) => (
            <Col md={3} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
          {cards6?.slice(4, 6)?.map((item: any, index: any) => (
            <Col md={6} xs={6} key={index}>
              <div className="cardHead">{item?.head}</div>
              <img src={item?.image} alt="Image" className="w-100" />
            </Col>
          ))}
        </Row>
      </div>
      <Row className="g-0 mx-0">
        <Col sm={12} xs={12}>
          <div className="textBoxMobile">
            <div className="fancytxt3">Handcraft</div>
            <div className="upperHeadTxt">DISCOVER THE BEAUTY OF</div>
            <div className="headerTxtMain">Handcrafting</div>
            <img
              src={craft}
              style={{ objectFit: "contain" }}
              width={"100%"}
              alt="craftImage"
            />
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div className="craftImageParent">
            <Col md={6} className="pt-5 d-flex flex-column align-items-center">
              <div className="textBox pt-5">
                <div className="fancytxt3">Handcraft</div>
                <div>
                  <div className="upperHeadTxt">DISCOVER THE BEAUTY OF</div>
                  <div className="headerTxtMain">Handcrafting</div>
                </div>
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ActivityCard;
