import React from "react";
import { Tag, Tooltip } from "antd";
import { CiRead } from "react-icons/ci";
import moment from "moment";
import { Table } from "react-bootstrap";
import Loading from "../../components/loading";

function DataTable(props: any) {
  return (
    <div>
      {props?.loading ? (
        <Loading />
      ) : (
        <div className="">
          <div className="">
            <Table bordered>
              <thead>
                <tr>
                  <td className="report-headtd">Id</td>
                  <td className="report-headtd">Card No.</td>
                  <td className="report-headtd">Name</td>
                  <td className="report-headtd">Phone</td>
                  <td className="report-headtd">Balance</td>
                  <td className="report-headtd">Status</td>
                  <td className="report-headtd">Assigned</td>
                  <td className="report-headtd">Created At</td>
                  <td className="report-headtd"></td>
                </tr>
              </thead>

              <tbody>
                {props?.data?.map((act: any) => {
                  return (
                    <tr>
                      <td>{act?.id}</td>
                      <td>{act?.cardNo}</td>
                      <td>{act?.name}</td>
                      <td>{act?.phone}</td>
                      <td>{Number(act?.balance).toFixed(2)}</td>
                      <td>
                        {act?.status ? (
                          <Tag color="green">Active</Tag>
                        ) : (
                          <Tag color="red">Inactive</Tag>
                        )}
                      </td>
                      <td>
                        {act?.assigned ? (
                          <Tag color="green">Assigned</Tag>
                        ) : (
                          <Tag color="red">Refunded</Tag>
                        )}
                      </td>
                      <td>{moment(act?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        <div>
                          <Tooltip
                            title="View"
                            mouseEnterDelay={0.5}
                            color="#8f8e8d"
                          >
                            <CiRead
                              size={20}
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => props?.onView(act)}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataTable;
