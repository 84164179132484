import { Button, Card, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { GET, POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import moment from "moment";
import NoData from "../../components/noData";
import { Col, Row, Table } from "react-bootstrap";
import Loading from "../../../components/loading";
import { IoIosArrowBack } from "react-icons/io";

const JobDetailsetails = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobData, setJobData] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.JOBS_DETAILS + props?.id;
      let response: any = await GET(api);
      if (response?.status) {
        setJobData(response?.data);
        setIsLoading(false);
        walletSummary(response?.data?.job?.staffId);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };

  const walletSummary = async (u_id: any) => {
    try {
      let obj = {
        staffId: u_id,
        jobId: Number(props?.id),
      };
      let api = API.TRANSACTION_SUMMARY;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
      }
    } catch (err) {
      console.log("err --- >>>", err);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Button onClick={() => props?.back()}>
            <IoIosArrowBack size={18} color="#000" />
          </Button>
          <br />
          <br />
          <div className="">
            <Row>
              <Col>
                <Card>
                  <div className="jobTxt1">Total Collection</div>
                  <div className="jobTxt2">
                    {data?.grandTotal ? Number(data?.grandTotal).toFixed(2) : 0}
                  </div>
                </Card>
              </Col>
              <Col>
                <Card>
                  <div className="jobTxt1">Total Tickets</div>
                  <div className="jobTxt2">
                    {jobData?.tickets?.length ? jobData?.tickets?.length : 0}
                  </div>
                </Card>
              </Col>
              <Col>
                <Card>
                  <div className="jobTxt1">Total Refund</div>
                  <div className="jobTxt2">
                    {data?.refundcash
                      ? Number(data?.refundcash).toFixed(2)
                      : 0.0}
                  </div>
                </Card>
              </Col>
              <Col>
                <Card>
                  <div className="jobTxt1">Cash In Hand</div>
                  <div className="jobTxt2">
                    {data?.totalcash
                      ? Number(
                          Number(data?.totalcash) -
                            Number(data?.refundcash ? data?.refundcash : 0)
                        ).toFixed(2)
                      : 0}
                  </div>
                </Card>
              </Col>
            </Row>

            <br />
            {isLoading ? (
              <Loading />
            ) : (
              <Table bordered hover>
                <thead>
                  <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                    <th className="table-th">ID</th>
                    <th className="table-th">Name</th>
                    <th className="table-th">Clock In</th>
                    <th className="table-th">Clock Out</th>
                    <th className="table-th">Total Amount</th>
                    <th className="table-th">Settled Amount</th>
                    <th className="table-th">Due Amount</th>
                    <th className="table-th">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td>{jobData?.job?.id}</td>
                    <td>{jobData?.job?.name}</td>
                    <td>{moment(jobData?.job?.clockIn).format("LT")}</td>
                    <td>{moment(jobData?.job?.clockOut).format("LT")}</td>
                    <td>{Number(jobData?.job?.totalAmount).toFixed(2)}</td>
                    <td>{Number(jobData?.job?.settledAmount).toFixed(2)}</td>
                    <td>{Number(jobData?.job?.dueAmount).toFixed(2)}</td>
                    <td>
                      {jobData?.job?.status === true ? (
                        <Tag color="green">Active</Tag>
                      ) : (
                        <Tag color="red">End</Tag>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            <br />

            {/* {isLoading ? (
              <Loading />
            ) : (
              <>
                {jobData?.tickets?.length ? (
                  <>
                    <div className="title1">Ticket Details</div>
                    <Table bordered hover>
                      <thead>
                        <tr
                          style={{ backgroundColor: "#08493e", color: "#fff" }}
                        >
                          <th className="table-th">ID</th>
                          <th className="table-th">Ticket No.</th>
                          <th className="table-th">Ticket Info</th>
                          <th className="table-th">Payment Method</th>
                          <th className="table-th">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobData?.tickets?.map(
                          (activity: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{activity.id}</td>
                                <td>{activity.ticketNumber}</td>
                                <td>
                                  {activity.activities.map(
                                    (act: any, idx: number) => {
                                      return (
                                        <div
                                          key={idx}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div>
                                            <b>{act.name} : &nbsp;</b>
                                          </div>
                                          {act.price.map((prc: any) => (
                                            <div>
                                              {prc.age}-{prc?.price}(
                                              {prc?.count})
                                            </div>
                                          ))}
                                        </div>
                                      );
                                    }
                                  )}
                                </td>
                                <td>{activity.paymentMethod}</td>
                                <td>
                                  {Number(activity.grandTotal).toFixed(2)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>{" "}
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
            <br />

            {isLoading ? (
              <Loading />
            ) : (
              <>
                {jobData?.transactions?.length ? (
                  <>
                    <div className="title1">Transactions</div>
                    <Table bordered hover>
                      <thead>
                        <tr
                          style={{ backgroundColor: "#08493e", color: "#fff" }}
                        >
                          <th className="table-th">Transaction Id</th>
                          <th className="table-th">Type</th>
                          <th className="table-th">Payment Method</th>
                          <th className="table-th">Amount</th>
                          <th className="table-th">Ticket No.</th>
                          <th className="table-th">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobData?.transactions?.map(
                          (activity: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{activity.transactionId}</td>
                                <td>{activity.type}</td>
                                <td>{activity.paymentMethod}</td>
                                <td>{activity.amount}</td>
                                <td>{activity.ticketNo ?? "-"}</td>
                                <td>
                                  {moment(activity.createdAt).format("lll")}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default JobDetailsetails;
