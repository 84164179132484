import { useEffect, useState } from "react";
import { Button, Card, DatePicker, Form, Select, Table, notification } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { API } from "../../config/Api";
import { DELETE, POST } from "../../utils/apiRequest";
import HorizontalLineLoader from "../components/loader";
import { IoReload } from "react-icons/io5";
import { EyeOutlined } from "@ant-design/icons";
import FeedbackDataTable from "./dataTable";

const Feedback = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    loadFeedbacks();
  }, [page, take]);
  console.log("data:",data);

  const loadFeedbacks = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        date: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.FEEDBACK;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  
  const onView = (val: any) => {
    
  };


  

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Feedbacks"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={loadFeedbacks}>
          <div className="PageHeaderBox">
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>

            <Button className="refreshBtn" onClick={() => loadFeedbacks()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        {/* <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <OffersDataTable
                data={data}
                onEdit={(item: any) => editOffers(item)}
                // onView={(item: any) => onView(item)}
                delete={(id: any) => deleteOffers(id)}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          )}
        </Card> */}
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <FeedbackDataTable
              data={data}
              onView={(item: any) => onView(item)}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading2(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          )}
        </Card>
        
      </div>
    </div>
  );
};

export default Feedback;
