import { Modal, Table, Tag } from "antd";
import React from "react";
import moment from "moment";
import Loading from "../../components/loading";

const TransViewModal = (props: any) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div>
      <Modal
        open={props.visible}
        title={<div style={{ fontSize: 25 }}>Card Details</div>}
        onCancel={props.onCancel}
        footer={false}
        width={700}
      >
        <hr />
        {props?.loading ? (
          <Loading />
        ) : (
          <Table
            columns={columns}
            dataSource={props.data}
            rowKey="cardNo"
            bordered
          />
        )}
      </Modal>
    </div>
  );
};

export default TransViewModal;
