import { Col, Row } from "react-bootstrap";
import "./styles.scss";
const About = () => {
  return (
    <>
      <div className="aboutBg">
        <Row className="mx-0 g-0">
          <Col lg={6} xs={0} md={0} sm={0} />
          <Col lg={6} xs={12} sm={12} md={0}>
            <div className="textContainer">
              <div className="aboutus-heading">About Us</div>
              <div className="aboutus-desc">
                We located in the stunning Lakkidi in Wayanad, the Teenzania
                Amusement and Adventure Project promises a unique blend of
                leisure and adventure. Set to become Wayanad's highlight, it
                invites visitors worldwide to experience its unmatched natural
                beauty and thrilling adventures. Lakkidi, known as Wayanad's
                gem, showcases nature's everlasting charm, offering a sensory
                feast regardless of the season, making every visit memorable.{" "}
                <br />
                <br /> Adventure Park is a thrilling outdoor destination perfect
                for teenagers and anyone looking for fun and adventure. With
                everything from high-speed zip lines and challenging obstacle
                courses to climbing towers, boulder walls, and mountain biking
                trails, our park has something for everyone. Plus, our serene
                lake offers kayaking and paddle boarding for a refreshing
                escape. It's a place where boredom doesn't exist, and adventure
                awaits at every turn.
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* mobile */}
      <div className="aboutBgSm">
        <Row className="mx-0 g-0">
          <Col sm={12} xs={12} lg={12} className="aboutusImage">
            <div className="aboutus-heading d-flex justify-content-center align-items-center pt-2">
              About Us
            </div>
          </Col>
          <Col xs={12} sm={12} lg={12}>
            <div className="textContainer">
              <div className="aboutus-descSm">
                We located in the stunning Lakkidi in Wayanad, the Teenzania
                Amusement and Adventure Project promises a unique blend of
                leisure and adventure. Set to become Wayanad's highlight, it
                invites visitors worldwide to experience its unmatched natural
                beauty and thrilling adventures. Lakkidi, known as Wayanad's
                gem, showcases nature's everlasting charm, offering a sensory
                feast regardless of the season, making every visit memorable.{" "}
                <br />
                <br /> Adventure Park is a thrilling outdoor destination perfect
                for teenagers and anyone looking for fun and adventure. With
                everything from high-speed zip lines and challenging obstacle
                courses to climbing towers, boulder walls, and mountain biking
                trails, our park has something for everyone. Plus, our serene
                lake offers kayaking and paddle boarding for a refreshing
                escape. It's a place where boredom doesn't exist, and adventure
                awaits at every turn.
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default About;
