import { Col, Row, Container } from "react-bootstrap";
import { Carousel } from "antd";
import ticket from "../../../assets/images/ticketbtn.svg";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

function Section1() {
  const navigation = useNavigate();
  return (
    <div className="Home-Section1">
      <Container fluid style={{ margin: 0, padding: 0 }}>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col sm={3} xs={6} style={{ margin: 0, padding: 0 }}>
            <Fade top>
              <Carousel dots={false} autoplay={true} autoplaySpeed={5000}>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img0.png")}
                  />
                </div>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img1.jpeg")}
                  />
                </div>
              </Carousel>
            </Fade>
          </Col>
          <Col sm={3} xs={6} style={{ margin: 0, padding: 0 }}>
            <Fade bottom>
              <Carousel dots={false} autoplay={true} autoplaySpeed={6500}>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img2.png")}
                  />
                </div>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img3.jpeg")}
                  />
                </div>
              </Carousel>
            </Fade>
          </Col>
          <Col sm={3} xs={6} style={{ margin: 0, padding: 0 }}>
            <Fade top>
              <Carousel dots={false} autoplay={true} autoplaySpeed={7000}>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img4.png")}
                  />
                </div>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img5.jpeg")}
                  />
                </div>
              </Carousel>
            </Fade>
          </Col>
          <Col sm={3} xs={6} style={{ margin: 0, padding: 0 }}>
            <Fade bottom>
              <Carousel dots={false} autoplay={true} autoplaySpeed={7500}>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img6.png")}
                  />
                </div>
                <div>
                  <img
                    className="Home-image1"
                    src={require("../../../assets/activities/img7.jpeg")}
                  />
                </div>
              </Carousel>
            </Fade>
          </Col>
          <img
            src={ticket}
            alt="ticket"
            className="Section1_ticket"
            onClick={() => navigation("/booking")}
          />
        </Row>
      </Container>
    </div>
  );
}

export default Section1;
