import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import HelmetSeo from "../../components/helmetSeo";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const advantages = [
    {
      id: 1,
      head: "Experience the Thrill of Adventure",
      caption: ` Get ready to conquer heart-pounding rides, challenging obstacle courses, 
      and epic adventures designed just for you! Experience non-stop excitement and create
       unforgettable memories at [Park Name]. Grab your friends and let the fun begin!`,
    },
    {
      id: 2,
      head: "Unique Children Attractions",
      caption: ` At our adventure park, we take pride in offering exceptional
            children's attractions that captivate young visitors and ignite
            their imagination. Our carefully designed rides and interactive
            experiences prioritize safety while providing endless fun and
            excitement for children of all ages.`,
    },
    {
      id: 3,
      head: "Easy Accessible",
      caption: `Our park is located in the picturesque Lakkidi, Wayanad, offering easy access from major cities.
       Just 56 km from Calicut City, 20 km from Kalpetta,
     ,127 km from Ooty and 140 km from Mysore,290 km from Bangalore, your adventure is closer than you think!`,
    },
    {
      id: 4,
      head: "Ride with Confidence and Peace of Mind",
      caption: ` At our park, your safety is our top priority.
       We take every measure to ensure our rides are as safe as possible. 
       From regular maintenance checks to thorough testing, we strive to provide a secure and enjoyable experience for all our guests.
        You can relax and enjoy the thrill, knowing that our expert team is always working behind the scenes to keep you safe.`,
    },
    {
      id: 5,
      head: "Joyful Rides for the Whole Family",
      caption: `Your family will be thrilled with our selection of rides designed for all ages and excitement levels.
       Share laughter and create
       lasting memories together as you enjoy the park's fun and safe attractions`,
    },
    {
      id: 6,
      head: "Dedicated to Your Comfort",
      caption: `At Teenzania, our team is fully committed to ensuring your visit is as comfortable as possible. 
      We prioritize your enjoyment and satisfaction, providing attentive service and maintaining a welcoming environment.
       Come experience the care and hospitality that makes our park a top destination for adventurers of all ages`,
    },
  ];
  return (
    <>
      <Header />
      <HelmetSeo
        title="About Us"
        description={`Teenzania in the stunning Lakkidi, Wayanad, the TEENZANIA  Amusement and Adventure Project promises a Unique blend of Leisure and Adventure.`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/AboutusMobile.png"
        canonicalurl="https://www.teenzania.com/aboutus"
        imageAlt="About Us"
      />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> About Us
              </div>
              <h3 className="heading_h3">About Us</h3>
              <br />
              <Col md={8} sm={12}>
                <p>
                  We located in the stunning Lakkidi in Wayanad, the
                  <strong> TEENZANIA </strong>
                  Amusement and Adventure Project promises a unique blend of
                  leisure and adventure. Set to become Wayanad's highlight, it
                  invites visitors worldwide to experience its unmatched natural
                  beauty and thrilling adventures. Lakkidi, known as Wayanad's
                  gem, showcases nature's everlasting charm, offering a sensory
                  feast regardless of the season, making every visit memorable.
                </p>
                <p>
                  {" "}
                  Adventure Park is a thrilling outdoor destination perfect for
                  teenagers and anyone looking for fun and adventure. With
                  everything from high-speed zip lines and challenging obstacle
                  courses to climbing towers, boulder walls, and mountain biking
                  trails, our park has something for everyone. Plus, our serene
                  lake offers kayaking and paddle boarding for a refreshing
                  escape. It's a place where boredom doesn't exist, and
                  adventure awaits at every turn.
                </p>
              </Col>

              <Col md={12} className="mt-5">
                <div className="About_text4">Our Advantages </div>
              </Col>
              <Row className="g-2 my-5">
                {advantages?.map((item: any) => (
                  <Col md={6} xs={12} className="mb-3">
                    <IoCheckmarkDoneCircle color="188a8a" size={"40px"} />
                    <div className="About_text5">{item?.head}</div>
                    <div className="About_text3 mt-2"> {item?.caption}</div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
