import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import StaffDataTable from "./dataTable";
import { API } from "../../config/Api";
import { DELETE, POST } from "../../utils/apiRequest";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import StaffFormModal from "./form";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import HorizontalLineLoader from "../components/loader";
import CardFormModal from "./form";
import CardDataTable from "./dataTable";
import "./styles.scss";
import { IoReload } from "react-icons/io5";

const CardScreen = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(30);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getCard();
  }, [page, take]);

  const getCard = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        expiryDate: form.getFieldValue("exdate"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.CARDS_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const deleteCard = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.CARDS_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Card Deleted",
        });
        getCard();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Card not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Card not deleted",
      });
    }
  };

  const editCard = (card: any) => {
    try {
      setSelectedItem(card);
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  const onView = (val: any) => {
    navigate(`/Auth/CardsDetails/${val?.id}`, { state: { val } });
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Cards"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getCard}>
          <div className="jobScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            <Form.Item name={"status"} noStyle>
              <Select allowClear style={{ minWidth: 100 }} placeholder="Status">
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>Inactive</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={"exdate"} noStyle>
              <DatePicker
                placeholder="Expried Date"
                style={{ minWidth: 100 }}
              />
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => editCard({})} type="primary">
                Create +
              </Button>
            </Form.Item>

            <Button className="refreshBtn" onClick={() => getCard()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <CardDataTable
              data={data}
              onEdit={(item: any) => editCard(item)}
              onView={(item: any) => onView(item)}
              delete={(id: any) => deleteCard(id)}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading2(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          )}
        </Card>
      </div>
      {modalVisible ? (
        <CardFormModal
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={() => {
            setIsLoading2(true);
            editCard({});
            getCard();
          }}
        />
      ) : null}
    </div>
  );
};

export default CardScreen;
