import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import { IoIosArrowForward, IoIosContact } from "react-icons/io";
import { Button, Form, Input, Modal, Select, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { IoCall, IoLocationOutline } from "react-icons/io5";
import { LuClock1 } from "react-icons/lu";
import { RiWhatsappFill } from "react-icons/ri";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import tick from "../../assets/images/tickmarkGif.gif";
import HelmetSeo from "../../components/helmetSeo";

function ContactScreen() {
  const iframeHTML = `<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3909.452524314732!2d76.01857197505284!3d11.519361088678226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDMxJzA5LjciTiA3NsKwMDEnMTYuMSJF!5e0!3m2!1sen!2sin!4v1713760018911!5m2!1sen!2sin" width="100%" height="300" style="border:0;border-radius:10px;"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { Option } = Select;

  const handleClick = () => {
    window.open(`https://wa.me/${+917001800600}`);
  };
  const Call = () => {
    window.location.href = "tel:+917001800600";
  };
  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let contact = {
        subject: val?.subject,
        type: val?.type,
        name: val?.name,
        email: val?.email,
        phone: val?.phone,
        message: val?.message,
      };
      let url = API.CONTACT_US;
      const data: any = await POST(url, contact);
      if (data) {
        setIsModalOpen(true);
        setIsLoading(false);
        form.resetFields();
      } else {
        message.error("Failed to Submit Request");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        width={500}
        centered
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
      >
        <img
          src={tick}
          style={{ objectFit: "contain" }}
          width="100%"
          alt="done"
        />
        <h2 className="text-center">Succesfully Submitted</h2>
        <h6 className="text-center">We will be in touch with you shortly.</h6>
      </Modal>
      <HelmetSeo
        title="Contact Us"
        description={`Raise a issue, Ask  a question,give us a feedback, or simply want to say hello, we're here to assist you every step of the way.`}
        image="https://as1.ftcdn.net/v2/jpg/02/04/95/58/1000_F_204955866_V7qsvrM9equd404KRYBolNLCLl1Baxzv.jpg"
        canonicalurl="https://www.teenzania.com/contact-us"
        imageAlt="Contact Us"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <div className="heading_bredcume">
              Home <IoIosArrowForward /> Support <IoIosArrowForward /> Contact
              Us
            </div>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <h3 className="heading_h3">Contact Us</h3>

              <Row>
                <Col sm={7} xs={12}>
                  <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />
                  <div className="ContactUs_Box1">
                    {" "}
                    <div className="round-icon-container">
                      <LuClock1 size={30} color="#575c5e" />
                    </div>{" "}
                    <div>
                      <div className="ContactUs_text1">Opening Hours</div>
                      <div className="ContactUs_text2">9:30 am - 6:00 pm</div>
                    </div>
                  </div>
                  <div className="ContactUs_Box1">
                    <div className="round-icon-container">
                      <IoLocationOutline size={30} color="#575c5e" />
                    </div>
                    <div>
                      <div className="ContactUs_text1">Address</div>
                      <div className="ContactUs_text2">
                        Opposite Chain Tree, 8/494, Kozhikode Vythiri Mysore
                        Road (NH 766),
                        <br /> Lakkidi, Vythiri Wayanad Kerala 673576
                      </div>
                    </div>
                  </div>
                  <div className="ContactUs_Box1">
                    <div className="round-icon-container">
                      <IoIosContact size={30} color="#575c5e" />
                    </div>
                    <div>
                      <div className="ContactUs_text1">Contact</div>
                      <div className="ContactUs_text2  my-2" onClick={Call}>
                        <IoCall size={28} color="#3f8ad4" />
                        &nbsp;+917001800600
                      </div>
                      <div
                        className="ContactUs_text2  my-2"
                        onClick={handleClick}
                      >
                        <RiWhatsappFill size={30} color="#129922" />
                        &nbsp;+917001800600
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={5} xs={12}>
                  <Form form={form} onFinish={onFinish}>
                    <div className="ContactUs_text3">Subject</div>
                    <FormItem name="subject">
                      <Input placeholder="Subject" type="text" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Select</div>
                    <FormItem
                      name="type"
                      rules={[
                        { required: true, message: "Please select an Option" },
                      ]}
                    >
                      <Select size="large" placeholder="Select an Option">
                        <Option value="booking">Booking</Option>
                        <Option value="ticket">Tickets</Option>
                        <Option value="services">Services</Option>
                        <Option value="others">Others</Option>
                      </Select>
                    </FormItem>
                    <div className="ContactUs_text3">Name</div>
                    <FormItem name="name">
                      <Input type="text" placeholder="Name" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Email</div>
                    <FormItem
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Email" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Phone</div>
                    <FormItem name="phone">
                      <Input type="number" placeholder="Phone" size="large" />
                    </FormItem>
                    <div className="ContactUs_text3">Message</div>
                    <FormItem name="message">
                      <TextArea
                        rows={4}
                        placeholder="Write anything here..."
                        size="large"
                      />
                    </FormItem>
                    <Col sm={{ offset: 6, span: 6 }} xs={12}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className="contactSubmit_Button"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
}
export default ContactScreen;
