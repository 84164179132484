import { Card } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { Table } from "react-bootstrap";
import { GET } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import NoData from "../components/noData";

const BookingDetails = () => {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.BOOKING_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        const arr: any = [];
        arr.push(response.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PageHeaders title={"Booking Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Booking ID</th>
                  <th className="table-th">User Name</th>
                  <th className="table-th">E-mail</th>
                  <th className="table-th">Phone No.</th>
                  <th className="table-th">Payment Method</th>
                  <th className="table-th">Count</th>
                  <th className="table-th">Paid Amount</th>
                  <th className="table-th">Grand Total</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.bookingId}</td>
                    <td>{item?.userName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.paymentMethod}</td>
                    <td>{item?.count}</td>
                    <td>{item?.paidAmount}</td>
                    <td>{item?.grandTotal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
        <br />
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {data[0]?.ticketInfo?.activities ? (
                <>
                  <div className="tableHeading">Ticket Info</div>
                  <Table bordered hover>
                    <thead>
                      <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                        <th className="table-th">ID</th>
                        <th className="table-th">Activity</th>
                        <th className="table-th">Pricing</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[0]?.ticketInfo?.activities?.map(
                        (act: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td>{act?.id}</td>
                              <td>{act?.name}</td>
                              <td>
                                {act?.price?.map((prc: any) => (
                                  <div>
                                    {prc.age}-{prc?.price}({prc?.count})
                                  </div>
                                ))}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>{" "}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default BookingDetails;
