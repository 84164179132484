import { useState } from "react";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { addTicket } from "../../../redux/slice/activitySlice";
import "../styles.scss";
function ItemCard({ activity }: any) {
  const dispatch = useDispatch();
  const { activities } = useSelector((state: any) => state.activity);

  const selectItem = (item: any, price: any, type: any) => {
    try {
      let obj = {
        id: item?.id,
        name: item?.name,
        price: price,
        type: type,
      };
      dispatch(addTicket(obj));
    } catch (err) {
      console.log("err = = = >", err);
    }
  };
  const checkItem = () => {
    try {
      var arr = activities;
      var findActivity = arr.findIndex(
        (selectedActivity: any) => selectedActivity?.id == activity?.id
      );
      if (findActivity >= 0) {
        return arr[findActivity]?.price;
      } else {
        return [];
      }
    } catch (err) {
      console.log("err = = = >", err);
    }
  };
  const showConunt = (item: any) => {
    try {
      let act = checkItem();
      if (act?.length) {
        var findItem = act?.filter((i: any) => i?.age == item?.age);
        if (findItem?.length) {
          return findItem[0]?.count;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (err) {
      console.log("err", err);
      return 0;
    }
  };

  return (
    <div className="itemCardHead">
      <div>
        <img className="cardImage" src={activity.image} alt={activity.name} />
      </div>
      <div className="cardContent">
        <div className="nameTxt">{activity?.name}</div>
        <div style={{ borderBottom: "1px solid white" }}></div>
        {activity.pricing.map((price: any, index: any) => (
          <>
            <div className="pricingHead" key={index}>
              <div>
                <div className="ageTxt">{price.age}:</div>
                <div className="pricingTxt">Rs. {price.price}</div>
              </div>
              <div className="countBtnHead">
                <CiCircleMinus
                  size={30}
                  color="white"
                  onClick={() => selectItem(activity, price, "remove")}
                />
                <div className="count">{showConunt(price)}</div>
                <CiCirclePlus
                  size={30}
                  color="white"
                  onClick={() => selectItem(activity, price, "add")}
                />
              </div>
            </div>
            {index < activity.pricing.length - 1 && (
              <div className="line"></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default ItemCard;
