import { useState } from "react";
import "./styles.scss";

import { Button, Form, Input, notification } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../config/logo-color.svg";
import Footer from "../../components/footer";

import { API } from "../../config/Api";
import { login } from "../../redux/slice/userSlice";
import { POST } from "../../utils/apiRequest";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        email: values.user,
        password: values.password,
        type: "admin",
      };
      let url = API.ADMIN_LOGIN;
      let { data, status, message }: any = await POST(url, obj);
      if (status) {
        setLoading(false);
        notification.success({ message });
        dispatch(login({ ...data }));
        navigate("/Auth");
      } else {
        setLoading(false);
        notification.error({ message });
      }
    } catch (error) {
      setLoading(false);
      console.error("Login failed:", error);
    }
  };

  return (
    <div>
      <Container>
        <div className="loginScreen">
          <Row>
            <Col sm={4}></Col>
            <Col sm={4}>
              <div className="loginScreen-Box3">
                <img src={Logo} alt="" />
              </div>
              <br />
              <div className="loginScreen-txt1">Login and Continue</div>
              <Form onFinish={onFinish}>
                <label className="formLabel">Email or Username</label>
                <Form.Item
                  name={"user"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input size="large" type="email" />
                </Form.Item>
                <label className="formLabel">Password</label>
                <Form.Item
                  name={"password"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <br />
                <Button
                  block
                  size="large"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  Sign In
                </Button>
              </Form>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default LoginScreen;
