import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";

import About from "./component/about";
import ActivityCard from "./component/activityCard";
import ClimbZip from "./component/climbZip/index";

import Section1 from "./component/section1";
import HelmetSeo from "../../components/helmetSeo";

function HomeScreen() {
  return (
    <main>
      <HelmetSeo
        title="Adventure Park in Wayanad"
        description="Teenzania | Adventure Park in Wayanad. Embrace the thrill, conquer the challenges, and let every moment be an adventure at Wayanad's enchanting Adventure Park."
        canonicalurl="https://www.teenzania.com"
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/logo.png"
        imageAlt="Teenzania"
      />
      <Header type={1} />
      <Section1 />
      <About />
      <ActivityCard />
      <ClimbZip />
      <Footer />
    </main>
  );
}

export default HomeScreen;
