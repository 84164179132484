import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import PageHeaders from "../../components/pageHeaders";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import HorizontalLineLoader from "../components/loader";
import Loading from "../../components/loading";
import TransactionDataTable from "./dataTable";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import ViewTransModal from "./viewModal";
import { IoReload } from "react-icons/io5";

const Transactions = () => {
  const [form] = useForm();
  const { Option } = Select;
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(30);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getTransactions();
    getAllStaffs();
  }, [page, take]);

  const getAllStaffs = async () => {
    try {
      setIsLoading(true);
      let api = API.STAFFS_PICKER;
      const users: any = await GET(api, null);
      if (users?.status) {
        setAllUsers(users?.data);
        setIsLoading(false);
      } else {
        console.log("");
        setAllUsers([]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getTransactions = async () => {
    try {
      setIsLoading(true);
      const api = API.TRANSACTIONS_LIST;
      let obj = {
        type: form.getFieldValue("type"),
        paymentMethod: form.getFieldValue("method"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        user: Number(form.getFieldValue("user")),
        page: page,
        take: take,
      };
      const response: any = await POST(api, obj);
      if (response?.status) {
        setTransactions(response.data);
        setMeta(response.meta);
        setIsLoading(false);
        setIsLoading2(false);
      } else {
        Notifications["error"]({
          message: "No data found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setIsLoading2(false);
      Notifications["error"]({
        message: "Oops !",
        description: "Something went wrong with the server",
      });
    }
  };

  const onView = (val: any) => {
    try {
      setSelectedItem(val);
      setModalVisible(!modalVisible);
      console.log("hello");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders
        title={"Transactions"}
        backButton={true}
        loading={isLoading2}
      >
        <Form form={form} onValuesChange={getTransactions}>
          <div className="jobScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>

            <Form.Item name={"type"} noStyle>
              <Select allowClear style={{ minWidth: 100 }} placeholder="Type">
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value="ticket">Ticket</Select.Option>
                <Select.Option value="card">Card</Select.Option>
                <Select.Option value="refund">Refund</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name={"user"} noStyle>
              <Select allowClear style={{ minWidth: 100 }} placeholder="Staff">
                {allUsers?.map((user: any) => {
                  return (
                    <Select.Option value={user?.id}>{user?.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name={"method"} noStyle>
              <Select
                allowClear
                style={{ minWidth: 100 }}
                placeholder="Payment Method"
              >
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value="upi">UPI</Select.Option>
                <Select.Option value="cash">Cash</Select.Option>
                <Select.Option value="card">Card</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>

            <Button className="refreshBtn" onClick={() => getTransactions()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}

      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <TransactionDataTable
              data={transactions}
              onView={(item: any) => onView(item)}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading2(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          )}
        </Card>
      </div>

      {modalVisible ? (
        <ViewTransModal
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
        />
      ) : null}
    </div>
  );
};

export default Transactions;
