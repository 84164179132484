import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { IoIosArrowForward } from "react-icons/io";
import HelmetSeo from "../../components/helmetSeo";

const Delivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <HelmetSeo
        title="Shipping and Delivery"
        description={` For International buyers, orders are shipped and delivered
        through registered international courier companies and/or
        International speed post only.`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/teenzaniLogo.png"
        canonicalurl="https://www.teenzania.com/delivery"
        imageAlt="Shipping and Delivery"
      />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> Legal Terms <IoIosArrowForward />{" "}
                Shipping and Delivery
              </div>
              <h3 className="heading_h3">Shipping and Delivery</h3>
              <br />
              <Col md={8} sm={12}>
                <p>
                  For International buyers, orders are shipped and delivered
                  through registered international courier companies and/or
                  International speed post only. For domestic buyers, orders are
                  shipped through registered domestic courier companies and /or
                  speed post only. Orders are shipped within 1-2 days or as per
                  the delivery date agreed at the time of order confirmation and
                  delivering of the shipment subject to Courier Company / post
                  office norms. <strong>TEENZANIA</strong>
                  is not liable for any delay in delivery by the courier company
                  / postal authorities and only guarantees to hand over the
                  consignment to the courier company or postal authorities
                  within 1-2 days rom the date of the order and payment or as
                  per the delivery date agreed at the time of order
                  confirmation. Delivery of all orders will be to the address
                  provided by the buyer. Delivery of our services will be
                  confirmed on your mail ID as specified during registration.
                  For any issues in utilizing our services you may contact our
                  helpdesk on <a href="tel:+918573900900">8573900900</a> or
                  &nbsp;
                  <a href="mailto: teenzaniaadventurepark@gmail.com">
                    teenzaniaadventurepark@gmail.com
                  </a>
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Delivery;
