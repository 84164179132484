const ThemeObj = {
  token: {
    // colorPrimary: "#08493e",
    colorPrimary: "#018989",
    fontFamily: "DMSans-Medium",
    borderRadius: 4,
  },
};

export default ThemeObj;
