import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  notification,
} from "antd";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import { useSelector } from "react-redux";

function TicketFormModal(props: any) {
  const [form] = Form.useForm();
  const user: any = useSelector((val: any) => val.User.user);
  const Job = useSelector((state: any) => state.job);
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState<any>(0);
  const [Notifications, contextHolder] = notification.useNotification();

  useEffect(() => {
    fetchStaffJobDet();
  }, []);

  const fetchStaffJobDet = async () => {
    try {
      let api = API.STAFFS_DETAILS + user.id;
      const response: any = await GET(api);
      if (response?.status) {
        setJobId(response?.data?.job?.id);
      } else {
        setJobId(null);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const ticket = async (val: any) => {
    try {
      const activitiesPayload = val?.main?.map((activity: any) => ({
        description:
          props?.activity?.find((act: any) => act?.id === activity?.activity)
            ?.description || "",
        id: activity?.activity,
        name:
          props?.activity?.find((act: any) => act?.id === activity?.activity)
            ?.name || "",
        price: activity?.sub
          .filter((subItem: any) => subItem.count > 0)
          .map((subItem: any) => ({
            age: subItem?.age,
            amount: subItem?.total,
            count: subItem?.count,
            price: subItem?.price,
          })),
      }));
      setIsLoading(true);
      let totalCount = 0;
      activitiesPayload.forEach((activity: any) => {
        activity.price.forEach((price: any) => {
          totalCount += price.count;
        });
      });
      let payload = {
        from: "web",
        bookingId: moment().format("YYYYMMDDHHmmssSSS"),
        activities: activitiesPayload,
        staffId: Number(user?.id),
        jobId: Number(jobId),
        totalPrice: val?.totalAmount,
        start: moment().toDate(),
        end: moment().add(30, "minutes").toDate(),
        paymentStatus: true,
        transId: "",
        totalAmount: val?.totalAmount,
        grandTotal: Number(val?.grandTotal),
        paidAmount: Number(val?.grandTotal),
        tax: val?.tax,
        taxAmt: val?.tax,
        paymentMethod: val?.payment_mehtod,
        discount: val?.discount ?? 0,
        count: totalCount,
        ticketInfo: activitiesPayload,
        createdBy: "admin",
      };
      let url = API.TICKET_ADD;
      const response: any = await POST(url, payload);
      if (response?.status) {
        props?.onSuccess(response?.data?.ticketNumber);
        notification.success({
          message: `Success`,
          description: `Ticket generated`,
        });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Faild",
          description: "failed to generate",
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    try {
      if (
        changedValues?.main ||
        changedValues?.tax ||
        changedValues?.discount
      ) {
        const updatedValues = allValues?.main?.map((mainItem: any) => {
          const foundData = props?.activity?.find(
            (item: any) => item?.id === mainItem?.activity
          );
          if (foundData) {
            return {
              ...mainItem,
              sub: foundData?.pricing?.map((pricingItem: any) => {
                const correspondingSubItem = mainItem?.sub?.find(
                  (subItem: any) => subItem?.age === pricingItem?.age
                );
                return {
                  age: pricingItem?.age,
                  price: pricingItem?.price,
                  count: correspondingSubItem?.count || 0,
                  total:
                    (correspondingSubItem?.count || 0) * pricingItem?.price,
                };
              }),
            };
          }
          return mainItem;
        });

        let totalAmount = updatedValues?.reduce(
          (accumulator: number, currentValue: any) =>
            accumulator +
            currentValue?.sub?.reduce(
              (subAccumulator: number, subCurrentValue: any) =>
                subAccumulator + subCurrentValue?.total,
              0
            ),
          0
        );

        var tax = Number(allValues?.tax) || 0;
        var discount = Number(allValues?.discount || 0);
        var grandTotal = totalAmount + tax - discount;

        const valuesToSet: any = {
          main: updatedValues,
          tax,
          paidAmount: changedValues?.paidAmount ?? grandTotal,
        };

        if (!isNaN(totalAmount)) {
          valuesToSet.totalAmount = totalAmount;
        }

        if (!isNaN(grandTotal)) {
          valuesToSet.grandTotal = grandTotal;
        }

        form.setFieldsValue(valuesToSet);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={props?.open}
      title={<div style={{ fontSize: 25 }}>Create Ticket</div>}
      onCancel={props?.onCancel}
      footer={false}
      width={600}
    >
      {contextHolder}
      <Form
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{ main: [{}] }}
        onFinish={ticket}
      >
        <Table size="small" bordered className="ticketScreen-table">
          <tbody>
            <Form.List name="main">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <tr>
                        <td
                          style={{ width: "100%", backgroundColor: "#edf5f0" }}
                        >
                          <Form.Item
                            noStyle
                            name={[name, "activity"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing activity",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%", height: 30 }}
                              placeholder="Select Activities"
                              bordered={false}
                              size="large"
                            >
                              {props?.activity.map((option: any) => (
                                <Select.Option
                                  key={option?.id}
                                  value={option?.id}
                                >
                                  {option?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </td>
                        <td
                          style={{ backgroundColor: "#edf5f0" }}
                          onClick={() =>
                            fields?.length > 1 ? remove(name) : null
                          }
                        >
                          <IoMdRemove size={30} color="red" />
                        </td>
                      </tr>
                      <Form.List name={[name, "sub"]}>
                        {(subFields) => (
                          <>
                            {subFields?.map((subField, index) => (
                              <tr style={{ borderBottom: "1px solid grey" }}>
                                <td colSpan={2} style={{ padding: 0 }}>
                                  <Table bordered style={{ marginBottom: 0 }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: 0 }}>
                                          <Form.Item
                                            noStyle
                                            {...subField}
                                            name={[subField?.name, "age"]}
                                          >
                                            <Input bordered={false} />
                                          </Form.Item>
                                        </td>
                                        <td style={{ padding: 0 }}>
                                          <Form.Item
                                            noStyle
                                            {...subField}
                                            name={[subField?.name, "price"]}
                                          >
                                            <Input bordered={false} />
                                          </Form.Item>
                                        </td>
                                        <td style={{ padding: 5 }}>
                                          <Form.Item
                                            noStyle
                                            {...subField}
                                            name={[subField?.name, "count"]}
                                          >
                                            <InputNumber
                                              placeholder="Count"
                                              min={0}
                                              defaultValue={0}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td style={{ width: 46 }}></td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </>
                  ))}
                  <tr>
                    <td></td>
                    <td onClick={() => add()}>
                      <IoMdAdd size={30} color="green" />
                    </td>
                  </tr>
                </>
              )}
            </Form.List>
          </tbody>
        </Table>
        <Table size="small" bordered className="ticketScreen-table2">
          <tbody>
            <tr>
              <td>Total</td>
              <td style={{ padding: 0 }}>
                <Form.Item noStyle name={"totalAmount"}>
                  <Input bordered={false} />
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>Tax</td>
              <td style={{ padding: 0 }}>
                <Form.Item noStyle name={"tax"}>
                  <Input bordered={false} />
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>Grand Total</td>
              <td style={{ padding: 0 }}>
                <Form.Item noStyle name={"grandTotal"}>
                  <Input bordered={false} />
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>Select Payment Method</td>
              <td style={{ padding: 0 }}>
                <Form.Item noStyle name={"payment_mehtod"}>
                  <Select
                    placeholder={"Select Method"}
                    style={{ width: "100%", marginTop: 2 }}
                    bordered={false}
                  >
                    <Select.Option value={"cash"}>Cash</Select.Option>
                    <Select.Option value={"upi"}>UPI</Select.Option>
                  </Select>
                </Form.Item>
              </td>
            </tr>
          </tbody>
        </Table>
        <Row>
          <Col sm={6}>
            <Button block danger size="large" onClick={props?.onCancel}>
              Cancel
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              htmlType="submit"
              block
              type="primary"
              size="large"
              loading={isLoading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default TicketFormModal;
