import { message } from "antd";
import { API } from "../config/Api";
import { Store } from "../redux/store";
import { AwsImageUploadResponseType } from "../shared/types/types";
import S3 from "../config/S3";
const S3FileUpload = require("react-s3").default;
window.Buffer = window.Buffer || require("buffer").Buffer;

const GET = async (url: any, params?: any, pageOption?: any) => {
  const User: any = Store.getState().User;
  const token = User.user.token;
  const apiUrl = params
    ? `${API.BASE_URL}${url}/${params}`
    : `${API.BASE_URL}${url}`;
  const urlWithPage = pageOption
    ? `${apiUrl}?page=${pageOption}&take=20&order=DESC`
    : apiUrl;
  return new Promise((resolve, reject) => {
    fetch(urlWithPage, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const DELETE = async (url: any, params?: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  const apiURL = params
    ? `${API.BASE_URL + url}/${params}`
    : `${API.BASE_URL + url}`;

  return new Promise((resolve, reject) => {
    fetch(apiURL, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const POST = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const PUT = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const COMPRESS_IMAGE = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(
          `${API.BASE_URL}${API.FILE_COMPRESS_UPLOAD}`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (response.ok) {
          const jsonResponse: AwsImageUploadResponseType =
            await response.json();
          const obj = {
            ...jsonResponse,
            url: jsonResponse.Location,
            status: true,
          };
          resolve(obj);
        } else {
          let obj = {
            status: false,
            url: null,
          };
          resolve(obj);
        }
      } else {
        resolve("no file selected");
      }
    } catch (err) {
      resolve(err);
    }
  });
};

const FILE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file && file.name) {
        message.loading({
          type: "loading",
          content: "Action in progress..",
          duration: 1,
        });
        const response = await S3FileUpload.uploadFile(file, S3);
        if (response && response.location) {
          resolve(response.location);
        } else {
          reject("Failed");
        }
      } else {
        resolve(null);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export { DELETE, GET, POST, PUT,COMPRESS_IMAGE,FILE_UPLOAD };
