import { createSlice } from "@reduxjs/toolkit";

const addTickets = (state: any, item: any) => {
  try {
    var arr = state?.activities;
    var findActivity = arr.findIndex(
      (activty: any) => activty?.id === item?.id
    );
    if (findActivity >= 0) {
      var ItemObj = arr[findActivity];
      if (ItemObj?.price?.length) {
        var findPrice = ItemObj?.price.findIndex(
          (prce: any) => prce?.age === item?.price?.age
        );
        if (findPrice >= 0) {
          // Update Pricing
          var priceItem = ItemObj.price[findPrice];
          if (item?.type === "add") {
            priceItem.count = Number(priceItem.count) + 1;
            ItemObj.price[findPrice] = priceItem;
            arr[findActivity] = ItemObj;
          } else {
            let count = Number(priceItem.count) - 1;
            if (count > 0) {
              priceItem.count = Number(priceItem.count) - 1;
              ItemObj.price[findPrice] = priceItem;
              arr[findActivity] = ItemObj;
            } else {
              ItemObj.price.splice(findPrice, 1);
              if (ItemObj?.price?.length > 0) {
                arr[findActivity] = ItemObj;
              } else {
                arr.splice(findActivity, 1);
              }
            }
          }
          return arr;
        } else {
          // New Price add In activity
          if (item?.type === "add") {
            let price = {
              age: item?.price?.age,
              price: item?.price?.price,
              count: 1,
            };
            ItemObj.price.push(price);
            arr[findActivity] = ItemObj;
          }
        }
      }
      return arr;
    } else {
      //New Activity Insert
      if (item?.type === "add") {
        let price = {
          age: item?.price?.age,
          price: item?.price?.price,
          count: 1,
        };
        let obj = {
          id: item?.id,
          name: item?.name,
          price: [price],
        };
        arr.push(obj);
      }
      return arr;
    }
  } catch (err) {
    return [];
  }
};

const activitySlice: any = createSlice({
  name: "activity",
  initialState: {
    activities: [],
  },

  reducers: {
    addTicket: (state, action) => {
      state.activities = addTickets(state, action.payload);
    },

    removeAllTicket: (state: any) => {
      state.activities = [];
    },
    removeSelectedTicket: (state: any, action: any) => {
      const activityIdToRemove = action.payload;
      state.activities = state.activities.filter(
        (activity: any) => activity.id !== activityIdToRemove
      );
    },
  },
});

export const { addTicket, removeAllTicket, removeSelectedTicket }: any =
  activitySlice.actions;
export default activitySlice.reducer;
