import { useNavigate } from "react-router-dom";
import getTicket from "../../assets/images/ticketbtn.svg";
import logo from "../../config/logo-white.svg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoCallOutline, IoMailOpenOutline } from "react-icons/io5";
const Fade = require("react-reveal/Fade");
const CustomDrawer = ({ open, onClose, options }: any) => {
  const navigation = useNavigate();
  return (
    <div className="CustomDrawer-blurry">
      <div className="CustomDrawer-Header">
        <div>
          <Fade top>
            <img
              src={logo}
              width="150px"
              onClick={() => navigation("/")}
              alt="logo"
            />
          </Fade>
        </div>
        <div className="CustomDrawer-close">
          <IoMdCloseCircleOutline onClick={() => onClose()} />
        </div>
      </div>
      <div className="CustomDrawer-container">
        {options?.map((item: any, index: number) => (
          <Fade key={item?.id} top delay={index * 30}>
            <div
              className="CustomDrawer-item"
              onClick={() => navigation(item?.path)}
            >
              {item?.name}
            </div>
          </Fade>
        ))}
        <hr />
        <div className="CustomDrawer-ticketBox">
          <Fade top delay={600}>
            <img
              src={getTicket}
              alt="getTicket"
              width="150px"
              onClick={() => navigation("/booking")}
            />
          </Fade>
        </div>
        <hr />
        <div className="Drawer-text1">Contact Us</div>
        <div className="my-2">
          <a href="tel:+917001800600" className="Drawer-text2">
            <IoCallOutline size={20} color="#f9da7f" />
            &nbsp;+917001800600
          </a>
        </div>
        <div className="my-2">
          <a href="mailto: contact@teenzania.com" className="Drawer-text2">
            <IoMailOpenOutline size={20} color="#f9da7f" />{" "}
            &nbsp;contact@teenzania.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default CustomDrawer;
