import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";

function FeedbackDataTable(props: any) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{id}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: " Comment",
      dataIndex: "comment",
      key: " comment",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
        title: "Created At",
        dataIndex: "createdAt",
        render: (record: any) => (
          <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
        ),
      },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: " phone",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    
   
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Jobs`}
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default FeedbackDataTable;
