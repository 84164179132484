import React, { useEffect, useState } from "react";
import TicketFormModal from "./form";
import { API } from "../../config/Api";
import { CiSearch } from "react-icons/ci";
import ActivitesDataTable from "./dataTable";
import { useForm } from "antd/es/form/Form";
import { POST } from "../../utils/apiRequest";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { Button, Card, DatePicker, Form, Input, notification } from "antd";
import "./styles.scss";
import TicketModal from "./ticketModal";
import { IoReload } from "react-icons/io5";

function TicketData() {
  const [form] = useForm();
  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ticketData, setTicketData] = useState<any>([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [modalVisible, setModalVisible] = useState(false);
  const [onViewModal, setOnViewModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");

  const [page, setPage] = useState(1);
  const [take, setTake] = useState<any>(30);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    loadData();
  }, [page, take]);

  useEffect(() => {
    getActvites();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        query: form.getFieldValue("query"),
        date: form.getFieldValue("date"),
        page: page,
        take: take,
      };
      console.log(obj);
      let url = API.TICKET_LIST;
      const tickets: any = await POST(url, obj);
      if (tickets?.status) {
        setTicketData(tickets?.data);
        setMeta(tickets?.meta);
        setIsLoading(false);
      } else {
        setActivity([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
      console.log(err);
    }
  };

  const getActvites = async () => {
    try {
      let obj = {
        page: 1,
        take: null,
      };
      const response: any = await POST(API.ACTIVITY_LIST, obj);
      if (response?.status) {
        setActivity(response?.data);
      } else {
        setActivity([]);
      }
    } catch (err) {
      console.log("list-activity-err", err);
    }
  };

  const editUser = (user: any) => {
    try {
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  const pagination = (page: number, take: number) => {
    try {
      window.scrollTo(0, 0);
      setPage(page);
      setTake(take);
    } catch (err) {
      console.log(err);
    }
  };

  const onView = (id: any) => {
    console.log(typeof id);
    try {
      // navigate(`/Auth/ticket/print/${id}`);
      setOnViewModal(true);
      setTicketNumber(id);
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = (page: number, take: any) => {
    try {
      setPage(page);
      setTake(take);
    } catch (err) {
      console.log(err);
    }
  };
  // const onView = (val: any) => {
  //   navigate(`/Auth/ticket/view/${val}`);
  // };
  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Tickets"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={loadData}>
          <div className="ticketScreen-PageHeaderBox">
            <Form.Item name={"date"} noStyle>
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name={"query"} noStyle>
              <Input
                allowClear
                placeholder="Search"
                suffix={<CiSearch size={16} color="#000" />}
                onChange={() => {
                  setPage(1);
                  setTake(10);
                }}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => editUser({})} type="primary">
                Create +
              </Button>
            </Form.Item>

            <Button className="refreshBtn" onClick={() => loadData()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <ActivitesDataTable
              meta={meta}
              data={ticketData}
              onEdit={(item: any) => editUser(item)}
              onPageChange={(page: number, take: number) => {
                pagination(page, take);
              }}
              onView={onView}
            />
          )}
        </Card>
      </div>
      {modalVisible ? (
        <TicketFormModal
          activity={activity}
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={(id: any) => {
            loadData();
            // navigate(`/Auth/ticket/print/${id}`);
            setTicketNumber(id);
            setModalVisible(false);
            setOnViewModal(true);
          }}
        />
      ) : null}
      {onViewModal ? (
        <TicketModal
          view={onViewModal}
          ticketNo={ticketNumber}
          onClose={() => setOnViewModal(false)}
          onRefresh={() => loadData()}
        />
      ) : null}
    </div>
  );
}
export default TicketData;
