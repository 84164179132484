import { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import { Template } from "./components/Template";
import { TicketTemplateHtml } from "../ticketTemplate/components/ticketTemplate";
import "./styles.scss";
import { GET } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import jsPDF from "jspdf";
import { Button } from "antd";
import { IoMdCloudDownload } from "react-icons/io";

function TicketInvoice(props: any) {
  const params: any = useParams();
  const [html, setHtml] = useState<any>();
  const [btn, setBtn] = useState(false);
  const [ticketInfo, setTicketInfo] = useState<any>({});
  const pdfTemp = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  useEffect(() => {
    SetTemplate();
  }, []);

  const SetTemplate = async () => {
    try {
      const response: any = await GET(
        API.TICKET_DETAILS_INFO + params?.ticketId,
        null
      );
      if (response?.status) {
        const template = await TicketTemplateHtml(response?.data);
        setHtml(template);
        setTicketInfo(response?.data);
        setBtn(true);
      } else {
        setBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function downloadPDF() {
    const templates = html;
    pdfTemp.html(templates, {
      callback: function (pdf) {
        pdf.save(ticketInfo?.ticketNumber + ".pdf");
      },
      x: 100,
      width: 180,
      html2canvas: {
        scale: 0.3,
        height: document.documentElement.offsetHeight,
      },
    });
  }

  return (
    <Container>
      <Header />
      <div className="screenBox mt-5">
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </div>
      {btn && (
        <div className="TicketInvoice-Downlaod-Btn">
          <Button
            type="primary"
            icon={<IoMdCloudDownload />}
            onClick={() => downloadPDF()}
          >
            Download Ticket
          </Button>
        </div>
      )}
    </Container>
  );
}
export default TicketInvoice;
