import { Button, Modal, Tag } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ViewTransModal = (props: any) => {
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={props?.visible}
        title={
          <div style={{ fontSize: 25 }}>
            Transaction #{props?.data?.transactionId}
          </div>
        }
        onCancel={props?.onCancel}
        footer={false}
        width={500}
      >
        <hr />
        <div className="transactionItem1">
          <div className="transactionTxt1">Transaction Type :</div>
          <div className="transactionTxt2">
            {props?.data?.type?.toUpperCase()}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Payment Method :</div>
          <div className="transactionTxt2">
            {props?.data?.paymentMethod === "cash" ? (
              <Tag color="purple">
                {props?.data?.paymentMethod?.toUpperCase()}
              </Tag>
            ) : props?.data?.paymentMethod === "card" ? (
              <Tag color="gold">
                {props?.data?.paymentMethod?.toUpperCase()}
              </Tag>
            ) : (
              <Tag color="cyan">
                {props?.data?.paymentMethod?.toUpperCase()}
              </Tag>
            )}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Card No. :</div>
          <div className="transactionTxt2">{props?.data?.cardNo ?? "N/A"}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Amount :</div>
          <div className="transactionTxt2">{props?.data?.amount}</div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Ticket No. :</div>
          <div className="transactionTxt2">
            {props?.data?.ticketNo ?? "N/A"}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Booking Id :</div>
          <div className="transactionTxt2">
            {props?.data?.BookingId ?? "N/A"}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Created By :</div>
          <div
            className="transactionTxt3"
            onClick={() => {
              let id = props?.data?.staff?.id;
              navigate(`/Auth/staffDetails/${id}`, { state: { val: id } });
            }}
          >
            {props?.data?.staff?.name}
          </div>
        </div>

        <div className="transactionItem1">
          <div className="transactionTxt1">Created At :</div>
          <div className="transactionTxt2">
            {moment(props?.data?.createdAt).format("lll")}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewTransModal;
