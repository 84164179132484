import React from "react";
import "./styles.scss";
import Empty from "../../../assets/images/empty.gif";

const NoData = () => {
  return (
    <div className="NoData-noDataText">
      <div>
        <img src={Empty} alt="Loader" className="NoData-img" />
      </div>
      <div className="NoData-Text">No Data Found</div>
    </div>
  );
};
export default NoData;
