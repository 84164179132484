import { Card } from "antd";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { Table } from "react-bootstrap";

const ActivityDetails = () => {
  const location = useLocation();
  const id = location.state?.val;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = API.ACTIVITY_DETAILS + id;
      let response: any = await GET(api);
      if (response?.status) {
        const arr: any = [];
        arr.push(response?.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PageHeaders title={"Activity Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Name</th>
                  <th className="table-th">Description</th>
                  <th className="table-th">Duration</th>
                  <th className="table-th">Image</th>
                  <th className="table-th">Pricing</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.name}</td>
                    <td>{item?.description}</td>
                    <td>{item?.duration}</td>
                    <td>
                      <img
                        src={item?.image}
                        alt={item?.name}
                        style={{ width: "100%", height: "120px" }}
                      />
                    </td>
                    <td>
                      {item?.pricing?.map((item: any, index: any) => (
                        <div key={index}>
                          <p>
                            {item?.age} : {item?.price}
                          </p>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ActivityDetails;
