import { Button, Modal, Popconfirm, notification } from "antd";
import { useEffect, useState } from "react";
import { GET, POST } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import { TicketTemplateHtml } from "../../website/ticketTemplate/components/ticketTemplate";
import jsPDF from "jspdf";
import { IoMdCloudDownload } from "react-icons/io";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";

function TicketModal(props: any) {
  const User = useSelector((state: any) => state.User);
  const [data, setData] = useState("");
  const [html, setHTML] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [ticketInfo, setTicketInfo] = useState<any>({});

  const ticketNumber = props?.ticketNo;

  const pdfTemp = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  useEffect(() => {
    loadTicketData();
  }, []);
  const loadTicketData = async () => {
    try {
      const response: any = await GET(
        API.TICKET_DETAILS_INFO + ticketNumber,
        null
      );
      if (response?.status) {
        setData(response?.data);
        const html = await TicketTemplateHtml(response?.data);
        setTicketInfo(response?.data);
        setHTML(html);
      }
    } catch (err) {
      console.log("err - >", err);
    }
  };

  async function downloadPDF() {
    const templates = html;
    pdfTemp.html(templates, {
      callback: function (pdf) {
        pdf.save(ticketInfo?.ticket?.ticketNumber + ".pdf");
      },
      x: 100,
      width: 180,
      html2canvas: {
        scale: 0.3,
        height: document.documentElement.offsetHeight,
      },
    });
  }

  const onDelete = async () => {
    try {
      let reqObj = {
        ticketNumber: props?.ticketNo,
        deleted_by: User.user.id,
      };
      const response: any = await POST(API.TICKET_DELETE, reqObj);
      if (response.status) {
        notification.success({
          message: response.message,
          description: "Success",
        });
        props?.onClose();
        props?.onRefresh();
      } else {
        notification.error({
          message: response.message,
          description: "Ticket Couldn't be deleted",
        });
      }
    } catch (err: any) {
      console.log("err - >", err);
    }
  };

  return (
    <Modal footer={false} open={props?.view} onCancel={props?.onClose}>
      <div>
        <div className="mt-3" dangerouslySetInnerHTML={{ __html: html }}></div>
        <br />
        <Row>
          <Col md={2}>
            {props?.booking ? null : (
              <Popconfirm
                title="Delete the ticket"
                description="Are you sure to delete this ticket?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button className="mt-2" danger icon={<AiOutlineDelete />}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col md={4}></Col>
          <Col md={2}>
            <Button onClick={props?.onClose} className="mt-2">
              Cancel
            </Button>
          </Col>
          <Col md={3}>
            <Button
              className="mt-2"
              icon={<IoMdCloudDownload />}
              type="primary"
              onClick={() => downloadPDF()}
              loading={isLoading}
            >
              Download
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default TicketModal;
