import { useEffect } from "react";
import Header from "../../components/header";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import resort from "../../assets/images/resort.jpg";
import Footer from "../../components/footer";
import { IoCall } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import "./styles.scss";
// import { Helmet } from "react-helmet";
import "../../assets/images/resort.jpg";
import HelmetSeo from "../../components/helmetSeo";

// const Helmet = require("react-helmet");

const Resort = () => {
  const whatsApp = () => {
    window.open(`https://wa.me/${+91917001800600}`);
  };
  const Call = () => {
    window.location.href = "tel:+917001800600";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetSeo
        title="Resort"
        description={`Discover the best resorts in
      Wayanad that suit your specific preferences and needs.`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/resort.jpeg"
        canonicalurl="https://www.teenzania.com/resort"
        imageAlt="Discover the best resort at Wayand with Teenzania"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> About <IoIosArrowForward /> Resort
              </div>
              <h3 className="heading_h3">Resort</h3>
              <br />
              <Col md={8} xs={12}>
                <img
                  src={resort}
                  width="100%"
                  alt="resort"
                  style={{
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                  className="mb-5"
                />
                <h5>Are you in search of a Resort in Wayanad ?</h5>
                <p>
                  We are dedicated to helping you discover the best resorts in
                  Wayanad that suit your specific preferences and needs. Whether
                  you're in search of budget-friendly accommodations, luxurious
                  retreats, bachelor-friendly lodgings, or family-oriented stays
                  in the picturesque hilly region, we have a diverse selection
                  of options to suit your desires. Our network of fully
                  responsible and trustworthy partners ensures that your
                  experience is not only comfortable but also safe and
                  hassle-free.
                </p>
                <p>
                  As you plan your trip, rely on our expertise to guide you in
                  making the right choices. We take pride in offering a curated
                  list of high-quality resorts that cater to various tastes and
                  budgets. With us, you can rest assured that your stay in
                  Wayanad will be memorable and exceed your expectations. Let us
                  assist you in planning a remarkable getaway, tailored to your
                  needs.
                </p>

                <div className="Resort_text1">Contact Us</div>

                <div onClick={Call} className="Resort_text2">
                  <IoCall size={25} color="#3f8ad4" />
                  &nbsp;+917001800600
                </div>

                <div onClick={whatsApp} className="Resort_text2">
                  <RiWhatsappFill size={25} color="#129922" />
                  &nbsp; +917001800600
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Resort;
