import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Button, Input, Modal, notification, Tabs } from "antd";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo-white.png";
import { useNavigate } from "react-router-dom";
import { MdQrCodeScanner } from "react-icons/md";
import { Html5QrcodeScanner } from "html5-qrcode";
import "./style.scss";
import { TbBoxOff } from "react-icons/tb";

const { TabPane } = Tabs;

function CardInfo() {
  const [error, setError] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [fullDetails, setFullDetails] = useState<any>({});
  const [refeData, setRefeDeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(false);
  const navigate = useNavigate();

  const [Notifications, contextHolder] = notification.useNotification();

  const changescreen = () => {
    navigate("/home");
  };

  const changepage = () => {
    navigate("/share");
  };

  const handleSearch = async (searchCardNo: any) => {
    setIsLoading(true);
    try {
      const response: any = await GET("card/search/" + searchCardNo);
      if (response?.status) {
        setFullDetails(response?.data);
        setError("");
        setVisible(false);
        referanceDet(response?.data?.card?.id);
      } else {
        setFullDetails({});
        notification.error({
          message: "Card not found !",
          description:
            "Please check your card number or reach out to our staff.",
        });
      }
    } catch (err) {
      notification.error({
        message: "Oops !",
        description: "Something went wrong. Please refresh and try again.",
      });
    }
    setIsLoading(false);
  };

  const qrCodeSuccessCallback = (decodedText: any) => {
    setCardNo(decodedText);
    handleSearch(decodedText);
  };

  const qrCodeErrorCallback = (errorMessage: any) => {
    console.error("Error scanning QR code:", errorMessage);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      const html5QrcodeScanner = new Html5QrcodeScanner(
        "html5qr-code-full-region",
        {
          fps: 10,
          qrbox: 250,
          aspectRatio: 1,
          disableFlip: false,
        },
        true
      );
      html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
      return () => {
        html5QrcodeScanner.clear().catch((error: any) => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
      };
    }
  }, [visible]);

  const referanceDet = async (cardId: any) => {
    try {
      let api = API.CARD_REFERENCE_DATA + cardId;
      const response: any = await GET(api);
      if (response?.status) {
        setRefeDeta(response?.data);
        const totalAmount = response?.data?.transactions?.reduce(
          (sum: any, item: any) => sum + Number(item?.totalAmount),
          0
        );
        setTotal(totalAmount);
      } else {
        console.log("No Reference");
        setRefeDeta({});
      }
    } catch (err) {
      console.log("err === >", err);
    }
  };

  return (
    <>
      <div>
        <Header />
        {contextHolder}

        <div className="LayoutBox">
          <br />
          <Container>
            <div className="heading_style">
              <span onClick={changepage}>Back</span>
              <IoIosArrowForward onClick={changepage} />
              <span onClick={changescreen}>Home</span>
              <IoIosArrowForward onClick={changescreen} />
            </div>

            <Row style={{ marginTop: 20 }}>
              <Col xl={4} lg={3} md={2} xs={0} sm={2}></Col>
              <Col xl={4} lg={6} md={8} sm={8}>
                <br />
                <div className="hd-text">Find Your Card Details</div>
                <br />

                <div style={{ display: "flex", gap: 10, marginBottom: 15 }}>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Card Number"
                    value={cardNo}
                    onChange={(e) => setCardNo(e.target.value)}
                    onPressEnter={() => handleSearch(cardNo)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => handleSearch(cardNo)}
                  >
                    {isLoading ? (
                      <LoadingOutlined style={{ fontSize: 20 }} spin />
                    ) : (
                      <IoMdSearch size={20} />
                    )}
                  </Button>
                  <div>
                    <MdQrCodeScanner
                      size={35}
                      color="#188a8a"
                      onClick={() => setVisible(true)}
                    />
                  </div>
                </div>

                {error && <div className="error">{error}</div>}
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Card Details" key="1">
                    {fullDetails?.card ? (
                      <div className="cardBox">
                        <div className="cardBoxInner">
                          <p className="cardtxt7">LOYALTY CARD</p>
                          <div style={{ flex: 1 }}></div>
                          <div style={{ padding: 10 }}>
                            <img className="cardLogo" src={logo} alt="Logo" />
                          </div>
                        </div>

                        <Col sm={{ span: 12 }} xs={6} md={6}>
                          <div>
                            <p className="cardtxt1">
                              {fullDetails?.card?.cardNo}
                            </p>
                          </div>
                        </Col>
                        <div className="cardBoxInner">
                          <div style={{ flex: 1 }}>
                            {fullDetails?.card?.name ? (
                              <>
                                <p className="cardtxt2">
                                  {fullDetails?.card?.name}
                                </p>
                                <p className="cardtxt3">
                                  {fullDetails?.card?.phone}
                                </p>
                              </>
                            ) : (
                              <p className="cardtxt5">Card Not Assigned</p>
                            )}
                          </div>
                          <div>
                            <p className="cardtxt6">Balance</p>
                            <p className="cardtxt4">
                              {fullDetails?.card?.balance}.00
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Reference Details" key="2">
                    {refeData?.cardReference ? (
                      <div className="referenceCard">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#018989",
                          }}
                        >
                          <div>Total Cards</div>
                          <div>{refeData?.cardReference?.length ?? 0}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#018989",
                          }}
                        >
                          <div>Total Amount</div>
                          <div>{Number(total).toFixed(2) ?? 0}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="emptyBox">
                        <TbBoxOff color="red" size={25} />
                        <div className="mt-2"> No Reference</div>
                      </div>
                    )}
                  </TabPane>
                </Tabs>

                <br />
              </Col>
              <Col xl={4} lg={3} md={2} xs={0} sm={2}></Col>
            </Row>
          </Container>
          <br />
        </div>
        <Footer />
      </div>
      <Modal
        title="Scan QR Code"
        open={visible}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div id="html5qr-code-full-region" style={{ width: "100%" }} />
      </Modal>
    </>
  );
}

export default CardInfo;
