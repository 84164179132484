import { Button, Card, Empty, Form, Input, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import DataTable from "./dataTable";
import { CiSearch } from "react-icons/ci";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import { Col, Row } from "react-bootstrap";
import NoData from "../components/noData";
import { useNavigate } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import Loading from "../../components/loading";

function Commission() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({ totalAmount: 0 });

  useEffect(() => {
    getAllCards();
  }, []);

  const getAllCards = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        page: 1,
        take: 10,
      };
      let api = API.REFERENCE_CARDS;
      const result: any = await POST(api, obj);
      if (result?.status) {
        setData(result?.data);
        setIsLoading2(false);
      } else {
        setIsLoading2(false);
        setData([]);
      }
    } catch (error) {
      setIsLoading2(false);
      console.log(error);
      Notifications["error"]({
        message: "Network error",
      });
    }
  };

  const getData = async (value: any) => {
    setIsLoading(true);
    try {
      let api = API.CARDS_FULL_DETAILS + value?.cardNo;
      const response: any = await GET(api);
      if (response?.data?.length) {
        setData(response?.data);
        setIsLoading(false);
        Notifications["success"]({
          message: "Data Found",
        });
      } else {
        setIsLoading(false);
        Notifications["error"]({
          message: "Data Not Found",
        });
        setData([]);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Notifications["error"]({
        message: "Network error",
      });
    }
  };
  const onView = (val: any) => {
    navigate(`/Auth/CommissionDetails/${val?.id}`, { state: { val } });
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Commission"} backButton={true}>
        <Form form={form} onFinish={getData}>
          <div className="jobScreen-PageHeaderBox">
            <Row>
              <Col md="8">
                <Form.Item name={"cardNo"} noStyle>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Card No"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col md="4">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </PageHeaders>
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : data?.length ? (
            <DataTable
              data={data}
              loading={isLoading}
              summary={summary}
              onView={(item: any) => onView(item)}
            />
          ) : (
            <Empty />
          )}
        </Card>
      </div>
    </div>
  );
}

export default Commission;
