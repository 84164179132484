import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import moment from "moment";

function SettlementFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (val: any) => {
    try {
      console.log(val);
      setIsLoading(true);
      let job = {
        id: props?.jobData?.job?.id,
        name: props?.jobData?.job?.name,
        staffId: props?.jobData?.job?.staffId,
        status: false,
        isClockIn: "End",
        totalSlots: props?.jobData?.job?.totalSlots,
        usedSlots: props?.jobData?.job?.usedSlots,
        totalAmount: props?.data?.total?.length
          ? Number(props?.data?.grandTotal)
          : 0,
        dueAmount: val?.outStanding + val?.outStandingUpi,
        remarks: val?.remarks,
        settledAmount: Number(val?.amoutPaying),
        upiSettle: Number(val?.upiPaying),
        dateTime: moment().format(),
      };
      let url = API.JOB_IN;
      const data: any = await POST(url, job);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Job CLOCK OUT Successfully`,
        });
        props?.onCancel();
        setIsLoading(false);
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  const hndleValueChange = (value: any, type: string) => {
    if (type === "cash") {
      let payableAmount =
        Number(props?.data?.totalcash ? props?.data?.totalcash : 0) -
        Number(props?.data?.refundcash ? props?.data?.refundcash : 0);
      let outstanding = Number(payableAmount) - Number(value);
      form.setFieldValue("outStanding", outstanding);
    } else if (type === "upi") {
      let totalUPIAmount =
        props?.data?.total?.find((item: any) => item.paymentMethod === "upi")
          ?.totalAmount || 0;
      let outstandingUPI = Number(totalUPIAmount) - Number(value);
      form.setFieldValue("outStandingUpi", outstandingUPI);
    }
  };

  return (
    <Modal
      open={props?.visible}
      title={<div style={{ fontSize: 25 }}>{`Summary`}</div>}
      onCancel={props?.onCancel}
      footer={false}
      width={500}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          outStanding:
            Number(props?.data?.totalcash ? props?.data?.totalcash : 0) -
            Number(props?.data?.refundcash ? props?.data?.refundcash : 0),
          outStandingUpi: Number(
            props?.data?.total.find((item: any) => item.paymentMethod === "upi")
              ?.totalAmount || 0
          ),
        }}
      >
        <Table bordered>
          <tbody>
            <tr>
              <td>Collection</td>
              <td style={{ textAlign: "right" }}>
                {" "}
                {Number(props?.data?.grandTotal).toFixed(2)}
              </td>
            </tr>
            <br />
            {props?.data?.total?.slice(0, 2).map((item: any) => {
              return (
                <tr key={item?.paymentMethod}>
                  <td>{item?.paymentMethod}</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(item?.totalAmount).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <br />
            {props?.data?.refund?.length ? (
              <tr>
                <td>Refund</td>
                <td style={{ textAlign: "right" }}>Amount</td>
              </tr>
            ) : null}

            {props?.data?.refund?.map((item: any) => {
              return (
                <tr key={item?.paymentMethod}>
                  <td>{item?.paymentMethod}</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(item?.totalAmount).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <br />
            <tr>
              <td>CASH IN HAND ( CH )</td>
              <td style={{ textAlign: "right" }}>
                {" "}
                {Number(
                  Number(props?.data?.totalcash ? props?.data?.totalcash : 0) -
                    Number(
                      props?.data?.refundcash ? props?.data?.refundcash : 0
                    )
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </Table>
        <Card title="Settlement">
          <Card.Grid
            style={{ width: "100%", paddingTop: "8px", paddingBottom: "2px" }}
          >
            <label className="fw-bold mb-2">Cash Settlement</label>
            <Row>
              <Col md="6">
                <label>Outstanding</label>
                <Form.Item name={"outStanding"}>
                  <Input type="number" readOnly />
                </Form.Item>
              </Col>
              <Col md="6">
                <label>Settle Amount</label>
                <Form.Item
                  name={"amoutPaying"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Settle Amount",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    onChange={(value) => hndleValueChange(value, "cash")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card.Grid>
          <br />
          <Card.Grid
            style={{ width: "100%", paddingTop: "8px", paddingBottom: "2px" }}
          >
            <label className="fw-bold mb-2">Upi Settlement</label>
            <Row>
              <Col md="6">
                <label>Outstanding</label>
                <Form.Item name={"outStandingUpi"}>
                  <Input type="number" readOnly />
                </Form.Item>
              </Col>
              <Col md="6">
                <label>Settle Amount</label>
                <Form.Item
                  name={"upiPaying"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Settle Amount",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    onChange={(value) => hndleValueChange(value, "upi")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card.Grid>
        </Card>
        <br />
        <Col md="12">
          <label>Remarks</label>
          <Form.Item name={"remarks"}>
            <Input.TextArea
              autoSize={{ minRows: 3 }}
              placeholder="Enter Remarks"
            />
          </Form.Item>
        </Col>

        <Row>
          <Col sm={5}></Col>
          <Col sm={3}>
            <Button size="large" danger block onClick={props.onCancel}>
              Close
            </Button>
          </Col>
          <Col sm={4}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              loading={isLoading}
            >
              Clock Out
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SettlementFormModal;
