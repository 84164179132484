import { Card, Pagination, Tag } from "antd";
import Loading from "../../../components/loading";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment";

const ActivityDataTable = (props: any) => {
  return (
    <div>
      {props?.loading ? (
        <Loading />
      ) : (
        <div className="">
          <Row>
            <Col md="4">
              <Card>
                <div className="report-text1">ACTIVITY</div>
                <div className="report-text2">{props?.activity?.children}</div>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <div className="report-text1">TOTAL TICKETS</div>
                <div className="report-text2">
                  {props?.summary?.totalTickets}
                </div>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <div className="report-text1">TOTAL AMOUNT</div>
                <div className="report-text2">
                  {Number(props?.summary?.totalAmount).toFixed(2)}
                </div>
              </Card>
            </Col>
          </Row>
          <br />

          <div className="">
            <Table bordered>
              <thead>
                <tr>
                  <td className="report-headtd">Date</td>
                  <td className="report-headtd">Total Tickets</td>
                  <td className="report-headtd">Total Amount</td>
                </tr>
              </thead>

              <tbody>
                {props?.data?.map((act: any) => {
                  return (
                    <tr>
                      <td>{act?.date}</td>
                      <td>{act?.totalTickets}</td>
                      <td>{Number(act?.totalAmount).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityDataTable;
