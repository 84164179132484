import { useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/header";
import { IoIosArrowForward } from "react-icons/io";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import Loading from "../../components/loading";
import { FaRupeeSign } from "react-icons/fa";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import HelmetSeo from "../../components/helmetSeo";
const Fade = require("react-reveal/Fade");

const Offers = () => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState<any>([]);
  // const [preData, setPreData] = useState<any>();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getOffers();
  // }, []);

  // const getOffers = async () => {
  //   setIsLoading(true);
  //   try {
  //     const obj = {
  //       startDate: "",
  //       query: "",
  //       page: 1,
  //       take: 10,
  //     };
  //     const api = API.OFFERS_LIST;
  //     let response: any = await POST(api, obj);
  //     if (response?.status) {
  //       setData(response.data);
  //       setIsLoading(false);
  //     } else {
  //       console.log("some thing went wrong");
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     console.log("err === >", err);
  //     setIsLoading(false);
  //   }
  // };

  // const totalPrice = (val: any) => {
  //   console.log("--------------- val --", val);
  //   let totaL = val?.reduce((total: number, item: { price: string }) => {
  //     return total + Number(item.price);
  //   }, 0);
  //   return totaL || 0;
  // };

  // const totalPriceModal = preData?.price?.reduce(
  //   (total: number, item: { price: string }) => {
  //     return total + parseInt(item.price);
  //   },
  //   0
  // );

  return (
    <div>
      <HelmetSeo
        title="Offers"
        description={`Know the best Offers in Teenzania Waiting For you.!`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/Talaia.jpg"
        canonicalurl="https://www.teenzania.com/offers"
        imageAlt="Offers"
      />
      <Header />
      <br />
      <br />
      <br />
      <br />

      <Col className="d-flex justify-content-center">
        <Fade top duration={2000}>
          <img
            src={require("../../assets/images/comingSoon.png")}
            alt="Coming Soon"
            width={"320px"}
          />
        </Fade>
      </Col>
      <br />
      <br />
      <br />
      <br />

      {/* <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> About <IoIosArrowForward /> Offers
              </div>
              <h3 className="heading_h3">Offers</h3>
              <br />
              {isLoading ? (
                <Loading />
              ) : (
                <Row className="g-3 g-sm-2">
                  {data.length &&
                    data?.map((item: any, index: any) => {
                      console.log("---------------- item -", item);
                      return (
                        <Col
                          key={index}
                          md={3}
                          xs={12}
                          onClick={() => {
                            setIsModalOpen(true);
                            setPreData(item);
                          }}
                        >
                          <div className="Offer_box1">
                            <img
                              src={item?.image}
                              alt="offer"
                              className="Offer_image1"
                            />
                            <div className="Offer_image2">
                              <div className="Offer_Tagtext">
                                <FaRupeeSign color="#fff" />
                                {totalPrice(item?.price)}/-
                                {item?.price}/-
                              </div>
                            </div>
                          </div>
                          <Fade top delay={100}>
                            <div className="Offer_text1">{item?.title}</div>
                          </Fade>
                          <div className="Offer_text2">
                            {item?.description} in {item?.activities}
                          </div>
                          {item?.price?.map(
                            (priceItem: any, priceIndex: any) => (
                              <div key={priceIndex} className="Offer_text3">
                                <FaRupeeSign color="#828583" />
                                {priceItem?.activities} For {priceItem?.age}{" "}
                                Only at {priceItem?.price}/-
                              </div>
                            )
                          )}
                          <div className="Offer_text4">T&C apply</div>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </Col>
            <Modal
              width={450}
              open={isModalOpen}
              footer={false}
              onCancel={() => setIsModalOpen(false)}
              centered
              closable={false}
            >
              <img
                src={preData?.image}
                alt="offer"
                className="OfferModal_image1 "
              />
              <img
                src={require("../../assets/images/hurryUp.png")}
                alt="offer"
                className="OfferModal_image2"
              />
              <div className="Offer_text1">{preData?.title}</div>
              <div className="Offer_text2">
                {preData?.description} in {preData?.activities}
              </div>
              <div className="Offer_text3">
                Get a ComboTicket for {preData?.activities} Only at&nbsp;{" "}
                <FaRupeeSign color="#828583" />
                {totalPriceModal}/-
                {preData?.price?.map((item: any, index: any) => (
                  <>
                    <div>
                      {item?.age}: {item?.price}/-
                    </div>
                  </>
                ))}
              </div>
              <div className="Offer_text4">T&C apply</div>
              <Col className="d-flex gap-2 justify-content-end">
                <Button
                  className="OfferModal_Botton1"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={() => navigate("/booking")}>
                  Book Now!
                </Button>
              </Col>
            </Modal>
          </Row>
        </Container>
        <br />
      </div> */}
      <Footer />
    </div>
  );
};

export default Offers;
