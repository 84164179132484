import { Button, Form, Input, Modal, notification, Select } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import { Col, Row } from "react-bootstrap";
import { useForm } from "antd/es/form/Form";

const ConfirmModal = (props: any) => {
  const [Notifications, contextHolder] = notification.useNotification();
  const [cardView, setCardView] = useState(false);
  const [summary, setSummary] = useState<any>(0);
  const [refeCardData, setRefeCardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const User: any = useSelector((val: any) => val.User.user);
  const [doSettle, setDoSettle] = useState(false);
  const [form] = useForm();

  const doRefund = async (values: any) => {
    console.log(values);

    try {
      setLoading(true);
      let obj = {
        staffId: Number(User?.id),
        cardNo: props?.cardNo,
        paymentMethod: values?.payment,
      };
      let api = API.CARD_REFUND;
      let refund: any = await POST(api, obj);
      if (refund?.status) {
        Notifications["success"]({
          message: "Settlement completed",
        });
        props?.reload();
      } else {
        Notifications["success"]({
          message: "Unable to completed settlement",
        });
      }
    } catch (error) {
      setLoading(false);
      Notifications["success"]({
        message: "Unable to procees",
      });
      console.log("error", error);
    }
  };

  return (
    <div>
      <Modal
        visible={props.open}
        title={<div style={{ fontSize: 25 }}>Confirm Settlement</div>}
        onCancel={props.close}
        footer={false}
        width={500}
      >
        {/* <Form form={form} onFinish={doRefund}>
          <Form.Item
            name={"pm"}
            rules={[
              {
                required: true,
                message: "Please enter Card No",
              },
            ]}
          >
            <div className="">Payment Method</div>
            <Select size="large">
              <Select.Option value={"cash"}>cash</Select.Option>
              <Select.Option value={"upi"}>upi</Select.Option>
            </Select>
          </Form.Item>

          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Button
                onClick={() => props?.close()}
                style={{ width: "100%" }}
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col md="3">
              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
                size="large"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form> */}

        <Form onFinish={doRefund} form={form}>
          <Row>
            <label className="formLabel">Payment method</label>
            <Form.Item
              name={"payment"}
              rules={[
                {
                  required: true,
                  message: "Payment Method",
                },
              ]}
            >
              <Select size="large">
                <Select.Option value={"cash"}>CASH</Select.Option>
                <Select.Option value={"upi"}>UPI</Select.Option>
              </Select>
            </Form.Item>
          </Row>

          <Row>
            <Col sm={6}></Col>
            <Col sm={3}>
              <Button size="large" danger block onClick={props.close}>
                Close
              </Button>
            </Col>
            <Col sm={3}>
              <Button
                size="large"
                type="primary"
                block
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
