import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Switch,
  notification,
  Modal,
  DatePicker,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

function CardFormModal(props: any) {
  const user: any = useSelector((val: any) => val.User.user);

  const [form] = useForm();
  const [refundForm] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [viewBalanceForm, setViewBalanceForm] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);

  useEffect(() => {
    if (props.data && props.data.balance > 0) {
      setViewBalanceForm(true);
    }
  }, [props.data]);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      var id = props?.data?.id;
      let obj = {
        id: props?.data?.id,
        name: val?.name,
        cardNo: val?.cardNo,
        phone: val?.phone,
        expiryDate: val?.expiryDate,
        balance: val?.balance ? val?.balance : "",
        status: id ? val?.status : true,
        assigned: id ? val?.assigned : true,
        recharge: viewBalanceForm ? true : false,
        paymentMethod: val?.paymentMethod,
      };
      let url = id ? API.CARDS_EDIT : API.CARDS_ADD;
      const data: any = await POST(url, obj);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Card Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };
  const refundProcess = async (val: any) => {
    try {
      let obj = {
        staffId: Number(user?.id),
        cardNo: props?.data?.cardNo,
        paymentMethod: val?.paymentMethod,
      };
      let api = API.CARD_REFUND;
      const refundResponse: any = await POST(api, obj);
      if (refundResponse?.status) {
        notification.success({
          message: "Success",
          description: "Successfully refunded",
        });
        setRefundModalVisible(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Failed",
          description: "Refund process failed",
        });
      }
    } catch (err) {
      console.log("err =-=-=-=-=-= >", err);
      notification.error({
        message: "Failed",
        description: "Oops some thing went wrong",
      });
    }
    console.log("refunding ...");
  };
  return (
    <Modal
      visible={props?.visible}
      title={
        <div
          style={{
            fontSize: 25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{`${props?.data?.id ? "Edit" : "Create"} Card`}</span>
          {props?.data?.id ? (
            <span>
              <Button
                style={{ marginRight: 30 }}
                // type="primary"
                danger
                onClick={() => setRefundModalVisible(true)}
              >
                Refund
              </Button>
            </span>
          ) : null}
        </div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={800}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          name: props?.data?.name,
          cardNo: props?.data?.cardNo,
          phone: props?.data?.phone,
          expiryDate: props?.data?.id ? dayjs(props?.data?.expiryDate) : null,
          balance: props?.data?.balance,
          recharge: props?.data?.recharge,
        }}
      >
        <Row>
          <Col md={6}>
            <label className="formLabel">Card No</label>
            <Form.Item
              name={"cardNo"}
              rules={[
                {
                  required: true,
                  message: "Please enter Card No",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Name</label>
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please enter Name",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <label className="formLabel">Update Balance</label>
                <Form.Item>
                  <Switch
                    checked={viewBalanceForm}
                    onChange={(checked) => {
                      setViewBalanceForm(checked);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <label className="formLabel">Phone</label>
            <Form.Item
              name={"phone"}
              rules={[{ required: true, message: "Please enter Phone No" }]}
            >
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">ExpiryDate</label>
            <Form.Item
              name={"expiryDate"}
              rules={[{ required: true, message: "Please enter ExpiryDate" }]}
            >
              <DatePicker style={{ width: "100%" }} size="large" />
            </Form.Item>
          </Col>
        </Row>
        {viewBalanceForm ? (
          <Row>
            <Col>
              <label className="formLabel">Balance</label>
              <Form.Item
                name="balance"
                rules={[{ required: true, message: "Please enter Balance" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col>
              <label className="formLabel">Payment Method</label>
              <Form.Item
                name={"paymentMethod"}
                rules={[
                  {
                    required: true,
                    message: "Please select a payment method",
                  },
                ]}
              >
                <Select
                  placeholder={"Select payment method"}
                  size="large"
                  allowClear
                >
                  <Select.Option value={"cash"}>Cash</Select.Option>
                  <Select.Option value={"upi"}>Upi</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col sm={6}>
            <Button size="large" danger block onClick={props.onCancel}>
              Close
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              loading={isLoading}
            >
              {props?.data?.id ? "Edit" : "Create"}
            </Button>
          </Col>
        </Row>
      </Form>
      {refundModalVisible ? (
        <Modal
          open={refundModalVisible}
          title="Confirm Refund"
          onCancel={() => setRefundModalVisible(false)}
          footer={false}
        >
          <div className="mt-3 mb-3">
            Are you sure you want to refund ? Then please Select the a payment
            method which you want
          </div>
          <Form form={refundForm} onFinish={refundProcess}>
            <label className="formLabel">Payment Method</label>
            <Form.Item
              name={"paymentMethod"}
              rules={[
                {
                  required: true,
                  message: "Please select a payment method",
                },
              ]}
            >
              <Select
                placeholder={"Select payment method"}
                size="large"
                allowClear
              >
                <Select.Option value={"cash"}>Cash</Select.Option>
                <Select.Option value={"upi"}>Upi</Select.Option>
              </Select>
            </Form.Item>
            <Row>
              <Col>
                <Form.Item>
                  <Button
                    size="large"
                    danger
                    block
                    onClick={() => setRefundModalVisible(false)}
                  >
                    Close
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    block
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Confirm
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : null}
    </Modal>
  );
}

export default CardFormModal;
