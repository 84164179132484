import React, { useEffect, useState } from "react";
import PageHeaders from "../../components/pageHeaders";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  notification,
} from "antd";
import Loading from "../../components/loading";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import "./style.scss";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

function CreateBooking() {
  const location = useLocation();
  const booking = location.state?.booking;
  const [form] = useForm();
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(true);
  const [activities, setActivities] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(100);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getActvites();
  }, []);

  const getActvites = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        page: page,
        take: take,
      };
      let api = API.ACTIVITY_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setActivities(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setActivities([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };
  const createBooking = async (val: any) => {
    try {
      setIsLoading(true);
      const activitiesPayload = val.activities.map((activity: any) => ({
        description: activity?.description,
        id: activity?.activity,
        name:
          activities.find((act: any) => act.id === activity.activity)?.name ||
          "",
        price: activity.sub
          .filter((subItem: any) => subItem.count > 0)
          .map((subItem: any) => ({
            amount: subItem?.total,
            count: subItem?.count,
            price: subItem?.price,
            age: subItem?.age,
          })),
      }));
      let totalCount = 0;
      activitiesPayload.forEach((activity: any) => {
        activity.price.forEach((price: any) => {
          totalCount += price.count;
        });
      });
      var id = booking?.id;
      const obj = {
        id: booking?.id ? id : null,
        userName: val?.userName,
        phone: val?.phone,
        email: val?.email,
        address: val?.address,
        district: val?.district,
        state: val?.state,
        pincode: val?.pincode,
        totalAmount: Number(val?.totalAmount),
        grandTotal: Number(val?.grandTotal),
        paidAmount: Number(val?.paidAmount),
        tax: Number(val?.tax),
        paymentMethod: val?.paymentMethod,
        bookingDate: val?.bookingDate,
        discount: Number(val?.discount),
        fromDevice: "web",
        ticketInfo: { activities: activitiesPayload },
        transId: "",
        taxAmt: Number(val?.tax),
        jobId: null,
        // activityId: 3,
        type: "",
        count: totalCount,
        ticketNo: "",
        status: true,
        paymentStatus: val?.paidAmount ? true : false,
        print: 0,
      };
      let url = booking?.id ? API.BOOKING_EDIT : API.BOOKING_ADD;
      const data: any = await POST(url, obj);
      if (data.status) {
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: data.message,
        });
        booking?.id ? window.history.back() : resetForm();
      } else {
        setIsLoading(false);
        notification.error({
          message: "Failed",
          description: data.message,
        });
      }
    } catch (err) {
      console.log("err -  -  - >", err);
      setIsLoading(false);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    try {
      if (
        changedValues?.activities ||
        changedValues?.tax ||
        changedValues?.discount
      ) {
        const updatedValues = allValues?.activities?.map((mainItem: any) => {
          const foundData = activities.find(
            (item: any) => item?.id === mainItem?.activity
          );
          if (foundData) {
            return {
              ...mainItem,
              sub: foundData?.pricing?.map((pricingItem: any) => {
                const correspondingSubItem = mainItem?.sub?.find(
                  (subItem: any) => subItem?.age === pricingItem?.age
                );
                return {
                  age: pricingItem?.age,
                  price: pricingItem?.price,
                  count: correspondingSubItem?.count || 0,
                  total:
                    (correspondingSubItem?.count || 0) * pricingItem?.price,
                };
              }),
            };
          }
          return mainItem;
        });

        let totalAmount = updatedValues?.reduce(
          (accumulator: number, currentValue: any) =>
            accumulator +
            currentValue?.sub?.reduce(
              (subAccumulator: number, subCurrentValue: any) =>
                subAccumulator + subCurrentValue?.total,
              0
            ),
          0
        );

        var tax = Number(allValues.tax || 0);
        var discount = Number(allValues?.discount || 0);
        var grandTotal = totalAmount + tax - discount;

        const valuesToSet: any = {
          activities: updatedValues,
          tax,
          paidAmount: changedValues?.paidAmount ?? grandTotal,
        };

        if (!isNaN(totalAmount)) {
          valuesToSet.totalAmount = totalAmount;
        }

        if (!isNaN(grandTotal)) {
          valuesToSet.grandTotal = grandTotal;
        }

        form.setFieldsValue(valuesToSet);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetForm = () => {
    form.resetFields();
  };
  return (
    <div>
      {contextHolder}
      <PageHeaders
        title={booking?.id ? "Update Booking" : "Create Booking"}
        backButton={true}
        loading={isLoading2}
      ></PageHeaders>
      <div className="screenBox">
        <Card>
          <Form
            form={form}
            onFinish={createBooking}
            autoComplete="off"
            layout="vertical"
            onValuesChange={onValuesChange}
            initialValues={{
              userName: booking?.userName || "",
              phone: booking?.phone || "",
              email: booking?.email || "",
              address: booking?.address || "",
              state: booking?.state || "",
              district: booking?.district || "",
              pincode: booking?.pincode || "",
              totalAmount: booking?.totalAmount || 0,
              grandTotal: booking?.grandTotal || 0,
              discount: booking?.discount || 0,
              tax: booking?.tax || 0,
              paymentMethod: booking?.paymentMethod || undefined,
              bookingDate: booking?.bookingDate
                ? moment(booking?.bookingDate)
                : null,
              paidAmount: booking?.paidAmount || undefined,
              activities: booking?.ticketInfo?.activities?.map(
                (activity: any) => {
                  return {
                    activity: activity.id,
                    sub:
                      activity?.price?.map((price: any) => {
                        return {
                          age: price.age,
                          price: price.price,
                          count: price.count,
                          total: price.amount,
                        };
                      }) || [],
                  };
                }
              ) || [""],
            }}
          >
            <Row>
              <Col md={3}>
                <label className="formLabel">User Name</label>
                <Form.Item
                  name={"userName"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">Phone No</label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">E-mail</label>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">Address</label>
                <Form.Item name={"address"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={3}>
                <label className="formLabel">State</label>
                <Form.Item name={"state"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">District</label>
                <Form.Item name={"district"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">Pincode</label>
                <Form.Item name={"pincode"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">Booking Date</label>
                <Form.Item name={"bookingDate"}>
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="actCol">
                  <label className="formLabel">Activities</label>
                  <Form.List name="activities">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div>
                            <Row>
                              <Col md={9}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "activity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing activity",
                                    },
                                  ]}
                                  // label="Activity"
                                  noStyle
                                >
                                  <Select
                                    allowClear
                                    size="large"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    placeholder="Select Activities"
                                  >
                                    {activities.map((option: any) => (
                                      <Select.Option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={3}>
                                <Form.Item noStyle>
                                  <Button
                                    size="large"
                                    onClick={() => remove(name)}
                                    block
                                  >
                                    <IoRemoveCircleOutline
                                      size={20}
                                      color="red"
                                    />
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                            <br />
                            <Form.Item>
                              <Form.List name={[name, "sub"]}>
                                {(subFields) => (
                                  <>
                                    {subFields?.map((subField, index) => (
                                      <Space
                                        key={subField?.key}
                                        style={{
                                          display: "flex",
                                          marginBottom: 8,
                                        }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...subField}
                                          name={[subField?.name, "age"]}
                                          label="Age Category"
                                        >
                                          <Input
                                            // size="small"
                                            readOnly
                                            placeholder="Age Category"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...subField}
                                          name={[subField?.name, "price"]}
                                          label="Price"
                                        >
                                          <Input
                                            // size="small"
                                            readOnly
                                            placeholder="Price"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...subField}
                                          name={[subField?.name, "count"]}
                                          label="Count"
                                        >
                                          <InputNumber
                                            // size="small"
                                            placeholder="Count"
                                            min={0}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...subField}
                                          name={[subField.name, "total"]}
                                          label="Total"
                                        >
                                          <Input
                                            // size="small"
                                            readOnly
                                            placeholder="Total"
                                          />
                                        </Form.Item>
                                      </Space>
                                    ))}
                                  </>
                                )}
                              </Form.List>
                            </Form.Item>
                          </div>
                        ))}
                        <Row>
                          <Col md={8} />
                          <Col md={4}>
                            <Form.Item>
                              <Button
                                // type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Activity
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="formLabel">Total Amount</label>
                <Form.Item name={"totalAmount"}>
                  <Input readOnly />
                </Form.Item>
                <label className="formLabel">Grand Total</label>
                <Form.Item name={"grandTotal"}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col>
                <label className="formLabel">Discount</label>
                <Form.Item name={"discount"}>
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    defaultValue={0}
                  />
                </Form.Item>
                <label className="formLabel">Payment Method</label>
                <Form.Item
                  name={"paymentMethod"}
                  rules={[
                    {
                      required: true,
                      message: "Please select a payment method",
                    },
                  ]}
                >
                  <Select placeholder={"Select payment method"}>
                    <Select.Option value={"cash"}>Cash</Select.Option>
                    <Select.Option value={"upi"}>Upi</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <label className="formLabel">Tax</label>
                <Form.Item name={"tax"}>
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    defaultValue={0}
                  />
                </Form.Item>
                <label className="formLabel">Paid Amount</label>
                <Form.Item
                  name={"paidAmount"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={4}></Col>
              <Col sm={4}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {booking?.id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default CreateBooking;
