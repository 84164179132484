import React, { useState } from "react";
import { Button, Form, Input, Select, Switch, notification, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST, COMPRESS_IMAGE } from "../../utils/apiRequest";
import { useForm } from "antd/es/form/Form";
import ImagePicker from "../components/ImagePicker";

function StaffFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [image_url, setImageUrl] = useState<any>(props?.data?.image);
  const [file, setFile] = useState<any>();

  const onFinish = async (val: any) => {
    try {
      // setIsLoading(true);
      let images: any = await COMPRESS_IMAGE(file);
      var id = props?.data?.id;
      let staff = {
        id: props?.data?.id,
        name: val?.name,
        role: val?.role,
        phone: val?.phone,
        email: val?.email?.toLowerCase(),
        address: val?.address,
        Password: val?.password,
        status: val?.status,
        self_assign: val?.self_assign,
        image: file?.name ? images?.url : props?.data?.image,
      };

      console.log("staff --> ", staff);

      let url = id ? API.STAFFS_EDIT : API.STAFFS_ADD;
      const data: any = await POST(url, staff);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Staff Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Staff`}</div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={800}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          name: props?.data?.name,
          role: props?.data?.role,
          phone: props?.data?.phone,
          email: props?.data?.email,
          address: props?.data?.address,
          password: props?.data?.Password,
          status: props?.data?.status,
          self_assign: props?.data?.self_assign,
        }}
      >
        <Row>
          <Col md={6}>
            <label className="formLabel">Full Name</label>
            <Form.Item name={"name"}>
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Phone</label>
            <Form.Item
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input type="number" size="large" />
            </Form.Item>
            <label className="formLabel">Address</label>
            <Form.Item
              name={"address"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.TextArea size="large" rows={3} />
            </Form.Item>
            <label className="formLabel">Email</label>
            <Form.Item
              name={"email"}
              rules={[{ required: true, message: "Please enter email id" }]}
            >
              <Input type="email" size="large" />
            </Form.Item>
            <label className="formLabel">Password</label>
            <Form.Item
              name="password"
              rules={[{ required: props?.data?.id ? false : true }]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <label className="formLabel">Confirm Password</label>
            <Form.Item
              name="password2"
              dependencies={["password"]}
              rules={[
                {
                  required: props?.data?.id ? false : true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password you entered does not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col md={6}>
            <label className="formLabel">Role</label>
            <Form.Item name={"role"} rules={[{ required: true, message: "" }]}>
              <Select
                size="large"
                defaultValue="Select Role"
                style={{ width: "100%" }}
              >
                <Select.Option value="user">Staff</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </Form.Item>

            <label className="formLabel">Photo</label>
            <Form.Item name="image">
              <ImagePicker
                onChange={(value: any) => {
                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={image_url}
              />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <label className="formLabel">Status</label>
                <Form.Item
                  name="status"
                  valuePropName={props?.data.status ? "checked" : "null"}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <label className="formLabel">Self Assign</label>
                <Form.Item
                  name="self_assign"
                  valuePropName={props?.data.self_assign ? "checked" : "null"}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Button size="large" danger block onClick={props.onCancel}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {props?.data?.id ? "Edit" : "Create"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default StaffFormModal;
