import React, { useEffect } from "react";
import Header from "../../components/header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer";
import { IoIosArrowForward } from "react-icons/io";
import HelmetSeo from "../../components/helmetSeo";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HelmetSeo
        title="Terms & Conditions"
        description={`Our website contains material which is owned by or licensed to
        us. This material includes, but are not limited to, the
        design, layout, look, appearance and graphics.`}
        image="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/teenzania/seo/teenzaniLogo.png"
        canonicalurl="https://www.teenzania.com/terms"
        imageAlt="Terms & Conditions"
      />
      <Header />
      <div className="LayoutBox">
        <br />
        <Container>
          <Row>
            {" "}
            <Col sm={{ span: 10, offset: 1 }} xs={12}>
              <div className="heading_bredcume">
                Home <IoIosArrowForward /> Legal Terms <IoIosArrowForward />{" "}
                Terms and Conditions
              </div>
              <h3 className="heading_h3">Terms and Conditions</h3>
              <br />
              <Col md={8} sm={12}>
                <p>
                  For the purpose of these Terms and Conditions, The term "we",
                  "us", "our" used anywhere on this page shall mean{" "}
                  <strong>TEENZANIA</strong>, whose registered/operational
                  office is OPPOSITE CHAIN TREE, 8/494, Kozhikode Vythiri
                  Gudallur Road, LAKKIDI, Vythiri Wayanad KERALA 673576 . "you",
                  “your”, "user", “visitor” shall mean any natural or legal
                  person who is visiting our website and/or agreed to purchase
                  from us.
                </p>
                <p>
                  Your use of the website and/or purchase from us are governed
                  by following Terms and Conditions:
                </p>
                <p>
                  The content of the pages of this website is subject to change
                  without notice.
                </p>
                <p>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  found or offered on this website for any particular purpose.
                  You acknowledge that such information and materials may
                  contain inaccuracies or errors and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </p>
                <p>
                  Your use of any information or materials on our website and/or
                  product pages is entirely at your own risk, for which we shall
                  not be liable. It shall be your own responsibility to ensure
                  that any products, services or information available through
                  our website and/or product pages meet your specific
                  requirements.
                </p>
                <p>
                  Our website contains material which is owned by or licensed to
                  us. This material includes, but are not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </p>
                <p>
                  All trademarks reproduced in our website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website
                </p>
                <p>
                  Unauthorized use of information provided by us shall give rise
                  to a claim for damages and/or be a criminal offense.
                </p>
                <p>
                  From time to time our website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information.
                </p>
                <p>
                  You may not create a link to our website from another website
                  or document without <strong>TEENZANIA</strong>’s prior written
                  consent.
                </p>
                <p>
                  Any dispute arising out of use of our website and/or purchase
                  with us and/or any engagement with us is subject to the laws
                  of India .
                </p>
                <p>
                  We, shall be under no liability whatsoever in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any Transaction, on Account of
                  the Cardholder having exceeded the preset limit mutually
                  agreed by us with our acquiring bank from time to time
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
